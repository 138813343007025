import { UserDetailsDTO, AddressDTO, CompanyDTO, ContactDTO } from '_types/backendTypes';
import { User, Country, Industry, Company } from '_types/commonTypes';
import { mapAddressToDto, mapDtoToAddress } from './commonMapper';
import { transformToStarUsername } from '_libs/helpers';
import {
  initialCompanyState,
  initialUserState,
  initialCountry,
  initialDeliveryAddressState
} from '_reducers/partialInitialStates';

export const mapToUser = (
  dto: UserDetailsDTO,
  countries: Country[],
  industriesCodeList: Industry[],
  hideInfo?: boolean,
  description?: string
): User => {
  const industries: Industry[] = [];
  if (dto.Company && dto.Company.Industries && dto.Company.Industries.length > 0) {
    dto.Company.Industries.forEach(d => {
      const idx = industriesCodeList.findIndex(i => i.id === d);

      if (idx > -1) {
        industries.push(industriesCodeList[idx]);
      } else {
        // Industry nod from our database but from imported data
        industries.push({ id: d, display: d, name: d });
      }
    });
  }

  return {
    userName: dto.UserName,
    coveredUsername: transformToStarUsername(dto.UserName),
    email: dto.Email || '',
    isEmailConfirmed: dto.ContactEmailConfirmed,
    firstName: dto.FirstName || '',
    lastName: dto.LastName || '',
    phoneNumber: dto.PhoneNumber || '',
    rating: dto.Rating,
    facebookProfile: dto.FacebookProfile || '',
    linkedinProfile: dto.LinkedinProfile || '',
    defaultCurrency: dto.DefaultCurrency || 'USD',
    accountType: dto.AccountType || '',
    wantToBuy: dto.WantToBuy,
    wantToSell: dto.WantToSell,
    country: dto.Country || '',
    isSearcheable: dto.ShowMeInPublicSearches,
    favouriteAuctionKeywords: dto.FavouriteAuctionKeywords || '',
    address: mapDtoToAddress(dto.Address, countries),
    description,
    company: dto.Company
      ? {
          id: dto.Company.CompanyId,
          name: dto.Company.Name || '',
          position: dto.Company.Position || '',
          numberOfEmployees: dto.Company.NumberOfEmployees ? dto.Company.NumberOfEmployees.toString() : '',
          web: dto.Company.Web || '',
          registrationId: dto.Company.RegistrationId || '',
          taxId: dto.Company.TaxId || '',
          vatId: dto.Company.VatId || '',
          branches: dto.Company.NumberOfBranches || 1,
          yearlyRevenue: dto.Company.YearlyRevenue ? dto.Company.YearlyRevenue.toString() : '',
          industries: industries || [],
          address: mapDtoToAddress(dto.Company.Address!, countries)
        }
      : initialCompanyState,
    hideInfo
  };
};

// Update only user details portion, not company
export const mapToUserDetailsDTO = (user: User): UserDetailsDTO => {
  const userAddress: AddressDTO | null = user.address.country.id !== 0 ? mapAddressToDto(user.address) : null;

  return {
    UserName: user.userName,
    Email: user.email || '',
    ContactEmailConfirmed: user.isEmailConfirmed,
    FirstName: user.firstName,
    LastName: user.lastName,
    Rating: user.rating,
    AccountType: user.accountType,
    PhoneNumber: user.phoneNumber,
    WantToBuy: user.wantToBuy,
    WantToSell: user.wantToSell,
    Country: user.country,
    DefaultCurrency: user.defaultCurrency,
    FacebookProfile: user.facebookProfile,
    LinkedinProfile: user.linkedinProfile,
    FavouriteAuctionKeywords: user.favouriteAuctionKeywords,
    Address: userAddress,
    ShowMeInPublicSearches: user.isSearcheable,
    Company: null
  };
};

export const mapToCompanyDTO = (company: Company): CompanyDTO => {
  const companyAddress: AddressDTO | null =
    company && company.address.country.id !== 0 ? mapAddressToDto(company.address) : null;

  const companyDTO: CompanyDTO | null = company && {
    CompanyId: company.id,
    Name: company.name,
    TaxId: company.taxId,
    VatId: company.vatId,
    RegistrationId: company.registrationId,
    NumberOfEmployees: company.numberOfEmployees ? Number(company.numberOfEmployees) : 0,
    Web: company.web,
    NumberOfBranches: company.branches,
    YearlyRevenue: company.yearlyRevenue ? Number(company.yearlyRevenue) : 0,
    Industries: Array.from(company.industries, industry => industry.id),
    Address: companyAddress,
    Position: company.position
  };

  return companyDTO;
};

export const mapToUserFromContact = (
  dto: ContactDTO,
  countries: Country[],
  industriesCodeList: Industry[]
): User => {
  if (dto.UserProfile === null) {
    return {
      ...initialUserState,
      userName: dto.Email,
      email: dto.Email,
      firstName: dto.CustomName,
      coveredUsername: dto.Email ? transformToStarUsername(dto.Email) : '',
      favouriteAuctionKeywords: dto.Keywords,
      address: {
        ...initialDeliveryAddressState,
        country: countries.find(c => c.id === Number(dto.CountryId)) || initialCountry
      },
      company: {
        ...initialUserState.company,
        name: dto.CompanyName,
        web: dto.CompanyWeb,
        industries: dto.Industries?.map(i => industriesCodeList.find(c => i === c.id)!) ?? []
      },
      description: dto.PersonalDescription
    };
  }

  return mapToUser(dto.UserProfile, countries, industriesCodeList, dto.ExternalSorurce, dto.PersonalDescription);
};
