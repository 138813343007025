import { ThunkAction } from 'redux-thunk';

import { wikiApi } from '_api/wikiApi';
import * as codeListApi from '_api/codeListApi';
import { WikiQuestionAndAnswer, CodeList, IAction } from '_types/commonTypes';

export enum IWikiActionTypes {
  LOAD_WIKI_CONTENT = '[LOAD_WIKI_CONTENT] SUCCESS',
  LOAD_WIKI_CATEGORIES = '[LOAD_WIKI_CATEGORIES] SUCCESS',
  ADD_QUESTION_AND_ANSWER = '[ADD_QUESTION_AND_ANSWER] SUCCESS',
  ADD_WIKI_CATEGORY = '[ADD_WIKI_CATEGORY] SUCCESS',
  EDIT_WIKI_QUESTION_AND_ANSWER = '[EDIT_WIKI_QUESTION_AND_ANSWER] SUCCESS',
  EDIT_WIKI_CATEGORY = '[EDIT_WIKI_CATEGORY] SUCCESS',
  REMOVE_WIKI_QUESTION = '[REMOVE_WIKI_QUESTION] SUCCESS',
  REMOVE_WIKI_CATEGORY = '[REMOVE_WIKI_CATEGORY] SUCCESS'
}

interface ILoadWikiContentSuccessAction
  extends IAction<IWikiActionTypes.LOAD_WIKI_CONTENT, { wikiContent: WikiQuestionAndAnswer[] }> {}

interface ILoadWikiCategoriesSuccessAction
  extends IAction<IWikiActionTypes.LOAD_WIKI_CATEGORIES, { wikiCategories: Array<CodeList<number>> }> {}

interface IAddQuestionSuccessAction
  extends IAction<IWikiActionTypes.ADD_QUESTION_AND_ANSWER, { question: WikiQuestionAndAnswer }> {}

interface IAddCategorySuccessAction
  extends IAction<IWikiActionTypes.ADD_WIKI_CATEGORY, { category: CodeList<number> }> {}

interface IEditQuestionSuccessAction
  extends IAction<IWikiActionTypes.EDIT_WIKI_QUESTION_AND_ANSWER, { question: WikiQuestionAndAnswer }> {}

interface IEditCategorySuccessAction
  extends IAction<IWikiActionTypes.EDIT_WIKI_CATEGORY, { category: CodeList<number> }> {}

interface IRemoveQuestionSuccessAction extends IAction<IWikiActionTypes.REMOVE_WIKI_QUESTION, { id: number }> {}

interface IRemoveCategorySuccessAction extends IAction<IWikiActionTypes.REMOVE_WIKI_CATEGORY, { id: number }> {}

function loadWikiContent(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi
      .fetchWikiContent()
      .then(wikiContent => {
        dispatch(success(wikiContent));
        return wikiContent;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(wikiContent: WikiQuestionAndAnswer[]): ILoadWikiContentSuccessAction {
    return {
      type: IWikiActionTypes.LOAD_WIKI_CONTENT,
      payload: { wikiContent }
    };
  }
}

function loadWikiCategories(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListApi
      .fetchWikiCategories()
      .then(wikiCategories => {
        dispatch(success(wikiCategories));
        return wikiCategories;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(wikiCategories: Array<CodeList<number>>): ILoadWikiCategoriesSuccessAction {
    return {
      type: IWikiActionTypes.LOAD_WIKI_CATEGORIES,
      payload: { wikiCategories }
    };
  }
}

function addQuestion(question: WikiQuestionAndAnswer): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.addQuestionAndAnswer(question).then(res => dispatch(success({ ...question, id: res.data.Id })));
  };

  function success(question: WikiQuestionAndAnswer): IAddQuestionSuccessAction {
    return {
      type: IWikiActionTypes.ADD_QUESTION_AND_ANSWER,
      payload: { question }
    };
  }
}

function addWikiCategory(category: CodeList<number>): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.addCategory(category.name).then(res => dispatch(success({ ...category, id: res.data })));
  };

  function success(category: CodeList<number>): IAddCategorySuccessAction {
    return {
      type: IWikiActionTypes.ADD_WIKI_CATEGORY,
      payload: { category }
    };
  }
}

function editQuestion(question: WikiQuestionAndAnswer): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.editQuestionAndAnswer(question).then(() => dispatch(success(question)));
  };

  function success(question: WikiQuestionAndAnswer): IEditQuestionSuccessAction {
    return {
      type: IWikiActionTypes.EDIT_WIKI_QUESTION_AND_ANSWER,
      payload: { question }
    };
  }
}

function editWikiCategory(category: CodeList<number>): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.editCategory(category).then(() => dispatch(success(category)));
  };

  function success(category: CodeList<number>): IEditCategorySuccessAction {
    return {
      type: IWikiActionTypes.EDIT_WIKI_CATEGORY,
      payload: { category }
    };
  }
}

function removeQuestion(id: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.removeQuestion(id).then(() => dispatch(success(id)));
  };

  function success(id: number): IRemoveQuestionSuccessAction {
    return {
      type: IWikiActionTypes.REMOVE_WIKI_QUESTION,
      payload: { id }
    };
  }
}

function removeWikiCategory(id: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return wikiApi.removeCategory(id).then(() => dispatch(success(id)));
  };

  function success(id: number): IRemoveCategorySuccessAction {
    return {
      type: IWikiActionTypes.REMOVE_WIKI_CATEGORY,
      payload: { id }
    };
  }
}

export type WikiActions = ILoadWikiContentSuccessAction | ILoadWikiCategoriesSuccessAction;

export type AdminWikiActions =
  | ILoadWikiContentSuccessAction
  | ILoadWikiCategoriesSuccessAction
  | IAddQuestionSuccessAction
  | IEditQuestionSuccessAction
  | IRemoveQuestionSuccessAction
  | IAddCategorySuccessAction
  | IEditCategorySuccessAction
  | IRemoveCategorySuccessAction;

export {
  loadWikiContent,
  loadWikiCategories,
  addQuestion,
  editQuestion,
  removeQuestion,
  addWikiCategory,
  removeWikiCategory,
  editWikiCategory
};
