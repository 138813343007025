import { NewsletterAction, IActionTypes } from '_actions/admin/newsletterActions';
import { NewsletterStatus } from '_types/commonTypes';

export interface IState {
  isSubscribing: boolean;
  subscribedStatus: NewsletterStatus;
}

export const initialState: IState = {
  isSubscribing: false,
  subscribedStatus: NewsletterStatus.UNSUBSCRIBED
};

export function reducer(state: IState = initialState, action: NewsletterAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_STATUS_SUCCESS: {
      return {
        ...state,
        subscribedStatus: action.payload.status
      };
    }
    case IActionTypes.TOGGLE_SUBSCRIBING: {
      return {
        ...state,
        isSubscribing: !state.isSubscribing
      };
    }
    case IActionTypes.SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        subscribedStatus: action.payload.status
      };
    }
    case IActionTypes.UNSUBSCRIBE_SUCCESS: {
      return {
        ...state,
        subscribedStatus: action.payload.status
      };
    }
    default:
      return state;
  }
}
