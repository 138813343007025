import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import MaterialIcon from '../icons/MaterialIcon';

export const ShowSuccessToast = (text: string, options?: ToastOptions) => {
  toast.success(
    <span className="valign-wrapper">
      <MaterialIcon iconName="done" iconClassName="md-36 margin-right-10" />
      {text}
    </span>,
    {
      className: 'alert-toast primary-bg-clr',
      autoClose: 5000,
      ...options
    }
  );
};

export const ShowErrorToast = (text: string, options?: ToastOptions) => {
  toast.error(
    <span className="valign-wrapper">
      <MaterialIcon iconName="error" iconClassName="md-36 margin-right-10" />
      {text}
    </span>,
    {
      autoClose: 5000,
      ...options
    }
  );
};

export const ShowInfoToast = (text: string, options?: ToastOptions) => {
  toast.error(
    <span className="valign-wrapper">
      <MaterialIcon iconName="info" iconClassName="md-36 margin-right-10" />
      {text}
    </span>,
    {
      className: 'alert-toast blue-bg-clr',
      autoClose: 5000,
      ...options
    }
  );
};
