import { ThunkAction } from 'redux-thunk';

import * as tenderService from '_api/tenderApi';
import {
  TenderTemplate,
  Tender,
  SuppliersBidChange,
  SuppliersItemBid,
  SuppliersCriterionBid,
  TenderStatus,
  Message,
  MustHaveCondition,
  MustHaveConditionResponse
} from '_types/tenderTypes';
import {
  Supplier,
  SuppliersTenderStatus,
  SuppliersPosition,
  User,
  Address,
  AddressSource,
  AttachmentReq,
  IAction
} from '_types/commonTypes';
import { ItemDetails, CriterionDetails, Attachment, CodeList } from '_types/commonTypes';
import {
  mapTender,
  getItemsDetailsWithIds,
  getCriteriaDetailsWithIds,
  mapMustHaveConditionFromDTO,
  getMustHaveConditionsWithIds
} from '_mappers/tenderMapper';
import { IApplicationState } from 'core/reducer';
import {
  createSupplierBE,
  sendInvitationsToSuppliersBE,
  respondToInvitationBySellerBE,
  blockSupplierBE,
  unblockSupplierBE,
  uploadSuppliersAttachmentBE,
  editOfferDescriptionBE,
  tenderSupplierApi,
  addTenderToFavoritesBE
} from '_api/tenderSupplierApi';
import { mapToSupplierFromTenderSupplierDTO } from '_mappers/tenderSupplierMapper';
import { upsertDeliveryAddressCodelist } from './codeListActions';
import { addDemoBiddersBE } from '_api/tenderSupplierApi';
import { mapDtoToAddress } from '_mappers/commonMapper';
import { ShowErrorToast } from '_components/toasts/toast';
import { String } from '_libs/String';

export enum IActionTypes {
  CREATE_TENDER_SUCCESS = '[CREATE_TENDER] SUCCESS',
  UPDATE_TENDER_PROPS_SUCCESS = '[UPDATE_TENDER_PROPS] SUCCESS',
  UPDATE_TENDER_ON_BACKEND_SUCCESS = '[UPDATE_TENDER_ON_BACKEND] SUCCESS',
  UPDATE_TENDER_STATUS = '[UPDATE_TENDER_STATUS] SUCCESS',
  LOAD_TEMPLATE_SUCCESS = '[LOAD_TEMPLATE] SUCCESS',
  LOAD_TENDER_SUCCESS = '[LOAD_TENDER] SUCCESS',
  ADD_NEW_ITEM_DETAILS_SUCCESS = '[ADD_NEW_ITEM_DETAILS] SUCCESS',
  UPDATE_ITEM_DETAILS_SUCCESS = '[UPDATE_ITEM_DETAILS] SUCCESS',
  REMOVE_ITEM_DETAILS_SUCCESS = '[REMOVE_ITEM_DETAILS] SUCCESS',
  ADD_NEW_CRITERION_DETAILS_SUCCESS = '[ADD_NEW_CRITERION_DETAILS] SUCCESS',
  UPDATE_CRITERION_DETAILS_SUCCESS = '[UPDATE_CRITERION_DETAILS] SUCCESS',
  REMOVE_CRITERION_DETAILS_SUCCESS = '[REMOVE_CRITERION_DETAILS] SUCCESS',
  START_UPLOADING_ATTACHMENTS = 'START_UPLOADING_ATTACHMENTS',
  END_UPLOADING_ATTACHMENTS = 'END_UPLOADING_ATTACHMENTS',
  ADD_NEW_ATTACHMENTS_SUCCESS = '[ADD_NEW_ATTACHMENTS] SUCCESS',
  REMOVE_ATTACHMENT_SUCCESS = '[REMOVE_ATTACHMENT] SUCCESS',
  ADD_NEW_MUST_HAVE_CONDITION_SUCCESS = '[ADD_NEW_MUST_HAVE_CONDITION] SUCCESS',
  UPDATE_MUST_HAVE_CONDITION_SUCCESS = '[UPDATE_MUST_HAVE_CONDITION] SUCCESS',
  REMOVE_MUST_HAVE_CONDITION_SUCCESS = '[REMOVE_MUST_HAVE_CONDITION] SUCCESS',
  UPLOAD_MAIN_PICTURE_SUCCESS = '[UPLOAD_MAIN_PICTURE] SUCCESS',
  REMOVE_MAIN_PICTURE_SUCCESS = '[REMOVE_MAIN_PICTURE] SUCCESS',
  RESET_NEW_TENDER_SUCCESS = '[RESET_NEW_TENDER] SUCCESS',
  UPDATE_NEW_DELIVERY_ADDRESS_PROPS_SUCCESS = '[UPDATE_NEW_DELIVERY_ADDRESS_PROPS] SUCCESS',
  SUBMIT_CHANGE_SUCCESS = '[SUBMIT_CHANGE] SUCCESS',
  ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT',
  ADD_SUPPLIER_SUCCESS = '[ADD_SUPPLIER] SUCCESS',
  REMOVE_SUPPLIER_SUCCESS = '[REMOVE_SUPPLIER] SUCCESS',
  UNBLOCK_SUPPLIER = '[UNBLOCK_SUPPLIER] SUCCESS',
  UPDATE_SUPPLIERS_BLOCK_REASON = 'UPDATE_SUPPLIERS_BLOCK_REASON',
  START_UPLOADING_SUPPLIERS_ATTACHMENTS = 'START_UPLOADING_SUPPLIERS_ATTACHMENTS',
  END_UPLOADING_SUPPLIERS_ATTACHMENTS = 'END_UPLOADING_SUPPLIERS_ATTACHMENTS',
  ADD_NEW_SUPPLIERS_ATTACHMENTS_SUCCESS = '[ADD_NEW_SUPPLIERS_ATTACHMENTS] SUCCESS',
  REMOVE_SUPPLIERS_ATTACHMENT_SUCCESS = '[REMOVE_SUPPLIERS_ATTACHMENT_SUCCESS] SUCCESS',
  RESPOND_TO_INVITATION_BY_SUPPLIER_SUCCESS = '[RESPOND_TO_INVITATION_BY_SUPPLIER] SUCCESS',
  SEND_INVITATIONS_TO_SUPPLIERS = '[SEND_INVITATIONS_TO_SUPPLIERS] SUCCESS',
  UPDATE_SUPPLIERS_MHC_RESPONSES = '[UPDATE_SUPPLIERS_MHC_RESPONSES] SUCCESS',
  UPDATE_SUPPLIERS_ITEM_BID_SUCCESS = '[UPDATE_SUPPLIERS_ITEM_BID] SUCCESS',
  UPDATE_SUPPLIERS_CRITERION_BID_SUCCESS = '[UPDATE_SUPPLIERS_CRITERION_BID] SUCCESS',
  UPDATE_SUPPLIERS_POSITIONS_SUCCESS = '[UPDATE_SUPPLIERS_POSITIONS] SUCCESS',
  UPDATE_SUPPLIERS_OFFER_DESCRIPTION_SUCCESS = '[UPDATE_SUPPLIERS_OFFER_DESCRIPTION] SUCCESS',
  UPDATE_SUPPLIERS_PARTICIPATION_STATUS = '[UPDATE_SUPPLIERS_PARTICIPATION_STATUS] SUCCESS',
  UPDATE_SUPPLIERS_LAST_ACTIVITY = 'UPDATE_SUPPLIERS_LAST_ACTIVITY',
  SCHEDULE_TENDER_SUCCESS = '[SCHEDULE_TENDER] SUCCESS',
  ADD_BID_CHANGE = 'ADD_BID_CHANGE',
  REMOVE_BID_CHANGE = 'REMOVE_BID_CHANGE',
  TOGGLE_HIDE_IRRELEVANT_SUPPLIERS = 'TOGGLE_HIDE_IRRELEVANT_SUPPLIERS',
  TOGGLE_FAVORITE = '[TENDER] TOGGLE_FAVORITE'
}

interface ICreateTenderSuccessAction {
  type: IActionTypes.CREATE_TENDER_SUCCESS;
  payload: { tenderId: number; user: User };
}

interface IUpdateTenderPropsSuccessAction {
  type: IActionTypes.UPDATE_TENDER_PROPS_SUCCESS;
  payload: { name: string; value: any };
  shouldUpdateOnBackend: boolean;
}

interface IUpdateTenderOnBackendSuccessAction {
  type: IActionTypes.UPDATE_TENDER_ON_BACKEND_SUCCESS;
  payload: {
    items: Array<ItemDetails>;
    criteria: Array<CriterionDetails>;
    deliveryAddressId: number;
    mustHaveConditions: Array<MustHaveCondition>;
    attachments: Array<Attachment>;
    mainPicutreUrl: string;
  };
}

interface IUpdateTenderStatusAction {
  type: IActionTypes.UPDATE_TENDER_STATUS;
  payload: { statusId: number };
}

interface ILoadTemplateSuccessAction {
  type: IActionTypes.LOAD_TEMPLATE_SUCCESS;
  payload: { template: TenderTemplate };
}

interface ILoadTenderSuccessAction {
  type: IActionTypes.LOAD_TENDER_SUCCESS;
  payload: { tender: Tender };
}

interface IAddNewItemDetailsSucessAction {
  type: IActionTypes.ADD_NEW_ITEM_DETAILS_SUCCESS;
  payload: { item: ItemDetails };
}

interface IUpdateItemDetailsSucessAction {
  type: IActionTypes.UPDATE_ITEM_DETAILS_SUCCESS;
  payload: { index: number; name: string; value: any };
  shouldUpdateOnBackend: boolean;
}

interface IRemoveItemDetailsSucessAction {
  type: IActionTypes.REMOVE_ITEM_DETAILS_SUCCESS;
  payload: { index: number };
}

interface IAddNewCriterionDetailsSucessAction {
  type: IActionTypes.ADD_NEW_CRITERION_DETAILS_SUCCESS;
  payload: { criterion: CriterionDetails };
}

interface IUpdateCriterionDetailsSucessAction {
  type: IActionTypes.UPDATE_CRITERION_DETAILS_SUCCESS;
  payload: { index: number; name: string; value: any };
  shouldUpdateOnBackend: boolean;
}

interface IAddNewAttachmentsSucessAction {
  type: IActionTypes.ADD_NEW_ATTACHMENTS_SUCCESS;
  payload: { attachments: Attachment[] };
}
interface IStartUploadingAttachmentsAction {
  type: IActionTypes.START_UPLOADING_ATTACHMENTS;
}
interface IEndUploadingAttachmentsAction {
  type: IActionTypes.END_UPLOADING_ATTACHMENTS;
}

interface IRemoveAttachmentSucessAction {
  type: IActionTypes.REMOVE_ATTACHMENT_SUCCESS;
  payload: { index: number };
}

interface IRemoveSuppliersAttachmentSucessAction {
  type: IActionTypes.REMOVE_SUPPLIERS_ATTACHMENT_SUCCESS;
  payload: { id: number };
}
interface IAddNewSuppliersAttachmentsSucessAction {
  type: IActionTypes.ADD_NEW_SUPPLIERS_ATTACHMENTS_SUCCESS;
  payload: { username: string; attachments: Attachment[] };
}
interface IStartUploadingSuppliersAttachmentsAction {
  type: IActionTypes.START_UPLOADING_SUPPLIERS_ATTACHMENTS;
}
interface IEndUploadingSuppliersAttachmentsAction {
  type: IActionTypes.END_UPLOADING_SUPPLIERS_ATTACHMENTS;
}

interface IRemoveCriterionDetailsSucessAction {
  type: IActionTypes.REMOVE_CRITERION_DETAILS_SUCCESS;
  payload: { index: number };
}

interface IAddNewMustHaveConditionSucessAction {
  type: IActionTypes.ADD_NEW_MUST_HAVE_CONDITION_SUCCESS;
  payload: { condition: MustHaveCondition };
}

interface IUpdateMustHaveConditionSucessAction {
  type: IActionTypes.UPDATE_MUST_HAVE_CONDITION_SUCCESS;
  payload: { index: number; condition: MustHaveCondition };
}

interface IRemoveMustHaveConditionSucessAction {
  type: IActionTypes.REMOVE_MUST_HAVE_CONDITION_SUCCESS;
  payload: { index: number };
}

interface IResetNewTenderSuccessAction {
  type: IActionTypes.RESET_NEW_TENDER_SUCCESS;
  payload: {};
}

interface IUploadMainPictureSuccess extends IAction<IActionTypes.UPLOAD_MAIN_PICTURE_SUCCESS, { url: string }> {}
interface IRemoveMainPictureSuccess extends IAction<IActionTypes.REMOVE_MAIN_PICTURE_SUCCESS, {}> {}

interface ISendInvitationsToSuppliersSuccessAction {
  type: IActionTypes.SEND_INVITATIONS_TO_SUPPLIERS;
  payload: { emails: string[]; invitationStatus: CodeList<number> };
}
interface IRespondToInvitationBySupplierSuccessAction {
  type: IActionTypes.RESPOND_TO_INVITATION_BY_SUPPLIER_SUCCESS;
  payload: { response: boolean; invitationStatusCodeList: Array<CodeList<number>> };
}

interface IAddSupplierSuccessAction {
  type: IActionTypes.ADD_SUPPLIER_SUCCESS;
  payload: { supplier: Supplier };
  shouldUpdateOnBackend: boolean;
}

interface IRemoveSupplierSuccessAction
  extends IAction<IActionTypes.REMOVE_SUPPLIER_SUCCESS, { username: string }> {}

interface IUpdateSuppliersMHCResonsesSuccessAction
  extends IAction<
    IActionTypes.UPDATE_SUPPLIERS_MHC_RESPONSES,
    { username: string; responses: MustHaveConditionResponse[] }
  > {}

interface IUnblockSupplierSuccessAction {
  type: IActionTypes.UNBLOCK_SUPPLIER;
  payload: { userName: string; status: CodeList<number> };
}

interface IAddAnnouncementAction extends IAction<IActionTypes.ADD_ANNOUNCEMENT, { announcement: Message }> {}

interface IUpdateNewDeliveryAddressSuccessAction {
  type: IActionTypes.UPDATE_NEW_DELIVERY_ADDRESS_PROPS_SUCCESS;
  payload: { name: string; value: any };
  shouldUpdateOnBackend: boolean;
}

interface ISUbmitChangeSuccess {
  type: IActionTypes.SUBMIT_CHANGE_SUCCESS;
}

interface IUpdateSuppliersItemBidSuccess {
  type: IActionTypes.UPDATE_SUPPLIERS_ITEM_BID_SUCCESS;
  payload: { suppliersItemBid: SuppliersItemBid };
}

interface IUpdateSuppliersCriterionBidSuccess {
  type: IActionTypes.UPDATE_SUPPLIERS_CRITERION_BID_SUCCESS;
  payload: { suppliersCriterionBid: SuppliersCriterionBid };
}

interface IUpdateSuppliersPositionsSuccess {
  type: IActionTypes.UPDATE_SUPPLIERS_POSITIONS_SUCCESS;
  payload: { suppliersPositions: Array<SuppliersPosition> };
}

interface IUpdateSuppliersOfferDescriptionSuccess {
  type: IActionTypes.UPDATE_SUPPLIERS_OFFER_DESCRIPTION_SUCCESS;
  payload: { userName: string; description: string };
}

interface IUpdateSuppliersBlockReasonAction {
  type: IActionTypes.UPDATE_SUPPLIERS_BLOCK_REASON;
  payload: { userName: string; reason: string };
}

interface IUpdateSuppliersParticipationStatusSuccess {
  type: IActionTypes.UPDATE_SUPPLIERS_PARTICIPATION_STATUS;
  payload: { userName: string; status: CodeList<number> };
}

interface IUpdateSuppliersLastActivityAction
  extends IAction<IActionTypes.UPDATE_SUPPLIERS_LAST_ACTIVITY, { username: string; date: string }> {}

interface IScheduleTenderSuccess {
  type: IActionTypes.SCHEDULE_TENDER_SUCCESS;
  payload: { tenderStatusId: number };
}

interface IAddBidChangeAction {
  type: IActionTypes.ADD_BID_CHANGE;
  payload: { bid: SuppliersBidChange };
}

interface IRemoveBidChangeAction {
  type: IActionTypes.REMOVE_BID_CHANGE;
  payload: { bid: SuppliersBidChange };
}

interface IToggleHideIrelevantSuppliers {
  type: IActionTypes.TOGGLE_HIDE_IRRELEVANT_SUPPLIERS;
}

interface IToggleFavoriteAction {
  type: IActionTypes.TOGGLE_FAVORITE;
  payload: { isFavorite };
}

function createNewTender(): ThunkAction<Promise<number>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const user = getState().authentication.user;

    return tenderService
      .createNewTenderBE()
      .then(tenderId => {
        dispatch(success(tenderId, user));
        return tenderId;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(tenderId: number, user: User): ICreateTenderSuccessAction {
    return { type: IActionTypes.CREATE_TENDER_SUCCESS, payload: { tenderId, user } };
  }
}

/**
 * Updates tender root property and calls update tender on backend
 * @param name - property name
 * @param value - property value
 * LEGACY USE UPDATETENDER
 */
function updateTenderProps(
  name: string,
  value: any,
  shouldUpdateOnBackend: boolean = true
): ThunkAction<void, any, null, any> {
  return dispatch => {
    dispatch(updateTenderPropsSuccess(name, value, shouldUpdateOnBackend));
  };
}

function updateTenderPropsSuccess(
  name: string,
  value: any,
  shouldUpdateOnBackend: boolean
): IUpdateTenderPropsSuccessAction {
  return {
    type: IActionTypes.UPDATE_TENDER_PROPS_SUCCESS,
    payload: { name, value },
    shouldUpdateOnBackend
  };
}

function updateTender(name: string, value: any): ThunkAction<Promise<Tender>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const currTender = getState().tender.currentTender;
    const updTender = { ...currTender, [name]: value };

    return tenderService.updateTenderBE(updTender).then(res => {
      dispatch(updateTenderPropsSuccess(name, value, false));
      return mapTender(res.data, getState().codeList);
    });
  };
}

/**
 * Makes api call to update tender on backend, and gets Ids for items and
 * criteria
 */
function updateTenderOnBackend(): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const currentTender = getState().tender.currentTender;

    return tenderService
      .updateTenderBE(currentTender)
      .then(res => {
        const items = getItemsDetailsWithIds(res.data, currentTender);
        const criteria = getCriteriaDetailsWithIds(res.data, currentTender);
        const mustHaveConditions = getMustHaveConditionsWithIds(res.data, currentTender);
        const deliveryAddressId = res.data.DeliveryAddress ? res.data.DeliveryAddress.AddressId : 0;
        const mainPictureUrl = res.data.MainPictureUrl;
        const attachments: Attachment[] = res.data.Attachments
          ? res.data.Attachments.map(a => ({
              id: a.AttachmentId,
              name: a.FileName
            }))
          : [];

        dispatch(
          successUpdateTenderOnBe(
            items,
            criteria,
            deliveryAddressId,
            mustHaveConditions,
            attachments,
            mainPictureUrl
          )
        );
      })
      .catch(error => {
        throw error;
      });
  };
}

function addAnnouncement(announcement: Message): IAddAnnouncementAction {
  return { type: IActionTypes.ADD_ANNOUNCEMENT, payload: { announcement } };
}

function successUpdateTenderOnBe(
  items: Array<ItemDetails>,
  criteria: Array<CriterionDetails>,
  deliveryAddressId: number,
  mustHaveConditions: Array<MustHaveCondition>,
  attachments: Array<Attachment>,
  mainPicutreUrl: string
): IUpdateTenderOnBackendSuccessAction {
  return {
    type: IActionTypes.UPDATE_TENDER_ON_BACKEND_SUCCESS,
    payload: { items, criteria, deliveryAddressId, mustHaveConditions, attachments, mainPicutreUrl }
  };
}

function updateTenderStatus(statusId: number): IUpdateTenderStatusAction {
  return { type: IActionTypes.UPDATE_TENDER_STATUS, payload: { statusId } };
}

/**
 * Loads tender defaults
 */
function loadTemplateToTender(template: TenderTemplate): ILoadTemplateSuccessAction {
  return { type: IActionTypes.LOAD_TEMPLATE_SUCCESS, payload: { template } };
}

/**
 * Loads tender by id from backend and saves it to current tender
 * @param id - tender id
 */
function loadTenderById(id: number): ThunkAction<Promise<Tender | null>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return tenderService
      .fetchTenderBE(id)
      .then(res => mapTender(res.data, getState().codeList))
      .then(tender => {
        dispatch(loadTenderSuccess(tender));
        dispatch(upsertDeliveryAddressCodelist(tender.addressSource, tender.newDeliveryAddress));

        return tender;
      })
      .catch(err => {
        console.log(err);
        return null;
      });
  };
}

function loadTenderForAuctionOverview(id: number): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return tenderService
      .fetchTenderBE(id)
      .then(res => mapTender(res.data, getState().codeList))
      .then(tender => {
        dispatch(loadTenderSuccess(tender));

        return tender;
      })
      .catch(err => {
        return null;
      });
  };
}

/**
 * Schedules tender and returns new tender status
 * @param id - tender id
 */
function scheduleTender(id: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService.scheduleTenderBE(id).then(() => dispatch(success(TenderStatus.SCHEDULED)));
  };

  function success(tenderStatusId: number): IScheduleTenderSuccess {
    return { type: IActionTypes.SCHEDULE_TENDER_SUCCESS, payload: { tenderStatusId } };
  }
}

function toggleFavoriteTender(tenderId: number, isFavorite: boolean): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return addTenderToFavoritesBE(tenderId, isFavorite).then(() => dispatch(success(isFavorite)));
  };

  function success(isFavorite: boolean): IToggleFavoriteAction {
    return { type: IActionTypes.TOGGLE_FAVORITE, payload: { isFavorite } };
  }
}

function duplicateTender(id: number): ThunkAction<Promise<void>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return tenderService
      .duplicateTenderBE(id)
      .then(res => mapTender(res.data, getState().codeList))
      .then(tender => {
        dispatch(loadTenderSuccess(tender));

        dispatch(upsertDeliveryAddressCodelist(tender.addressSource, tender.newDeliveryAddress));
      });
  };
}

function loadTenderSuccess(tender: Tender): ILoadTenderSuccessAction {
  return { type: IActionTypes.LOAD_TENDER_SUCCESS, payload: { tender } };
}

// Item details actions section

/**
 * Adds new item details to store and after store is updated, triggers update tender on backend
 * @param item
 */
function addNewItemDetails(item: ItemDetails): ThunkAction<void, any, null, any> {
  return dispatch => {
    dispatch(success(item));
  };
  function success(item: ItemDetails): IAddNewItemDetailsSucessAction {
    return { type: IActionTypes.ADD_NEW_ITEM_DETAILS_SUCCESS, payload: { item } };
  }
}

function updateItemDetails(index: number, name: string, value: any, shouldUpdateOnBackend: boolean = true) {
  return success(index, name, value);

  function success(index: number, name: string, value: any): IUpdateItemDetailsSucessAction {
    return {
      type: IActionTypes.UPDATE_ITEM_DETAILS_SUCCESS,
      payload: { index, name, value },
      shouldUpdateOnBackend
    };
  }
}

function removeItemDetails(index: number) {
  return success(index);

  function success(index: number): IRemoveItemDetailsSucessAction {
    return { type: IActionTypes.REMOVE_ITEM_DETAILS_SUCCESS, payload: { index } };
  }
}

// Criterion details actions section
function addNewCriterionDetails(criterion: CriterionDetails) {
  return success(criterion);

  function success(criterion: CriterionDetails): IAddNewCriterionDetailsSucessAction {
    return {
      type: IActionTypes.ADD_NEW_CRITERION_DETAILS_SUCCESS,
      payload: { criterion }
    };
  }
}

function updateCriterionDetails(index: number, name: string, value: any, shouldUpdateOnBackend: boolean = true) {
  return success(index, name, value);

  function success(index: number, name: string, value: any): IUpdateCriterionDetailsSucessAction {
    return {
      type: IActionTypes.UPDATE_CRITERION_DETAILS_SUCCESS,
      payload: { index, name, value },
      shouldUpdateOnBackend
    };
  }
}

function removeCriterionDetails(index: number) {
  return success(index);

  function success(index: number): IRemoveCriterionDetailsSucessAction {
    return {
      type: IActionTypes.REMOVE_CRITERION_DETAILS_SUCCESS,
      payload: { index }
    };
  }
}

// Attachments section
function uploadAttachments(attachments: AttachmentReq[]): ThunkAction<Promise<any>, any, null, any> {
  return async (dispatch, getState: () => IApplicationState) => {
    let errorCounter = 0;
    dispatch(startUploading());
    const tenderId = getState().tender.currentTender.tenderId;
    const localization = getState().common.localization;

    const promises: any[] = [];
    attachments.forEach(a => {
      promises.push(
        tenderService
          .uploadAttachmentBE(tenderId, a.data)
          .then(res => {
            dispatch(success([{ id: res.data[0], name: a.name }]));
          })
          .catch(err => {
            errorCounter += 1;
            ShowErrorToast(String.format(localization.attachment_upload_error_toast, a.name), {
              autoClose: false
            });
          })
      );
    });

    await Promise.all(promises);
    dispatch(endUploading());

    return Promise.resolve(errorCounter);
  };

  function startUploading(): IStartUploadingAttachmentsAction {
    return { type: IActionTypes.START_UPLOADING_ATTACHMENTS };
  }

  function endUploading(): IEndUploadingAttachmentsAction {
    return { type: IActionTypes.END_UPLOADING_ATTACHMENTS };
  }

  function success(attachments: Attachment[]): IAddNewAttachmentsSucessAction {
    return {
      type: IActionTypes.ADD_NEW_ATTACHMENTS_SUCCESS,
      payload: { attachments }
    };
  }
}

function uploadMainPicture(tenderId: number, file: FormData): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService.uploadMainPicture(tenderId, file).then(res => dispatch(success(res.data)));
  };

  function success(url: string): IUploadMainPictureSuccess {
    return { type: IActionTypes.UPLOAD_MAIN_PICTURE_SUCCESS, payload: { url } };
  }
}

function removeMainPicture(tenderId: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService.removeMainPicture(tenderId).then(res => dispatch(success()));
  };

  function success(): IRemoveMainPictureSuccess {
    return { type: IActionTypes.REMOVE_MAIN_PICTURE_SUCCESS, payload: {} };
  }
}

function removeAttachment(index: number): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const attachment = getState().tender.currentTender.attachments.list[index];
    return tenderService.removeAttachmentBE(attachment.id).then(res => dispatch(success(index)));
  };

  function success(index: number): IRemoveAttachmentSucessAction {
    return { type: IActionTypes.REMOVE_ATTACHMENT_SUCCESS, payload: { index } };
  }
}

function resetCurrentTender() {
  return { type: IActionTypes.RESET_NEW_TENDER_SUCCESS, payload: {} };
}

// Must have condition section
function addNewMusHaveCondition(condition: MustHaveCondition): ThunkAction<Promise<void>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tender = getState().tender.currentTender;
    const updTender: Tender = { ...tender, mustHaveConditions: [...tender.mustHaveConditions, condition] };

    return tenderService.updateTenderBE(updTender).then(res => {
      const conditionWithId = mapMustHaveConditionFromDTO(
        res.data.MustHaveConditions[res.data.MustHaveConditions.length - 1]
      );

      dispatch(success(conditionWithId));
    });
  };

  function success(condition: MustHaveCondition): IAddNewMustHaveConditionSucessAction {
    return {
      type: IActionTypes.ADD_NEW_MUST_HAVE_CONDITION_SUCCESS,
      payload: { condition }
    };
  }
}

function updateMustHaveCondition(index: number, condition: MustHaveCondition) {
  return success(index, condition);

  function success(index: number, condition: MustHaveCondition): IUpdateMustHaveConditionSucessAction {
    return {
      type: IActionTypes.UPDATE_MUST_HAVE_CONDITION_SUCCESS,
      payload: { index, condition }
    };
  }
}

function removeMustHaveCondition(index: number) {
  return success(index);

  function success(index: number): IRemoveMustHaveConditionSucessAction {
    return {
      type: IActionTypes.REMOVE_MUST_HAVE_CONDITION_SUCCESS,
      payload: { index }
    };
  }
}

// New delivery address section
function updateNewDeliveryAddress(
  addressSource: AddressSource,
  address: Address
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const currTender = getState().tender.currentTender;
    const updTender: Tender = {
      ...currTender,
      newDeliveryAddress: address,
      addressSource: addressSource
    };

    return tenderService
      .updateTenderBE(updTender, true)
      .then(res => {
        const deliveryAddressId = res.data.DeliveryAddress ? res.data.DeliveryAddress.AddressId : 0;
        const newAddress = { ...updTender.newDeliveryAddress, id: deliveryAddressId };
        dispatch(updateTenderPropsSuccess('newDeliveryAddress', newAddress, false));
        dispatch(updateTenderPropsSuccess('addressSource', addressSource, false));
        return newAddress;
      })
      .catch(error => {
        return false;
      });
  };
}

// New delivery address section
function updateNewDeliveryAddressProps(
  name: string,
  value: any,
  shouldUpdateOnBackend: boolean = true
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const currTender = getState().tender.currentTender;
    const updatedTender: Tender = {
      ...currTender,
      newDeliveryAddress: { ...currTender.newDeliveryAddress, [name]: value }
    };

    return tenderService
      .updateTenderBE(updatedTender, true)
      .then(res => {
        const items = getItemsDetailsWithIds(res.data, updatedTender);
        const criteria = getCriteriaDetailsWithIds(res.data, updatedTender);
        const deliveryAddressId = res.data.DeliveryAddress ? res.data.DeliveryAddress.AddressId : 0;
        const mustHaveConditions = getMustHaveConditionsWithIds(res.data, updatedTender);
        const mainPictureUrl = res.data.MainPictureUrl;

        const attachments: Attachment[] = res.data.Attachments
          ? res.data.Attachments.map(a => ({
              id: a.AttachmentId,
              name: a.FileName
            }))
          : [];

        dispatch(updateNewDeliveryAddressSuccess(name, value, false));
        dispatch(
          successUpdateTenderOnBe(
            items,
            criteria,
            deliveryAddressId,
            mustHaveConditions,
            attachments,
            mainPictureUrl
          )
        );
        return mapDtoToAddress(res.data.DeliveryAddress, getState().codeList.countries);
      })
      .catch(error => {
        return false;
      });
  };
}

function updateNewDeliveryAddressSuccess(
  name: string,
  value: any,
  shouldUpdateOnBackend: boolean
): IUpdateNewDeliveryAddressSuccessAction {
  return {
    type: IActionTypes.UPDATE_NEW_DELIVERY_ADDRESS_PROPS_SUCCESS,
    payload: { name, value },
    shouldUpdateOnBackend: shouldUpdateOnBackend
  };
}

// Suppliers section

/**
 * Adds supplier by email via backend create supplier that returns supplier DTO
 */
function addSupplierByEmail(email: string): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderId = getState().tender.currentTender.tenderId;
    return createSupplierBE(email, tenderId)
      .then(res => {
        if (res.data.ErrorMessage) Promise.reject(res.data.ErrorMessage);
        return res.data.Supplier;
      })
      .then(supp => {
        return mapToSupplierFromTenderSupplierDTO(
          supp,
          getState().codeList.suppliersInvitationStatuses,
          getState().codeList.countries
        );
      })
      .then(supplier => dispatch(addSupplier(supplier)));
  };
}

/**
 * Adds supplier by email via backend create supplier that returns supplier DTO
 */
function unblockSupplier(tenderId: number, userName: string): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return unblockSupplierBE(tenderId, userName).then(res => {
      dispatch(
        unblockSupplierSuccess(
          userName,
          getState().codeList.suppliersInvitationStatuses.find(s => s.id === res.data)!
        )
      );
    });
  };

  function unblockSupplierSuccess(userName: string, status: CodeList<number>): IUnblockSupplierSuccessAction {
    return {
      type: IActionTypes.UNBLOCK_SUPPLIER,
      payload: { userName, status }
    };
  }
}

function blockSupplier(userName: string, message: string): ThunkAction<Promise<void>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderId = getState().tender.currentTender.tenderId;
    return blockSupplierBE(tenderId, userName, message).then(res => {
      dispatch<any>(updateSuppliersParictipationStatus(userName, SuppliersTenderStatus.BLOCKED));
    });
  };
}

function updateSuppliersBlockReason(userName: string, reason: string): IUpdateSuppliersBlockReasonAction {
  return {
    type: IActionTypes.UPDATE_SUPPLIERS_BLOCK_REASON,
    payload: { userName, reason }
  };
}

/**
 * Adds a supplier to app state
 * @param supplier - Supplier
 * @param shouldUpdateOnBackend
 */
function addSupplier(supplier: Supplier, shouldUpdateOnBackend: boolean = true) {
  return addSupplierSuccess(supplier, shouldUpdateOnBackend);
}

function addSupplierSuccess(supplier: Supplier, shouldUpdateOnBackend: boolean = true): IAddSupplierSuccessAction {
  return {
    type: IActionTypes.ADD_SUPPLIER_SUCCESS,
    payload: { supplier },
    shouldUpdateOnBackend
  };
}

function removeSupplier(supplier: Supplier): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderId = getState().tender.currentTender.tenderId;
    return tenderSupplierApi.removeSupplierFromTender(supplier.user.userName, tenderId).then(() => {
      dispatch(success(supplier.user.userName));
    });
  };

  function success(username: string): IRemoveSupplierSuccessAction {
    return { type: IActionTypes.REMOVE_SUPPLIER_SUCCESS, payload: { username } };
  }
}

function updateSuppliersMHCResponses(
  username: string,
  tenderId: number,
  responses: MustHaveConditionResponse[]
): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderSupplierApi
      .saveMHCResponses(
        responses.map(r => ({ MustHaveConditionId: r.mhcId, NewResponse: r.response })),
        tenderId
      )
      .then(res => dispatch(updateSuppliersMHCResponsesSuccess(username, responses)));
  };
}

function updateSuppliersMHCResponsesSuccess(
  username: string,
  responses: MustHaveConditionResponse[]
): IUpdateSuppliersMHCResonsesSuccessAction {
  return { type: IActionTypes.UPDATE_SUPPLIERS_MHC_RESPONSES, payload: { username, responses } };
}

/**
 * Sends invitations to suppliers, on BE is determined whether to send it to external or internal
 * supplier
 * @param suppliers to be invited
 */
function sendInvitationsToSuppliers(
  suppliers: Supplier[],
  message: string
): ThunkAction<Promise<void>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const emailsToSend: string[] = [];
    suppliers.forEach(supp => emailsToSend.push(supp.user.userName));
    return sendInvitationsToSuppliersBE(emailsToSend, getState().tender.currentTender.tenderId, message)
      .then(res => {
        dispatch(
          sendInvitationsToSuppliersSuccess(
            emailsToSend,
            getState().codeList.suppliersInvitationStatuses.find(
              x => x.id === SuppliersTenderStatus.INVITATION_SENT
            )!
          )
        );
      })
      .catch(error => {
        throw error;
      });
  };
}

/**
 * Sends invitation to supplier
 * @param email to invite
 */
function sendInvitationToSupplier(email: string, message: string): ThunkAction<Promise<void>, any, any, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const emails = [email];
    return sendInvitationsToSuppliersBE(emails, getState().tender.currentTender.tenderId, message)
      .then(() => {
        dispatch(
          sendInvitationsToSuppliersSuccess(
            emails,
            getState().codeList.suppliersInvitationStatuses.find(
              x => x.id === SuppliersTenderStatus.INVITATION_SENT
            )!
          )
        );
      })
      .catch(error => {
        throw error;
      });
  };
}

function sendInvitationsToSuppliersSuccess(
  emails: string[],
  invitationStatus: CodeList<number>
): ISendInvitationsToSuppliersSuccessAction {
  return {
    type: IActionTypes.SEND_INVITATIONS_TO_SUPPLIERS,
    payload: { emails, invitationStatus }
  };
}

function leaveAuction(reason: string): ThunkAction<Promise<boolean>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderId = getState().tender.currentTender.tenderId;

    return tenderSupplierApi.leaveTender(tenderId, reason);
  };
}

/**
 * Respond to invitation by changing the supplier status to accepted / rejected
 * @param response
 */
function respondToInvitation(response: boolean): ThunkAction<Promise<boolean>, any, null, any> {
  {
    return (dispatch, getState: () => IApplicationState) => {
      return respondToInvitationBySellerBE(response, getState().tender.currentTender.tenderId)
        .then(() => {
          dispatch(success(response, getState().codeList.suppliersInvitationStatuses));
          return true;
        })
        .catch(error => {
          throw error;
        });
    };
  }

  function success(
    response: boolean,
    invitationStatusCodeList: Array<CodeList<number>>
  ): IRespondToInvitationBySupplierSuccessAction {
    return {
      type: IActionTypes.RESPOND_TO_INVITATION_BY_SUPPLIER_SUCCESS,
      payload: { response, invitationStatusCodeList }
    };
  }
}

// Attachments section
function uploadSuppliersAttachments(attachments: AttachmentReq[]): ThunkAction<Promise<any>, any, null, any> {
  return async (dispatch, getState: () => IApplicationState) => {
    let errorCounter = 0;

    dispatch(startUploading());
    const tenderId = getState().tender.currentTender.tenderId;
    const localization = getState().common.localization;
    const currUser = getState().authentication.user.userName;

    const promises: any[] = [];
    attachments.forEach(a => {
      promises.push(
        uploadSuppliersAttachmentBE(tenderId, a.data)
          .then(res => {
            dispatch(addSuppliersAttachmentsSuccess(currUser, [{ id: res.data[0], name: a.name }]));
          })
          .catch(() => {
            errorCounter += 1;
            ShowErrorToast(String.format(localization.attachment_upload_error_toast, a.name), {
              autoClose: false
            });
          })
      );
    });

    await Promise.all(promises);
    dispatch(endUploading());

    return Promise.resolve(errorCounter);
  };

  function startUploading(): IStartUploadingSuppliersAttachmentsAction {
    return { type: IActionTypes.START_UPLOADING_SUPPLIERS_ATTACHMENTS };
  }

  function endUploading(): IEndUploadingSuppliersAttachmentsAction {
    return { type: IActionTypes.END_UPLOADING_SUPPLIERS_ATTACHMENTS };
  }
}

export function addSuppliersAttachmentsSuccess(
  username: string,
  attachments: Attachment[]
): IAddNewSuppliersAttachmentsSucessAction {
  return {
    type: IActionTypes.ADD_NEW_SUPPLIERS_ATTACHMENTS_SUCCESS,
    payload: { username, attachments }
  };
}

function removeSuppliersAttachment(id: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService.removeAttachmentBE(id).then(() => dispatch(removeSuppliersAttachmentSuccess(id)));
  };
}

function removeSuppliersAttachmentSuccess(id: number): IRemoveSuppliersAttachmentSucessAction {
  return { type: IActionTypes.REMOVE_SUPPLIERS_ATTACHMENT_SUCCESS, payload: { id } };
}

function submitTenderChange(): ISUbmitChangeSuccess {
  return { type: IActionTypes.SUBMIT_CHANGE_SUCCESS };
}

function updateSuppliersItemBid(suppliersItemBid: SuppliersItemBid) {
  return success(suppliersItemBid);
  function success(suppliersItemBid: SuppliersItemBid): IUpdateSuppliersItemBidSuccess {
    return {
      type: IActionTypes.UPDATE_SUPPLIERS_ITEM_BID_SUCCESS,
      payload: { suppliersItemBid }
    };
  }
}

function updateSuppliersCriterionBid(suppliersCriterionBid: SuppliersCriterionBid) {
  return success(suppliersCriterionBid);

  function success(suppliersCriterionBid: SuppliersCriterionBid): IUpdateSuppliersCriterionBidSuccess {
    return {
      type: IActionTypes.UPDATE_SUPPLIERS_CRITERION_BID_SUCCESS,
      payload: { suppliersCriterionBid }
    };
  }
}

function updateSuppliersOfferDescription(
  userName: string,
  description: string
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderId = getState().tender.currentTender.tenderId;

    return editOfferDescriptionBE(tenderId, description).then(res => {
      dispatch(updateSuppliersOfferDescriptionSuccess(userName, res.data));
    });
  };
}

function updateSuppliersOfferDescriptionSuccess(
  userName: string,
  description: string
): IUpdateSuppliersOfferDescriptionSuccess {
  return {
    type: IActionTypes.UPDATE_SUPPLIERS_OFFER_DESCRIPTION_SUCCESS,
    payload: { userName, description }
  };
}

function updateSuppliersPositions(suppliersPositions: Array<SuppliersPosition>) {
  return success(suppliersPositions);

  function success(suppliersPositions: Array<SuppliersPosition>): IUpdateSuppliersPositionsSuccess {
    return {
      type: IActionTypes.UPDATE_SUPPLIERS_POSITIONS_SUCCESS,
      payload: { suppliersPositions }
    };
  }
}

function applyToCurrentTender(
  mhcResponses: MustHaveConditionResponse[]
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const { tenderId } = getState().tender.currentTender;
    const { suppliersInvitationStatuses, countries } = getState().codeList;
    const responsesDTO = mhcResponses.map(m => ({ MustHaveConditionId: m.mhcId, NewResponse: m.response }));

    return tenderSupplierApi.applyToPublicTender(tenderId, responsesDTO).then(data => {
      const supplier = mapToSupplierFromTenderSupplierDTO(data, suppliersInvitationStatuses, countries);

      dispatch(addSupplierSuccess(supplier, false));
    });
  };
}

function goBackToAuction(tenderId: number, reason: string): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const username = getState().authentication.user.userName;
    return tenderSupplierApi.rejoinTender(tenderId, reason).then(res => {
      dispatch(updateSuppliersParictipationStatus(username, SuppliersTenderStatus.PENDING_COMEBACK));
    });
  };
}

function updateSuppliersParictipationStatus(
  userName: string,
  statusId: number
): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const status = getState().codeList.suppliersInvitationStatuses.find(s => s.id === statusId)!;

    dispatch(success(userName, status));
  };
  function success(userName: string, status: CodeList<number>): IUpdateSuppliersParticipationStatusSuccess {
    return {
      type: IActionTypes.UPDATE_SUPPLIERS_PARTICIPATION_STATUS,
      payload: { userName, status }
    };
  }
}

function updateSuppliersLastActivity(username: string, date: string): ThunkAction<void, any, null, any> {
  return dispatch => {
    dispatch(success(username, date));
  };
  function success(username: string, date: string): IUpdateSuppliersLastActivityAction {
    return {
      type: IActionTypes.UPDATE_SUPPLIERS_LAST_ACTIVITY,
      payload: { username, date }
    };
  }
}

function addDemoBidders(tenderId: number): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    addDemoBiddersBE(tenderId)
      .then(res => {
        const suppliersInvitationStatuses = getState().codeList.suppliersInvitationStatuses;
        const countries = getState().codeList.countries;

        res.data.forEach(supplierDto => {
          const supplier = mapToSupplierFromTenderSupplierDTO(supplierDto, suppliersInvitationStatuses, countries);
          dispatch(addSupplier(supplier, false));
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

function addBidChange(suppliersCriterionBid: SuppliersBidChange) {
  return success(suppliersCriterionBid);

  function success(bid: SuppliersBidChange): IAddBidChangeAction {
    return {
      type: IActionTypes.ADD_BID_CHANGE,
      payload: { bid }
    };
  }
}

function removeBidChange(suppliersCriterionBid: SuppliersBidChange) {
  return success(suppliersCriterionBid);

  function success(bid: SuppliersBidChange): IRemoveBidChangeAction {
    return {
      type: IActionTypes.REMOVE_BID_CHANGE,
      payload: { bid }
    };
  }
}

function toggleHideIrrelvantSuppliers(): IToggleHideIrelevantSuppliers {
  return { type: IActionTypes.TOGGLE_HIDE_IRRELEVANT_SUPPLIERS };
}

export type TenderAction =
  | ILoadTemplateSuccessAction
  | ILoadTenderSuccessAction
  | ICreateTenderSuccessAction
  | IUpdateTenderPropsSuccessAction
  | IUpdateTenderOnBackendSuccessAction
  | IToggleFavoriteAction
  | IAddNewItemDetailsSucessAction
  | IUpdateItemDetailsSucessAction
  | IRemoveItemDetailsSucessAction
  | IAddNewCriterionDetailsSucessAction
  | IUpdateCriterionDetailsSucessAction
  | IRemoveCriterionDetailsSucessAction
  | IStartUploadingAttachmentsAction
  | IEndUploadingAttachmentsAction
  | IAddNewAttachmentsSucessAction
  | IStartUploadingSuppliersAttachmentsAction
  | IEndUploadingSuppliersAttachmentsAction
  | IAddNewSuppliersAttachmentsSucessAction
  | IRemoveAttachmentSucessAction
  | IRemoveSuppliersAttachmentSucessAction
  | IResetNewTenderSuccessAction
  | IAddAnnouncementAction
  | IAddSupplierSuccessAction
  | IRemoveSupplierSuccessAction
  | ISendInvitationsToSuppliersSuccessAction
  | IRespondToInvitationBySupplierSuccessAction
  | IAddNewMustHaveConditionSucessAction
  | IUpdateMustHaveConditionSucessAction
  | IRemoveMustHaveConditionSucessAction
  | IUpdateNewDeliveryAddressSuccessAction
  | ISUbmitChangeSuccess
  | IUpdateSuppliersItemBidSuccess
  | IUpdateSuppliersCriterionBidSuccess
  | IUpdateSuppliersOfferDescriptionSuccess
  | IUpdateSuppliersPositionsSuccess
  | IUpdateSuppliersParticipationStatusSuccess
  | IUpdateSuppliersBlockReasonAction
  | IUpdateSuppliersLastActivityAction
  | IScheduleTenderSuccess
  | IUpdateTenderStatusAction
  | IUnblockSupplierSuccessAction
  | IAddBidChangeAction
  | IRemoveBidChangeAction
  | IToggleHideIrelevantSuppliers
  | IUpdateSuppliersMHCResonsesSuccessAction
  | IUploadMainPictureSuccess
  | IRemoveMainPictureSuccess;

export {
  createNewTender,
  updateTenderProps,
  updateTenderOnBackend,
  loadTemplateToTender,
  loadTenderById,
  addNewItemDetails,
  updateItemDetails,
  removeItemDetails,
  addNewCriterionDetails,
  updateCriterionDetails,
  removeCriterionDetails,
  uploadAttachments,
  removeAttachment,
  resetCurrentTender,
  addSupplierByEmail,
  addSupplier,
  removeSupplier,
  sendInvitationToSupplier,
  sendInvitationsToSuppliers,
  addNewMusHaveCondition,
  updateMustHaveCondition,
  removeMustHaveCondition,
  updateNewDeliveryAddressProps,
  submitTenderChange,
  respondToInvitation,
  leaveAuction,
  goBackToAuction,
  updateSuppliersItemBid,
  updateSuppliersCriterionBid,
  updateSuppliersPositions,
  updateSuppliersParictipationStatus,
  uploadSuppliersAttachments,
  updateSuppliersOfferDescription,
  updateSuppliersBlockReason,
  updateSuppliersOfferDescriptionSuccess,
  removeSuppliersAttachment,
  blockSupplier,
  duplicateTender,
  addDemoBidders,
  scheduleTender,
  addAnnouncement,
  loadTenderForAuctionOverview,
  updateTenderStatus,
  updateTender,
  updateNewDeliveryAddress,
  unblockSupplier,
  addBidChange,
  removeBidChange,
  toggleHideIrrelvantSuppliers,
  updateSuppliersLastActivity,
  toggleFavoriteTender,
  removeSuppliersAttachmentSuccess,
  updateSuppliersMHCResponses,
  updateSuppliersMHCResponsesSuccess,
  applyToCurrentTender,
  uploadMainPicture,
  removeMainPicture
};
