import React from 'react';
import * as classNames from 'classnames';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: any;
  label?: string | number | JSX.Element;
  dataTarget?: string;
}

const Button: React.FC<ButtonProps> = ({ children, label, className, dataTarget, ...restProps }) => {
  return (
    <button className={classNames('btn', className)} data-target={dataTarget} {...restProps}>
      {children}
      {label}
    </button>
  );
};

export default Button;
