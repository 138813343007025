import { ITendersActionTypes, TendersAction } from '_actions/tendersActions';
import { Tender } from '_types/tenderTypes';

export interface IState extends Array<Tender> {}

export const initialState: IState = [];

export function reducer(state: IState = initialState, action: TendersAction): IState {
  switch (action.type) {
    case ITendersActionTypes.ADD_TENDERS:
      return [...state, ...action.payload.tenders];
    case ITendersActionTypes.TOGGLE_FAVORITE: {
      let tenders = state.slice();
      const index = tenders.findIndex(t => t.tenderId === action.payload.tenderId);

      if (index > -1) {
        tenders[index] = { ...tenders[index], isFavorite: !tenders[index].isFavorite };
      }

      return tenders;
    }
    case ITendersActionTypes.CLEAR_TENDERS:
      return [];
    default: {
      return state;
    }
  }
}
