import { AddressCodeList, Country } from '_types/commonTypes';
import { mapDtoToAddress } from './commonMapper';

export const mapAddresses = (data: any, countries: Country[]): AddressCodeList[] => {
  const result: AddressCodeList[] = [];

  data.forEach(element => {
    result.push({
      addressSource: element.AddressSource,
      address: mapDtoToAddress(element.AddressDto, countries)
    });
  });

  return result;
};
