import { ThunkAction } from 'redux-thunk';

import { fetchNotificationsBE, MarkMultipleNotificationsAsReadBE } from '_api/notificationApi';
import { mapToNotifications } from '_mappers/notificationMapper';
import { Notification } from '_types/commonTypes';
import { IApplicationState } from 'core/reducer';
import favicon from '_assets/images/favicon.png';
import faviconNotification from '_assets/images/favicon-notification.png';
import { updateFavicon } from '_libs/utils';

export enum INotificationActionTypes {
  ADD_NOTIFICATION = '[ADD_NOTIFICATION] SUCCESS',
  LOAD_NOTIFICATIONS_SUCCESS = '[LOAD_NOTIFICATIONS] SUCCESS',
  MARK_ALL_UNREAD_NOTIFICATIONS_AS_READ = '[MARK_ALL_UNREAD_NOTIFICATIONS_AS_READ] SUCCESS'
}

interface IAddNotification {
  type: INotificationActionTypes.ADD_NOTIFICATION;
  payload: { notification: Notification };
}

interface ILoadNotificationAction {
  type: INotificationActionTypes.LOAD_NOTIFICATIONS_SUCCESS;
  payload: { notifications: Notification[] };
}

interface IMarkAllUnreadNotificationsAsRead {
  type: INotificationActionTypes.MARK_ALL_UNREAD_NOTIFICATIONS_AS_READ;
  payload: { notifIds: number[] };
}

function addNotification(notification: Notification) {
  updateFavicon(faviconNotification);

  return success(notification);

  function success(notification: Notification): IAddNotification {
    return { type: INotificationActionTypes.ADD_NOTIFICATION, payload: { notification } };
  }
}

function loadNotifications(page: number, countPerPage: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return fetchNotificationsBE(page, countPerPage)
      .then(res => mapToNotifications(res.data))
      .then(notifications => {
        // On first load if there are som unread notifications set notif favicon and put question mark before title
        if (page === 1 && notifications.findIndex(n => n.read === false) > -1) {
          updateFavicon(faviconNotification);
        }
        dispatch(success(notifications));
      })
      .catch(err => {
        throw err;
      });
  };

  function success(notifications: Notification[]): ILoadNotificationAction {
    return {
      type: INotificationActionTypes.LOAD_NOTIFICATIONS_SUCCESS,
      payload: { notifications }
    };
  }
}

/**
 * Marks all notifications that are unread to read
 */
function markAllUnreadNotificationsAsRead(): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const notifIds: number[] = [];

    getState()
      .notifcation.notifications.filter(i => i.read === false)
      .forEach(notif => notifIds.push(notif.id));

    if (notifIds.length > 0) {
      MarkMultipleNotificationsAsReadBE(notifIds)
        .then(() => {
          updateFavicon(favicon);
          dispatch(success(notifIds));
        })
        .catch(err => {
          throw err;
        });
    }
  };

  function success(notifIds: number[]): IMarkAllUnreadNotificationsAsRead {
    return {
      type: INotificationActionTypes.MARK_ALL_UNREAD_NOTIFICATIONS_AS_READ,
      payload: { notifIds }
    };
  }
}

export { addNotification, loadNotifications, markAllUnreadNotificationsAsRead };

export type NotificationAction = IAddNotification | ILoadNotificationAction | IMarkAllUnreadNotificationsAsRead;
