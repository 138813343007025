import { TenderTemplate } from '_types/tenderTypes';
import { ITenderTemplatesAction, IActionTypes } from '_actions/tenderTemplatesActions';

export interface IState extends Array<TenderTemplate> {}

export const initialState: IState = [];

export function reducer(state: IState = initialState, action: ITenderTemplatesAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_TEMPLATES: {
      return action.payload.templates;
    }
    default: {
      return state;
    }
  }
}
