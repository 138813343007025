import Maintenance from 'core/Maintenance';
import 'jquery';
import 'jquery-validation';
import 'materialize-css';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { syncHistoryWithStore } from 'react-router-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import 'signalr';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import commonActions from '_actions/commonActions';
import { authenticateUser } from '_actions/userActions';
import { userApi } from '_api/userApi';
import GoogleAnalytics from '_components/routing/GoogleAnalytics';
import ScrollToTop from '_components/routing/ScrollToTop';
import { routes } from '_constants/routesConstants';
import axiosSettings from '_libs/axiosSettings';
import LocalizationProvider from '_libs/contexts/LocalizationContext';
import MediaProvider from '_libs/contexts/MediaContext';
import '_libs/dateExtensionMethods';
import history from '_libs/history';
import LuckyOrange from '_libs/LuckyOrange';
import setDefaultAuthorizationHeader from '_libs/setDefaultAuthorizationHeader';
import { getTokenPersistance } from '_libs/tokenStorageManager/tokenStorageManager';
import TokenStorageManagerFactory from '_libs/tokenStorageManager/tokenStorageManagerFactory';
import { initStoreObservers } from '_observers/initObservers';
import configureStore from '_store/configureStore';
import '_styles/main.scss';
import { Persistance } from '_types/commonTypes';
import { validator } from '_validation/validator';

const App = lazy(() => import(/* webpackChunkName: "App" */ 'core/App'));
const AdminApp = lazy(() => import(/* webpackChunkName: "AdminApp" */ 'admin/AppProxy'));

export const store = configureStore();
syncHistoryWithStore(history, store);
initStoreObservers(store);

validator.init();
axiosSettings.setupInterceptors(store.dispatch);

// Token persistance represents place where jwt token is stored, can be either local, session or none. If there is no jwt token stored we do not need to authenticate
// App needs to be first time rendered after authentication because Private route would redirect user to login before authentication is done
const tokenPersistance = getTokenPersistance();

store
  .dispatch(commonActions.loadLocalization())
  .then(async () => {
    if (tokenPersistance !== Persistance.NONE && history.location.pathname !== routes.ACTIVATION) {
      const tokenStorageManager = new TokenStorageManagerFactory().create(tokenPersistance);
      const refreshToken = tokenStorageManager.getRefreshToken();

      if (refreshToken) {
        try {
          const data = await userApi.refreshToken(refreshToken);
          const { refresh_token, access_token } = data;
          tokenStorageManager.setRefreshToken(refresh_token);
          tokenStorageManager.setJwtToken(access_token);

          setDefaultAuthorizationHeader(access_token);
          store.dispatch(authenticateUser(access_token)).then((authenticated: boolean) => {
            if (!authenticated) {
              // token is invalid, clear token from cache
              tokenStorageManager.removeTokens();
            }

            renderApp();
          });
        } catch (err) {
          tokenStorageManager.removeTokens();
          renderApp();
        }
      } else {
        renderApp();
      }
    } else {
      renderApp();
    }
  })
  .catch(() => {
    renderMaintenance();
  });

function renderApp() {
  ReactDOM.render(
    <Provider store={store}>
      <LocalizationProvider>
        <MediaProvider>
          <Router history={history}>
            <GoogleAnalytics>
              <ScrollToTop>
                <Suspense fallback={<div />}>
                  <LuckyOrange />
                  <ToastContainer toastClassName="alert-toast" />
                  <ReactTooltip className="custom-tooltip" delayShow={0} delayHide={0} place="bottom" />
                  <ReactTooltip
                    id="image-popup"
                    place="bottom"
                    className="image-tooltip"
                    delayShow={200}
                    getContent={dataTip => <img src={dataTip} alt="Image" />}
                  />
                  <Switch>
                    <Route path={routes.ADMIN} component={AdminApp} />
                    <Route path="/" component={App} />
                  </Switch>
                </Suspense>
              </ScrollToTop>
            </GoogleAnalytics>
          </Router>
        </MediaProvider>
      </LocalizationProvider>
    </Provider>,
    document.getElementById('app')
  );
}

export function renderMaintenance() {
  ReactDOM.render(<Maintenance />, document.getElementById('app'));
}
