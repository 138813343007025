import { ThunkAction } from 'redux-thunk';

import { User } from '_types/commonTypes';
import { fetchUsersByCompanyIds } from '_api/userApi';
import { IApplicationState } from 'core/reducer';
import { mapToUser } from '_mappers/userMapper';

export enum IUsersActionTypes {
  ADD_USERS = '[ADD_USERS] SUCCESS'
}

interface IAddUsersSuccessAction {
  type: IUsersActionTypes.ADD_USERS;
  payload: { users: User[] };
}

function getUsersByCompanyIds(
  companyIds: number[],
  pageNumber?: number,
  recordsPerPage?: number
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    // TODO Kvoli bugu kde mozu byt v cashi duplicitne data, po odstraneni toho bugu odstranit
    const usersFound = getState().users.filter(
      (t, index, self) =>
        self.findIndex(s => s.company.id === t.company.id && companyIds.indexOf(t.company.id) > -1) === index
    );

    // const usersFound = getState().users.filter(t => companyIds.indexOf(t.company.id) > -1);
    const usersFoundLength = usersFound.length;
    if (companyIds.length !== usersFoundLength) {
      let usersToFetch = companyIds;

      if (usersFoundLength > 0) {
        usersToFetch = companyIds.filter(i => usersFound.findIndex(x => x.company.id === i) < 0);
      }

      return fetchUsersByCompanyIds(usersToFetch, pageNumber, recordsPerPage)
        .then(res => {
          const countries = getState().codeList.countries;
          const industries = getState().codeList.industries;

          const users = res.data.map(dto => mapToUser(dto, countries, industries));

          dispatch(addUsersSuccessAction(users));
          return users;
        })
        .catch(err => {
          console.log(err);
          return [];
        });
    } else {
      return Promise.resolve(usersFound);
    }
  };

  function addUsersSuccessAction(users: User[]): IAddUsersSuccessAction {
    return {
      type: IUsersActionTypes.ADD_USERS,
      payload: { users }
    };
  }
}

export type UsersAction = IAddUsersSuccessAction;

export { getUsersByCompanyIds };
