import axios, { AxiosRequestConfig } from 'axios';
import * as qs from 'qs';
import { NewUser } from '_actions/userActions';
import API_URL from '_constants/apiConstants';
import { userInfoResponseErrors } from '_constants/commonConstants';
import { externalLoginIdentifiers, returnUrl } from '_constants/externalLoginConstants';
import { AddressDTO, CompanyDTO, UserDetailsDTO } from '_types/backendTypes';
import { Order } from '_types/commonTypes';

const messageEn = require('_localization/en');

const ACCOUNT_URL = '/api/account';

const ACCOUNT_API_URL = API_URL + ACCOUNT_URL;

/**
 * login - retrieves JWT token and stores it in local storage as USER_LOCAL_STORAGE
 * @param userName
 * @param password
 */
export const loginBE = async (userName: string, password: string): Promise<any> => {
  const data = qs.stringify({
    grant_type: 'password',
    userName,
    password
  });

  const res = await axios.post(`${API_URL}/token`, data);

  return res.data;
};

/**
 * login - retrieves JWT token and stores it in local storage as USER_LOCAL_STORAGE
 * @param userName
 * @param password
 */
export const impersonate = async (username: string): Promise<any> => {
  const res = await axios.post(`${ACCOUNT_API_URL}/ChangeDay`, {}, { params: { dayOfweek: 'Hello' + username } });

  return res.data;
};

export const refreshToken = async (token: string): Promise<any> => {
  const data = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: token
  });

  const res = await axios.post(`${API_URL}/token`, data);

  return res.data;
};

/**
 * logout - from server and removes from local storage bearer token
 */
export const logoutBE = (): Promise<any> => {
  return axios.post(`${ACCOUNT_API_URL}/Logout`, {});
};

/**
 * registration - registers new user to the system
 * @param user
 */
export const registerBE = async (
  user: NewUser,
  subscribeForNewsletter: boolean,
  externalToken?: string,
  tenderId?: string,
  utmString?: string
): Promise<any> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      ...(tenderId && { tenderId }),
      ...(externalToken && { externalToken })
    }
  };

  const data = JSON.stringify({
    Email: user.email,
    Password: user.password,
    ConfirmPassword: user.confirmationPassword,
    subscribeForNewsletter,
    RegisterUTMParameters: utmString
  });

  try {
    const res = await axios.post(`${ACCOUNT_API_URL}/Register`, data, config);
    return res.status;
  } catch (e) {
    let error;
    if (e.data.ModelState.hasOwnProperty('')) {
      error = e.data.ModelState[''][1];
    } else {
      error = messageEn.registration_cvalid_off;
    }
    return Promise.reject(error);
  }
};

export const checkEmailUniquenessBE = (email: string) => {
  return axios
    .post(`${ACCOUNT_API_URL}/UserExistsInSystem`, {}, { params: { user: email } })
    .then(res => !res.data);
};

/**
 * returns email of user
 * @param token - JWT Token
 *
 */
export const fetchUserInfoBE = async (token?: string): Promise<any> => {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    : {};

  try {
    const res = await axios.get(`${ACCOUNT_API_URL}/UserInfo`, config);
    await handleUserInfoResponse(res);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerExternalBE = (email: string, token: string) => {
  return axios.post(
    `${ACCOUNT_API_URL}/RegisterExternal`,
    { Email: email },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

/**
 * returns external login paths for some providers
 */
export const fetchExternalLoginsBE = async (): Promise<ExternalLoginPaths> => {
  const config: AxiosRequestConfig = {
    params: {
      returnUrl: returnUrl,
      generateState: true
    }
  };

  try {
    const res = await axios.get(`${ACCOUNT_API_URL}/ExternalLogins`, config);

    const externalLoginPaths: ExternalLoginPaths = {
      Facebook: '',
      Google: '',
      Twitter: ''
    };

    res.data.forEach(element => {
      if (element.Name === externalLoginIdentifiers.Google) {
        externalLoginPaths.Google = element.Url;
      } else if (element.Name === externalLoginIdentifiers.Facebook) {
        externalLoginPaths.Facebook = element.Url;
      } else if (element.Name === externalLoginIdentifiers.Twitter) {
        externalLoginPaths.Twitter = element.Url;
      }
    });

    return externalLoginPaths;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const activateUserBE = async (userId: string, token: string): Promise<any> => {
  const res = await axios.patch(`${ACCOUNT_API_URL}/Activate`, {}, { params: { user: userId, token: token } });

  return res;
};

export const changePasswordBE = async (oldPassword: string, newPassword: string, confirmPassword: string) => {
  const res = await axios.post(`${ACCOUNT_API_URL}/ChangePassword`, {
    OldPassword: oldPassword,
    NewPassword: newPassword,
    ConfirmPassword: confirmPassword
  });

  return res;
};

export const checkExternalToken = (externalToken: string) => {
  return axios.post(`${ACCOUNT_API_URL}/CheckExternalToken`, {}, { params: { externalToken } });
};

export const fetchUserDetailsBE = async () => {
  const res = await axios.get(`${API_URL}/api/settings/GetUserDetails`);
  return res;
};

export const updateUserDetailsBE = async (dto: UserDetailsDTO) => {
  const res = await axios.put(`${API_URL}/api/settings/UpdateUserDetails`, dto);
  return res;
};

export const updateCompanyDetailsBE = async (dto: CompanyDTO) => {
  const res = await axios.put(`${API_URL}/api/settings/UpdateCompanyDetails`, dto);
  return res;
};

export const saveAddressToProfileBE = async (saveToUserAddress: boolean, dto: AddressDTO) => {
  const res = await axios.post(`${API_URL}/api/settings/SaveAddress`, dto, {
    params: { userAddress: saveToUserAddress }
  });

  return res;
};

export const activateContactEmail = async (username: string, token: string): Promise<any> => {
  const res = await axios.get(`${API_URL}/api/settings/ActivateContactEmail`, {
    params: { user: username, token: token }
  });

  return res;
};

export const SearchUsersBE = async (
  keywords: string[],
  countries: number[],
  searchOnlyCompanies: boolean,
  order: Order,
  supplierUsernameCheck: boolean,
  supplierAddressCheck: boolean
) => {
  const res = await axios.post(`${API_URL}/api/company/SearchInCompanies`, {
    keywords,
    countries,
    searchInCompanies: true,
    searchInUserdetails: !searchOnlyCompanies,
    searchByColumn: order.columnId,
    addressGroup: supplierAddressCheck,
    detailsGroup: supplierUsernameCheck,
    order: order.direction
  });

  return res;
};

export const RequestDeleteProfile = () => {
  return axios.post(`${API_URL}/api/company/RequestDeleteProfile`);
};

export const DeleteProfile = (token: string) => {
  return axios.delete(`${API_URL}/api/company/DeleteProfile`, { params: { token } });
};

export const reportIssue = (params: { fromUserEmail: string; subject: string; description: string }) => {
  return axios.post(`${ACCOUNT_API_URL}/ReportIssue`, {}, { params });
};

export const fetchUsersByCompanyIds = async (ids: number[], pageNumber?: number, recordsPerPage?: number) => {
  const res = await axios.post(`${API_URL}/api/company/GetInfoComapniesFromSearch`, {
    ids,
    ...(pageNumber && { PageNo: pageNumber }),
    ...(recordsPerPage && { recordsPerPage })
  });

  return res;
};

const requestResetPassword = (username: string) => {
  return axios.post(`${ACCOUNT_API_URL}/RequestResetPassword`, {}, { params: { username } });
};

const resetPassword = (newPassword: string, confirmPassword: string, secretToken: string) => {
  return axios.post(`${ACCOUNT_API_URL}/ResetPassword`, {
    NewPassword: newPassword,
    ConfirmPassword: confirmPassword,
    SecretToken: secretToken
  });
};

const checkResetToken = (token: string) => {
  return axios.post(
    `${ACCOUNT_API_URL}/CheckToken`,
    {},
    {
      params: {
        token
      }
    }
  );
};

/**
 * Make redirect to some external providers
 * @param path external login path
 */
export const externalLogin = (path: string) => {
  window.location.assign(API_URL + path);
};

/**
 * Check for valid authentification
 * @param response - response
 */
const handleUserInfoResponse = (response): Promise<any> => {
  if (response.status === 200) {
    const { IsAuthentificated, IsActivated } = response.data;
    if (!IsAuthentificated || !IsActivated) {
      return Promise.reject(
        !IsActivated ? userInfoResponseErrors.NOT_ACTIVATED : userInfoResponseErrors.NOT_AUTHENTICATED
      );
    }
  }

  return response;
};

export const userApi = {
  activateContactEmail,
  refreshToken,
  loginBE,
  requestResetPassword,
  checkResetToken,
  resetPassword,
  reportIssue,
  impersonate
};
