import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { IApplicationState } from 'core/reducer';

export const LocalizationContext = createContext<any>([]);

const LocalizationProvider = ({ children }) => {
  const localization = useSelector((state: IApplicationState) => state.common.localization);

  return (
    <LocalizationContext.Provider value={localization}>{children}</LocalizationContext.Provider>
  );
};

export default LocalizationProvider;

export const useLocalization = () => useContext(LocalizationContext);
