/**
 * Regex allows only numbers
 */
export const onlyNumbersRegex: RegExp = new RegExp(/[^0-9\.?]/g);

/**
 * Regex allows only numbers and white space
 */
export const onlyNumbersRegexWithTowDecimalPrecision: RegExp = new RegExp(/^$|^(\d+\.?\d{0,2}$)/);

/**
 * Regex for hh:mm format
 */
export const timeFormat24hRegex: RegExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);

/**
 * Regex for hh:mm mask input
 */
export const time24hMaskRegex: RegExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]|0h|1h|2h|hh):([0-5][0-9]|[0-5]m|mm)$/);

export const dateRegex: RegExp = new RegExp(
  /^[0-9]{4}-(0[1-9]|1[0-2])-(0[0-9]|1[0-9]|2[0-9]|3[0-1]) (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
);

/**
 * Password regex for one small letter, capital letter and number
 */
export const passwordRegex: RegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d'{}[\]|\\;':",./`<>?^!@#$%&*()_+=~-]{0,}$/;

export const webUrlRegex: RegExp =
  /((http|https)\:\/\/)?[a-zA-Z0-9À-ž\.\/\?\:@\-_=#]+\.([a-zA-Z0-9À-ž\&\.\/\?\:@\-_=#])*$/;

export const englishLetterRegex = /^$|^[a-zA-Z0-9$@$!%*?&#$()\[\]\/\\\\{§}=\-'"<;:>,^-_. +]+$/;

export const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
