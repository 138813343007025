import { Store } from 'redux';

export abstract class Hub {
  protected proxy: SignalR.Hub.Proxy;
  protected store: Store;

  constructor(connection: SignalR.Hub.Connection, store: Store, hubName: string) {
    this.proxy = connection.createHubProxy(hubName);
    this.store = store;
  }

  abstract registerMethods(): void;
}
