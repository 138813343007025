export enum IActionTypes {
  SUCCESS = '[alert] SUCCESS',
  ERROR = '[alert] ERROR',
  CLEAR = '[alert] CLEAR'
}

export interface ISuccessAction {
  type: IActionTypes.SUCCESS;
  payload: { message: string };
}
export interface IErrorAction {
  type: IActionTypes.ERROR;
  payload: { message: string };
}
export interface IClearAction {
  type: IActionTypes.CLEAR;
}

function success(message: string): ISuccessAction {
  return { type: IActionTypes.SUCCESS, payload: { message } };
}

function error(message: string): IErrorAction {
  return { type: IActionTypes.ERROR, payload: { message } };
}

function clear(): IClearAction {
  return { type: IActionTypes.CLEAR };
}

export { error, clear, success };

export type Action = IErrorAction | IClearAction | ISuccessAction;
