import {
  Currency,
  CodeList,
  Industry,
  Country,
  AddressCodeList,
  Address,
  AddressSource
} from '_types/commonTypes';
import { ThunkAction } from 'redux-thunk';

import * as codeListService from '_api/codeListApi';
import { mapAddresses } from '_mappers/codeListMapper';
import { IApplicationState } from 'core/reducer';

export enum IActionTypes {
  LOAD_CURRENCIES_SUCCESS = '[LOAD_CURRENCIES] SUCCESS',
  LOAD_UNITS_OF_MEASUREMENT_SUCCESS = '[LOAD_UNITS_OF_MEASUREMENT] SUCCESS',
  LOAD_UNITS_OF_TENDER_CRITERION_SUCCESS = '[LOAD_UNITS_OF_TENDER_CRITERION] SUCCESS',
  LOAD_INDUSTRIES_SUCCESS = '[LOAD_INDUSTRIES_SUCCESS] SUCCESS',
  LOAD_CANADA_PROVINCES_SUCCESS = '[LOAD_PROVINCES_SUCCESS] SUCCESS',
  LOAD_USA_STATES_SUCCESS = '[LOAD_USA_STATES_SUCCESS] SUCCESS',
  LOAD_COUNTRIES_SUCCESS = '[LOAD_COUNTRIES_SUCCESS] SUCCESS',
  LOAD_TENDER_STATUSES_SUCCESS = '[LOAD_TENDER_STATUSES] SUCCESS',
  LOAD_ADDRESSES_CODELIST_SUCCESS = '[LOAD_ADDRESSES_CODELIST] SUCCESS',
  LOAD_SUPPLIERS_INVITATION_STATUSES_SUCCESS = '[LOAD_SUPPLIERS_INVITATION_STATUSES] SUCCESS',
  ADD_NEW_ADDRESS_SUCCESS = '[ADD_NEW_ADDRESS_ADDRESS] SUCCESS',
  UPDATE_ADDRESS_CODELIST_SUCCESS = '[UPDATE_ADDRESS_CODELIST] SUCCESS',
  REMOVE_INSERT_NEW_ADDRESS_SUCCESS = '[REMOVE_INSERT_NEW_ADDRESS] SUCCESS',
  RESET_ADDRESSES_SUCCES = '[RESET_ADDRESSES] SUCCES',
  TENDER_CODELISTS_LOADED_SUCCESS = '[TENDER_CODELISTS_LOADED] SUCCESS'
}

interface ILoadCurrenciesSuccessAction {
  type: IActionTypes.LOAD_CURRENCIES_SUCCESS;
  payload: { currencies: Currency[] };
}

interface ILoadUnitsOfMeasurementSuccessAction {
  type: IActionTypes.LOAD_UNITS_OF_MEASUREMENT_SUCCESS;
  payload: { unitsOfMeasurement: Array<CodeList<string>> };
}
interface ILoadUnitsOfTenderCriterionSuccessAction {
  type: IActionTypes.LOAD_UNITS_OF_TENDER_CRITERION_SUCCESS;
  payload: { unitsOfTenderCriterion: Array<CodeList<string>> };
}
interface ILoadCountriesSuccessAction {
  type: IActionTypes.LOAD_COUNTRIES_SUCCESS;
  payload: { countries: Array<Country> };
}
interface ILoadTenderStatusesSuccessAction {
  type: IActionTypes.LOAD_TENDER_STATUSES_SUCCESS;
  payload: { statuses: Array<CodeList<number>> };
}
interface ILoadIndustriesSuccessAction {
  type: IActionTypes.LOAD_INDUSTRIES_SUCCESS;
  payload: { industries: Industry[] };
}
interface ILoadCanadaProvincesSuccessAction {
  type: IActionTypes.LOAD_CANADA_PROVINCES_SUCCESS;
  payload: { provinces: Array<CodeList<number>> };
}
interface ILoadUSAStatesSuccessAction {
  type: IActionTypes.LOAD_USA_STATES_SUCCESS;
  payload: { states: Array<CodeList<number>> };
}
interface ILoadCodelistAddressesSuccessAction {
  type: IActionTypes.LOAD_ADDRESSES_CODELIST_SUCCESS;
  payload: { addresses: AddressCodeList[] };
}
interface ILoadSuppliersInvitationStatusesSuccess {
  type: IActionTypes.LOAD_SUPPLIERS_INVITATION_STATUSES_SUCCESS;
  payload: { statuses: Array<CodeList<number>> };
}

interface IAddNewAddressSuccess {
  type: IActionTypes.ADD_NEW_ADDRESS_SUCCESS;
  payload: { address: AddressCodeList };
}
interface IUpdateAddressCodelistSuccess {
  type: IActionTypes.UPDATE_ADDRESS_CODELIST_SUCCESS;
  payload: { index: number; address: Address };
}
interface IResetAddressesSuccess {
  type: IActionTypes.RESET_ADDRESSES_SUCCES;
}
interface ITenderCodelistsLoadedSuccess {
  type: IActionTypes.TENDER_CODELISTS_LOADED_SUCCESS;
}

function loadCurrenciesCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchCurrencies()
      .then(currencies => {
        dispatch(success(currencies));
        return currencies;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(currencies: Currency[]): ILoadCurrenciesSuccessAction {
    return { type: IActionTypes.LOAD_CURRENCIES_SUCCESS, payload: { currencies } };
  }
}

function loadUnitsOfMeasurementCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchUnitsOfMeasurement()
      .then(unitsOfMeasurement => {
        dispatch(success(unitsOfMeasurement));
        return unitsOfMeasurement;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(unitsOfMeasurement: Array<CodeList<string>>): ILoadUnitsOfMeasurementSuccessAction {
    return {
      type: IActionTypes.LOAD_UNITS_OF_MEASUREMENT_SUCCESS,
      payload: { unitsOfMeasurement }
    };
  }
}

function loadUnitsOfTenderCriterionCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchUnitsOfTenderCriterion()
      .then(unitsOfTenderCriterion => {
        dispatch(success(unitsOfTenderCriterion));
        return unitsOfTenderCriterion;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(unitsOfTenderCriterion: Array<CodeList<string>>): ILoadUnitsOfTenderCriterionSuccessAction {
    return {
      type: IActionTypes.LOAD_UNITS_OF_TENDER_CRITERION_SUCCESS,
      payload: { unitsOfTenderCriterion }
    };
  }
}

function loadIndustriesCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchIndustries()
      .then(industries => {
        dispatch(success(industries));
        return industries;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(industries: Industry[]): ILoadIndustriesSuccessAction {
    return {
      type: IActionTypes.LOAD_INDUSTRIES_SUCCESS,
      payload: { industries }
    };
  }
}

function loadCanadaProvinces(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchCanadaProvinces()
      .then(provinces => {
        dispatch(success(provinces));
        return provinces;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(provinces: Array<CodeList<number>>): ILoadCanadaProvincesSuccessAction {
    return {
      type: IActionTypes.LOAD_CANADA_PROVINCES_SUCCESS,
      payload: { provinces }
    };
  }
}

function loadUSAStates(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchUSAStates()
      .then(provinces => {
        dispatch(success(provinces));
        return provinces;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(states: Array<CodeList<number>>): ILoadUSAStatesSuccessAction {
    return {
      type: IActionTypes.LOAD_USA_STATES_SUCCESS,
      payload: { states }
    };
  }
}

function loadCountriesCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchCountries()
      .then(countries => {
        dispatch(success(countries));
        return countries;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(countries: Array<Country>): ILoadCountriesSuccessAction {
    return {
      type: IActionTypes.LOAD_COUNTRIES_SUCCESS,
      payload: { countries }
    };
  }
}
function loadTenderStatusesCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchTenderStatuses()
      .then(statuses => {
        dispatch(success(statuses));
        return statuses;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(statuses: Array<CodeList<number>>): ILoadTenderStatusesSuccessAction {
    return {
      type: IActionTypes.LOAD_TENDER_STATUSES_SUCCESS,
      payload: { statuses }
    };
  }
}

function loadAddressesCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return codeListService
      .fetchAddressesBE()
      .then(res => {
        const countries = getState().codeList.countries;
        const addresses = mapAddresses(res.data, countries);
        dispatch(success(addresses));
        return addresses;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(addresses: AddressCodeList[]): ILoadCodelistAddressesSuccessAction {
    return {
      type: IActionTypes.LOAD_ADDRESSES_CODELIST_SUCCESS,
      payload: { addresses }
    };
  }
}

function loadSuppiersInvitationStatusCodeList(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return codeListService
      .fetchSuppliersInvitationStatuses()
      .then(statuses => {
        dispatch(success(statuses));
        return statuses;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(statuses: Array<CodeList<number>>): ILoadSuppliersInvitationStatusesSuccess {
    return {
      type: IActionTypes.LOAD_SUPPLIERS_INVITATION_STATUSES_SUCCESS,
      payload: { statuses }
    };
  }
}

// New address needs to be added/updated to codelist, because this address
// doesn't have to be present in addresses codelist, because of there are only
// 3 last delivery addreses
function upsertDeliveryAddressCodelist(
  tenderAddressSource: number,
  tenderAddress: Address
): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const codelistAddresses = getState().codeList.addresses;
    const address = codelistAddresses.find(
      a =>
        a.addressSource === tenderAddressSource &&
        a.address.country.id === tenderAddress.country.id &&
        a.address.zipCode === tenderAddress.zipCode &&
        a.address.streetAddress === tenderAddress.streetAddress
    );

    if (!address && tenderAddress.id !== 0) {
      const addressId = codelistAddresses.findIndex(
        a => a.addressSource === AddressSource.DELIVERY && a.address.id !== 0
      );

      if (addressId > -1) {
        dispatch(updateAddressCodeList(addressId, tenderAddress));
      } else {
        dispatch(
          addNewAddress({
            addressSource: 3,
            address: tenderAddress
          })
        );
      }
    }
  };
}

// Updates/inserts address by address source, mainly for company / home address usage
function upsertAddressCodelist(addressSource: number, address: Address): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const index = getState().codeList.addresses.findIndex(a => a.addressSource === addressSource);
    if (index > -1) {
      dispatch(updateAddressCodeList(index, address));
    } else {
      dispatch(addNewAddress({ addressSource, address }));
    }
  };
}

function updateAddressCodeList(index: number, address: Address): IUpdateAddressCodelistSuccess {
  return {
    type: IActionTypes.UPDATE_ADDRESS_CODELIST_SUCCESS,
    payload: { index, address }
  };
}

function addNewAddress(address: AddressCodeList): IAddNewAddressSuccess {
  return {
    type: IActionTypes.ADD_NEW_ADDRESS_SUCCESS,
    payload: { address }
  };
}
function resetAddressesCodeList(): IResetAddressesSuccess {
  return { type: IActionTypes.RESET_ADDRESSES_SUCCES };
}
function tenderCodelistsLoaded(): ITenderCodelistsLoadedSuccess {
  return { type: IActionTypes.TENDER_CODELISTS_LOADED_SUCCESS };
}

export type CodeListAction =
  | ILoadCurrenciesSuccessAction
  | ILoadUnitsOfMeasurementSuccessAction
  | ILoadUnitsOfTenderCriterionSuccessAction
  | ILoadIndustriesSuccessAction
  | ILoadCountriesSuccessAction
  | ILoadCodelistAddressesSuccessAction
  | ILoadSuppliersInvitationStatusesSuccess
  | IAddNewAddressSuccess
  | IResetAddressesSuccess
  | ITenderCodelistsLoadedSuccess
  | IUpdateAddressCodelistSuccess
  | ILoadTenderStatusesSuccessAction
  | ILoadCanadaProvincesSuccessAction
  | ILoadUSAStatesSuccessAction;

export {
  loadCurrenciesCodeList,
  loadUnitsOfMeasurementCodeList,
  loadUnitsOfTenderCriterionCodeList,
  loadIndustriesCodeList,
  loadCountriesCodeList,
  loadAddressesCodeList,
  loadSuppiersInvitationStatusCodeList,
  loadTenderStatusesCodeList,
  resetAddressesCodeList,
  tenderCodelistsLoaded,
  upsertDeliveryAddressCodelist,
  addNewAddress,
  updateAddressCodeList,
  upsertAddressCodelist,
  loadCanadaProvinces,
  loadUSAStates
};
