import { Supplier, CodeList, Bid, Attachment, Country, SuppliersTenderStatus } from '_types/commonTypes';
import { initialDeliveryAddressState, initialCompanyState } from '_reducers/partialInitialStates';
import { transformToStarUsername } from '_libs/helpers';
import moment from 'moment';
import { SuppliersEntity } from '_types/backendTypes';

export const mapToSupplierFromTenderSupplierDTO = (
  data: SuppliersEntity,
  supplierInvitationStatusCodeList: Array<CodeList<number>>,
  countriesCodeList: Country[]
): Supplier => {
  const itemsBids: Array<Bid> = [];
  if (typeof data.ItemsBids !== 'undefined' && data.ItemsBids !== null && data.ItemsBids.length > 0) {
    data.ItemsBids.forEach(itemBid => itemsBids.push({ id: itemBid.Id, value: itemBid.Bid.toString() }));
  }

  const criteriaBids: Array<Bid> = [];
  if (typeof data.CriteriaBids !== 'undefined' && data.CriteriaBids !== null && data.CriteriaBids.length > 0) {
    data.CriteriaBids.forEach(criterionBid =>
      criteriaBids.push({
        id: criterionBid.Id,
        value: criterionBid.Bid.toString()
      })
    );
  }

  const attachments: Attachment[] = [];
  if (typeof data.Attachments !== 'undefined' && data.Attachments !== null && data.Attachments.length > 0) {
    data.Attachments.forEach(attachment => {
      attachments.push({
        id: attachment.AttachmentId,
        name: attachment.FileName
      });
    });
  }

  const country = countriesCodeList.find(c => c.id === data.CountryId);

  return {
    overallPosition: data.OveralPosition ? data.OveralPosition : 9999,
    status: {
      id: data.SupplierStatusId,
      name: supplierInvitationStatusCodeList.find(x => x.id === data.SupplierStatusId)!.name
    },
    isPublic: data.IsPublic,
    rejectedReason: data.RejectedReason || 'You are a scammer! (Reason from frontend)',
    user: {
      country: country ? country.name : '',
      email: data.Useremail,
      rating: data.Rating,
      userName: data.Username,
      coveredUsername: transformToStarUsername(data.Username),
      firstName: data.FirstName,
      lastName: data.LastName,
      accountType: '',
      address: initialDeliveryAddressState,
      company: { ...initialCompanyState, id: data.CompanyId, name: data.CompanyName, vatId: data.VatId },
      defaultCurrency: '',
      facebookProfile: '',
      favouriteAuctionKeywords: '',
      linkedinProfile: '',
      phoneNumber: '',
      isSearcheable: true,
      isEmailConfirmed: true,
      wantToBuy: true,
      wantToSell: true
    },
    offerDescription: data.OfferDescription || '',
    attachments: { isUploading: false, list: attachments },
    criteriaBids: criteriaBids,
    itemsBids: itemsBids,
    mustHaveConditionResponses: data.MhcResponses
      ? data.MhcResponses.map(d => ({
          mhcId: d.MustHaveConditionId,
          response: d.NewResponse
        }))
      : [],
    lastActivityDate: moment(data.LastActive).toISOString(true),
    canReinvite: data.SupplierStatusId === SuppliersTenderStatus.INVITATION_SENT
  };
};
