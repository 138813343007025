import React from 'react';
import Action from '_components/actions/Action';
import FilledInCheckbox from '_components/inputs/checkboxes/FilledInCheckbox';
import TableCellInput from '_components/inputs/TableCellInput';
import { useLocalization } from '_libs/contexts/LocalizationContext';
import { useTooltip } from '_libs/hooks/useTooltip';
import { MustHaveCondition } from '_types/tenderTypes';

type Props = {
  index: number;
  condition: MustHaveCondition;
  isEditable: boolean;
  isFocused: boolean;
  isMandatory: boolean;
  onConditionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveCondition: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onConfirmCondition: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onEnterConfirmCondition: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocusCondition: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const MustHaveConditionsListRow: React.FC<Props> = ({
  index,
  condition,
  isMandatory,
  onConditionChange,
  onRemoveCondition,
  onBlur,
  onConfirmCondition,
  onKeyDown,
  onEnterConfirmCondition,
  onFocusCondition,
  isFocused,
  isEditable
}) => {
  const localization = useLocalization();
  useTooltip([isMandatory]);

  return (
    <li className="collection-item collection-item--fluid">
      <div style={{ flex: 1, marginRight: '20px' }}>
        <TableCellInput
          {...(isMandatory && { 'data-tip': 'Mandatory condition that can not be deleted' })}
          id={'condition' + index}
          name="mustHaveCondition"
          placeholder="Text for MUST HAVE condition e.g. ISO 9001"
          onChange={onConditionChange}
          value={condition.text}
          type="text"
          onMouseEnter={onEnterConfirmCondition}
          onFocus={onFocusCondition}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          inputStyle={{ flex: 1, marginRight: '20px' }}
          readOnly={isMandatory}
          disabled={!isEditable}
        />
        {index !== 0 && (
          <FilledInCheckbox
            className="small"
            wrapperStyle={{ marginTop: '8px' }}
            name="supplierAddressCheck"
            label="Bidder must insert the value or text for this condition to enter the Auction"
            checkedValue={condition.hasAdditionalInfo}
            onChange={onConditionChange}
          />
        )}
      </div>
      <div className="actions">
        {isFocused && !isMandatory && (
          <Action onClick={onConfirmCondition} tooltip="Confirm condition" icon="done" />
        )}
        {!isMandatory && (
          <Action
            onClick={onRemoveCondition}
            dataTip={localization.remove_tooltip}
            last={true}
            disabled={!isEditable}
            icon="delete"
          />
        )}
      </div>
    </li>
  );
};
export default MustHaveConditionsListRow;
