import moment from 'moment';

import {
  Tender,
  TenderTemplate,
  TenderLightWeight,
  SuppliersItemBid,
  SuppliersCriterionBid,
  TenderBoardShowings,
  Message,
  MustHaveCondition
} from '_types/tenderTypes';
import {
  ItemDetails,
  CriterionDetails,
  Attachment,
  Supplier,
  CodeList,
  SuppliersPosition,
  Country,
  AddressSource
} from '_types/commonTypes';
import { IState } from '_reducers/codeListReducer';
import { itemsDetailsCalculator } from '_libs/calculator';
import {
  TenderDTO,
  ItemsDetailsEntity,
  CriteriaDetailsEntity,
  TenderLightWeightDTO,
  ItemBidDTO,
  CriterionBidDTO,
  SuppliersPositionDTO,
  SuppliersEntity,
  SuppliersBidDTO,
  AddressDTO,
  MustHaveConditionDTO,
  MhcResponseDTO,
  AttachmentsEntity
} from '_types/backendTypes';
import { initialDeliveryAddressState, initialCountry, initialCompanyState } from '_reducers/partialInitialStates';
import { transformToStarUsername } from '_libs/helpers';
import { mapToSupplierFromTenderSupplierDTO } from './tenderSupplierMapper';
import { getMandatoryMustHaveCondition } from 'newAuction/components/MustHaveConditionsContainer';

/**
 * TODO - Bids step premapovat
 *      - Best bid
 *      - Attachments
 * @param data
 */
export function mapTender(data: TenderDTO, codeLists: IState): Tender {
  const industries: CodeList<string>[] = [];
  if (typeof data.Industries !== 'undefined' && data.Industries !== null && data.Industries.length > 0) {
    data.Industries.forEach(industry => {
      industries.push({
        id: industry,
        name: codeLists.industries.find(i => i.id === industry)!.name
      });
    });
  }

  const itemDetails: ItemDetails[] = [];
  if (typeof data.ItemsDetails !== 'undefined' && data.ItemsDetails !== null && data.ItemsDetails.length > 0) {
    data.ItemsDetails.forEach(item => {
      itemDetails.push({
        id: item.ItemDetailId,
        name: item.Name,
        unitPrice: item.UnitPrice,
        unitOfMeasurement: {
          id: item.UnitOfMeasurement,
          name:
            codeLists.unitsOfMeasurement.find(x => x.id === item.UnitOfMeasurement)?.name || item.UnitOfMeasurement
        },
        quantity: item.Quantity,
        expPrice: item.ExpPrice,
        bidStep: item.BidStep,
        bestBid: item.BestBid,
        description: item.Description ? item.Description : ''
      });
    });
  }
  const criteriaDetails: CriterionDetails[] = [];
  if (
    typeof data.CriteriaDetails !== 'undefined' &&
    data.CriteriaDetails !== null &&
    data.CriteriaDetails.length > 0
  ) {
    data.CriteriaDetails.forEach(criterion => {
      criteriaDetails.push({
        id: criterion.CriterionId,
        name: criterion.Name,
        unit: {
          id: criterion.Unit,
          name: codeLists.unitsOfTenderCriterion.find(crit => crit.id === criterion.Unit)?.name || ''
        },
        winningThreshold: {
          id: criterion.WinningThreshold,
          name: codeLists.winningTreshold.find(thresh => thresh.id === criterion.WinningThreshold)!.name
        },
        weight: {
          id: criterion.Weight,
          name: `${criterion.Weight * 100}%`
        },
        bestBid: criterion.BestBid,
        description: criterion.Description ? criterion.Description : '',
        minBid: criterion.MinBid,
        maxBid: criterion.MaxBid
      });
    });
  }

  const attachments: Attachment[] = [];
  if (typeof data.Attachments !== 'undefined' && data.Attachments !== null && data.Attachments.length > 0) {
    data.Attachments.forEach(attachment => {
      attachments.push({
        id: attachment.AttachmentId,
        name: attachment.FileName
      });
    });
  }

  const suppliers: Supplier[] = [];

  if (typeof data.Suppliers !== 'undefined' && data.Suppliers !== null && data.Suppliers.length > 0) {
    data.Suppliers.forEach(supplier =>
      suppliers.push(
        mapToSupplierFromTenderSupplierDTO(supplier, codeLists.suppliersInvitationStatuses, codeLists.countries)
      )
    );
  }

  const mustHaveConditions: MustHaveCondition[] = [];
  if (
    typeof data.MustHaveConditions !== 'undefined' &&
    data.MustHaveConditions !== null &&
    data.MustHaveConditions.length > 0
  ) {
    data.MustHaveConditions.forEach(condition => {
      mustHaveConditions.push({
        id: condition.Id,
        text: condition.MustHaveCondition,
        hasAdditionalInfo: condition.AdditionalInfo
      });
    });
  }

  const announcements: Message[] = [];
  if (
    typeof data.TenderAnnouncements !== 'undefined' &&
    data.TenderAnnouncements !== null &&
    data.TenderAnnouncements.length > 0
  ) {
    data.TenderAnnouncements.forEach(announcement => {
      announcements.push({ date: moment(announcement.Created).toDate(), text: announcement.Announcement });
    });
  }

  return {
    tenderId: data.TenderId,
    tenderStatus: data.TenderStatus,
    name: data.Name,
    keywords: data.Keywords,
    currency: data.Currency,
    totExpPrice: data.TotalExpectedPrice,
    totExpPriceInUserCurr: data.TotalExpectedPriceForUser,
    industries: industries,
    itemsDetails: itemDetails,
    criteriaDetails: criteriaDetails,
    suppliers: suppliers,
    attachments: { isUploading: false, list: attachments },
    mustHaveConditions: mustHaveConditions,
    addressSource: data.AddressSource,
    newDeliveryAddress: {
      id: data.DeliveryAddress ? data.DeliveryAddress.AddressId : 0,
      city: data.DeliveryAddress ? data.DeliveryAddress.City : '',
      country: data.DeliveryAddress
        ? codeLists.countries.find(c => c.id === data.DeliveryAddress!.CountryId)!
        : initialCountry,
      state: data.DeliveryAddress && data.DeliveryAddress.State ? data.DeliveryAddress.State : '',
      streetAddress: data.DeliveryAddress ? data.DeliveryAddress.Street : '',
      streetAddress2: data.DeliveryAddress ? data.DeliveryAddress.StreetLine2 : '',
      zipCode: data.DeliveryAddress ? data.DeliveryAddress.PostalCode : '',
      gpsCoords: {
        lat: data.DeliveryAddress ? data.DeliveryAddress.GPS_NS : '',
        lng: data.DeliveryAddress ? data.DeliveryAddress.GPS_EW : ''
      }
    },
    mainPictureUrl: data.MainPictureUrl,
    announcements: announcements,
    isPublic: data.IsPublic,
    isFavorite: data.IsFavorite,
    duration: data.Duration,
    proposalEndDate: moment(data.ProposalEndDateTime).toISOString(true),
    startDate: moment(data.StartDateTime).toISOString(true),
    createdDate: moment(data.CreateDateTime).toDate(),
    stepProgress: data.TenderStepProgress,
    canceledReason: data.CanceledReason || 'Auction canceled with reason (Message from frontend).',
    isVatIncluded: typeof data.IsVatIncluded !== 'undefined' ? data.IsVatIncluded : true,
    owner: {
      country: '',
      isEmailConfirmed: true,
      email: data.Owner ? data.Owner.Email : '',
      rating: data.Owner ? data.Owner.Rating : 0,
      firstName: data.Owner ? data.Owner.FirstName : '',
      lastName: data.Owner ? data.Owner.LastName : '',
      userName: data.Owner ? data.Owner.UserName : '',
      coveredUsername: data.Owner && data.Owner.UserName ? transformToStarUsername(data.Owner.UserName) : '',
      accountType: '',
      address: initialDeliveryAddressState,
      company: {
        ...initialCompanyState,
        id: data.Owner ? data.Owner.CompanyId : 0,
        name: data.Owner ? data.Owner.CompanyName : '',
        vatId: data.Owner ? data.Owner.VatId : ''
      },
      defaultCurrency: '',
      facebookProfile: '',
      favouriteAuctionKeywords: '',
      linkedinProfile: '',
      phoneNumber: '',
      isSearcheable: true,
      wantToBuy: true,
      wantToSell: true
    },
    totalActiveBidders: data.TotalActiveBidders || 0
  };
}

/**
 * @param tender
 */
export function mapToTenderDTO(tender: Tender, deliveryAddressChanged: boolean): TenderDTO {
  const itemsDetails: ItemsDetailsEntity[] = [];
  if (tender.itemsDetails.length > 0) {
    tender.itemsDetails.forEach(item => {
      itemsDetails.push({
        ItemDetailId: item.id,
        Name: item.name,
        UnitPrice: item.unitPrice,
        UnitOfMeasurement: item.unitOfMeasurement.id,
        Quantity: item.quantity,
        ExpPrice: item.expPrice,
        BidStep: item.bidStep,
        BestBid: item.bestBid,
        Description: item.description
      });
    });
  }

  const criteriaDetails: CriteriaDetailsEntity[] = [];
  if (tender.criteriaDetails.length > 0) {
    tender.criteriaDetails.forEach(criterion => {
      criteriaDetails.push({
        CriterionId: criterion.id,
        Name: criterion.name,
        Unit: criterion.unit.id,
        WinningThreshold: criterion.winningThreshold.id,
        Weight: criterion.weight.id,
        BestBid: criterion.bestBid,
        Description: criterion.description,
        MaxBid: criterion.maxBid,
        MinBid: criterion.minBid
      });
    });
  }

  const mustHaveConditions: MustHaveConditionDTO[] = [];
  if (tender.mustHaveConditions.length > 0) {
    tender.mustHaveConditions.forEach(condition => {
      mustHaveConditions.push({
        Id: condition.id,
        AdditionalInfo: condition.hasAdditionalInfo,
        MustHaveCondition: condition.text
      });
    });
  }

  const suppliers: SuppliersEntity[] = [];
  if (tender.suppliers.length > 0) {
    tender.suppliers.forEach(supp => {
      const criteriaBids: SuppliersBidDTO[] = [];
      supp.criteriaBids.forEach(critBid => {
        criteriaBids.push({ Id: critBid.id, Bid: Number(critBid.value) });
      });

      const itemsBids: SuppliersBidDTO[] = [];
      supp.itemsBids.forEach(itemBid => {
        criteriaBids.push({ Id: itemBid.id, Bid: Number(itemBid.value) });
      });

      const mhcResponses: MhcResponseDTO[] = [];
      supp.mustHaveConditionResponses.forEach(response => {
        mhcResponses.push({ MustHaveConditionId: response.mhcId, NewResponse: response.response });
      });

      suppliers.push({
        IsPublic: supp.isPublic,
        CompanyId: supp.user.company.id,
        CompanyName: supp.user.company.name,
        VatId: supp.user.company.vatId,
        Username: supp.user.userName,
        FirstName: supp.user.firstName,
        LastName: supp.user.lastName,
        MhcResponses: mhcResponses,
        CriteriaBids: criteriaBids,
        RejectedReason: supp.rejectedReason,
        ItemsBids: itemsBids,
        OveralPosition: supp.overallPosition,
        Rating: 0,
        Attachments: [],
        SupplierStatusId: supp.status.id,
        Useremail: supp.user.email,
        OfferDescription: supp.offerDescription,
        CountryId: supp.user.address.country.id,
        LastActive: moment(supp.lastActivityDate).toISOString(true)
      });
    });
  }

  const deliveryAddress: AddressDTO | null = tender.newDeliveryAddress.country.id
    ? {
        AddressId: deliveryAddressChanged ? 0 : tender.newDeliveryAddress.id,
        CountryId: tender.newDeliveryAddress.country.id,
        City: tender.newDeliveryAddress.city,
        PostalCode: tender.newDeliveryAddress.zipCode,
        State: tender.newDeliveryAddress.state,
        Street: tender.newDeliveryAddress.streetAddress,
        StreetLine2: tender.newDeliveryAddress.streetAddress2,
        GPS_NS: tender.newDeliveryAddress.gpsCoords.lat,
        GPS_EW: tender.newDeliveryAddress.gpsCoords.lng
      }
    : null;

  const attachments: AttachmentsEntity[] | null = tender.attachments.list.map(a => ({
    AttachmentId: a.id,
    FileName: a.name
  }));

  return {
    TenderId: tender.tenderId,
    TenderStatus: tender.tenderStatus,
    MainPictureUrl: tender.mainPictureUrl,
    Name: tender.name,
    Keywords: tender.keywords,
    Currency: tender.currency,
    TotalExpectedPrice: 0,
    TotalExpectedPriceForUser: tender.totExpPriceInUserCurr,
    Industries: tender.industries.map(industry => industry.id),
    ItemsDetails: itemsDetails,
    CriteriaDetails: criteriaDetails,
    Suppliers: suppliers,
    CanceledReason: tender.canceledReason,
    Attachments: attachments,
    TenderAnnouncements: [],
    MustHaveConditions: mustHaveConditions,
    DeliveryAddress: deliveryAddress,
    IsVatIncluded: tender.isVatIncluded,
    CreateDateTime: tender.createdDate.toISOString(),
    ProposalEndDateTime: moment(tender.proposalEndDate).toISOString(true),
    StartDateTime: moment(tender.startDate).toISOString(true),
    Duration: tender.duration,
    AddressSource: tender.addressSource === 0 || tender.addressSource === 4 ? 3 : tender.addressSource,
    IsPublic: tender.isPublic,
    IsFavorite: tender.isFavorite,
    TenderStepProgress: tender.stepProgress,
    Owner: null
  };
}

/**
 *
 * @param data - data response from /TenderDefaults
 */
export function mapTenderTemplate(
  data: { IsDemo: boolean; Tender: TenderDTO },
  codeLists: IState,
  localization: any
): TenderTemplate {
  const itemsDetails: ItemDetails[] = [];
  const { IsDemo, Tender } = data;
  const { countries } = codeLists;

  if (
    typeof Tender.ItemsDetails !== 'undefined' &&
    Tender.ItemsDetails !== null &&
    Tender.ItemsDetails.length > 0
  ) {
    Tender.ItemsDetails.forEach(item => {
      itemsDetails.push({
        id: item.ItemDetailId,
        name: item.Name,
        unitPrice: item.UnitPrice,
        unitOfMeasurement: { id: 'PC', name: 'Pieces' },
        quantity: item.Quantity,
        expPrice: item.UnitPrice * item.Quantity,
        bidStep: item.BidStep,
        bestBid: -1,
        description: item.Description
      });
    });
  }
  let country: Country = initialCountry;

  const attachments: Attachment[] = [];
  if (typeof Tender.Attachments !== 'undefined' && Tender.Attachments !== null && Tender.Attachments.length > 0) {
    Tender.Attachments.forEach(attachment => {
      attachments.push({
        id: attachment.AttachmentId,
        name: attachment.FileName
      });
    });
  }

  if (
    typeof Tender.DeliveryAddress !== 'undefined' &&
    Tender.DeliveryAddress !== null &&
    typeof Tender.DeliveryAddress.CountryId !== 'undefined' &&
    Tender.DeliveryAddress.CountryId !== null
  ) {
    country = countries.find(country => country.id === Tender.DeliveryAddress?.CountryId)!;
  }

  // We need to replace space with t in date format because it's not accepted as a correct date format in many browsers
  const auctionStartDTT = new Date(Tender.StartDateTime.replace(/ /g, 'T'));
  auctionStartDTT.setSeconds(0);
  const proposalEndDTT = new Date(Tender.ProposalEndDateTime.replace(/ /g, 'T'));
  proposalEndDTT.setSeconds(0);

  const criteriaDetails: CriterionDetails[] = [];

  if (
    typeof Tender.CriteriaDetails !== 'undefined' &&
    Tender.CriteriaDetails !== null &&
    Tender.CriteriaDetails.length > 0
  ) {
    Tender.CriteriaDetails.forEach(criterion => {
      criteriaDetails.push({
        id: criterion.CriterionId,
        name: criterion.Name,
        unit: {
          id: criterion.Unit,
          name: codeLists.unitsOfTenderCriterion.find(crit => crit.id === criterion.Unit)?.name || 'WRONG UNIT ID'
        },
        winningThreshold: {
          id: criterion.WinningThreshold,
          name: codeLists.winningTreshold.find(thresh => thresh.id === criterion.WinningThreshold)!.name
        },
        weight: {
          id: criterion.Weight,
          name: `${criterion.Weight * 100}%`
        },
        bestBid: criterion.BestBid,
        description: criterion.Description ? criterion.Description : '',
        minBid: criterion.MinBid,
        maxBid: criterion.MaxBid
      });
    });
  }

  return {
    isDemo: IsDemo,
    name: Tender.Name,
    currency: Tender.Currency,
    addressSource:
      Tender.AddressSource === AddressSource.DELIVERY ? AddressSource.NEW_DELIVERY : Tender.AddressSource,
    deliveryAddress: {
      id: Tender.DeliveryAddress ? Tender.DeliveryAddress.AddressId : 0,
      city: Tender.DeliveryAddress ? Tender.DeliveryAddress.City : '',
      country: country,
      state: Tender.DeliveryAddress && Tender.DeliveryAddress.State !== null ? Tender.DeliveryAddress.State : '',
      streetAddress: Tender.DeliveryAddress ? Tender.DeliveryAddress.Street : '',
      streetAddress2: Tender.DeliveryAddress ? Tender.DeliveryAddress.StreetLine2 : '',
      zipCode: Tender.DeliveryAddress ? Tender.DeliveryAddress.PostalCode : '',
      gpsCoords: {
        lat: Tender.DeliveryAddress && Tender.DeliveryAddress.GPS_NS ? Tender.DeliveryAddress.GPS_NS : '',
        lng: Tender.DeliveryAddress && Tender.DeliveryAddress.GPS_EW ? Tender.DeliveryAddress.GPS_EW : ''
      }
    },
    criteriaDetails,
    attachments,
    itemsDetails: itemsDetails,
    duration: Tender.Duration,
    startDateTime: moment(auctionStartDTT).toISOString(true),
    proposalEndDateTime: moment(proposalEndDTT).toISOString(true),
    mustHaveConditions: [
      getMandatoryMustHaveCondition(localization),
      ...(Tender.MustHaveConditions?.map(t => ({
        id: t.Id,
        text: t.MustHaveCondition,
        hasAdditionalInfo: t.AdditionalInfo
      })) || [])
    ]
  };
}

/**
 *
 * @param tenderLW - tender light weight object from backend
 */
export function mapTendersLightWeight(tendersLW: TenderLightWeightDTO[]): TenderLightWeight[] {
  const result: TenderLightWeight[] = [];
  if (tendersLW !== null && typeof tendersLW !== 'undefined' && tendersLW.length > 0) {
    tendersLW.forEach(tenderLW => {
      result.push({
        id: tenderLW.ID,
        name: tenderLW.Name,
        mainPictureUrl: tenderLW.MainPictureUrl,
        totalActiveBiddersCount: tenderLW.TotalActiveBiddersCount,
        createDate: moment(tenderLW.CreateDateTime).toDate(),
        newAppliesCount: tenderLW.NewAppliesCount,
        isOwner: tenderLW.Owner,
        ownerName: tenderLW.OwnerName,
        ownerCompanyId: tenderLW.OwnerCompanyId,
        currency: tenderLW.Currency,
        isDemo: tenderLW.IsDemo,
        isPublic: tenderLW.IsPublic,
        startDate: moment(tenderLW.StartDate).toDate(),
        supplierStatus: tenderLW.SuplierStatus,
        totalExpectedPrice: tenderLW.Value,
        tenderStatus: tenderLW.TenderStatusId,
        isFavorite: tenderLW.IsFavorite,
        keywords: tenderLW.Keywords,
        industries: tenderLW.Industries
      });
    });
  }
  return result;
}

/**
 * Maps tender to tender leightweight as owner of the tender!
 * @param tender - Tender
 */
export function mapTenderLWfromTender(tender: Tender): TenderLightWeight {
  return {
    id: tender.tenderId,
    name: tender.name,
    currency: tender.currency,
    mainPictureUrl: tender.mainPictureUrl,
    totalActiveBiddersCount: tender.totalActiveBidders,
    createDate: tender.createdDate,
    newAppliesCount: 0,
    isOwner: true,
    isDemo: tender.suppliers.findIndex(s => s.user.userName.startsWith('demobidder')) > -1,
    ownerName: tender.owner.userName,
    ownerCompanyId: tender.owner.company.id,
    isPublic: tender.isPublic,
    startDate: moment(tender.startDate).toDate(),
    supplierStatus: null,
    totalExpectedPrice: itemsDetailsCalculator.calculateTotalExpPrice(tender.itemsDetails),
    tenderStatus: tender.tenderStatus,
    isFavorite: false,
    industries: tender.industries.join(', '),
    keywords: tender.keywords
  };
}

/**
 * Maps tender to tender leightweight as owner of the tender!
 * @param tender - Tender
 */
export function mapTenderLWfromTenderLWDTO(tenderLW: TenderLightWeightDTO): TenderLightWeight {
  return {
    id: tenderLW.ID,
    name: tenderLW.Name,
    mainPictureUrl: tenderLW.MainPictureUrl,
    totalActiveBiddersCount: tenderLW.TotalActiveBiddersCount,
    newAppliesCount: tenderLW.NewAppliesCount,
    createDate: moment(tenderLW.CreateDateTime).toDate(),
    isOwner: tenderLW.Owner,
    ownerName: tenderLW.OwnerName,
    ownerCompanyId: tenderLW.OwnerCompanyId,
    isPublic: tenderLW.IsPublic,
    isDemo: tenderLW.IsDemo,
    currency: tenderLW.Currency,
    startDate: moment(tenderLW.StartDate).toDate(),
    supplierStatus: tenderLW.SuplierStatus,
    totalExpectedPrice: tenderLW.Value,
    tenderStatus: tenderLW.TenderStatusId,
    isFavorite: tenderLW.IsFavorite,
    keywords: tenderLW.Keywords,
    industries: tenderLW.Industries
  };
}

export const mapToSuppliersItemBid = (itemBidDTO: ItemBidDTO): SuppliersItemBid => {
  return {
    userName: itemBidDTO.UserName,
    itemId: itemBidDTO.ItemId,
    bid: itemBidDTO.Bid.toString()
  };
};

export const mapToSuppliersCriteriaBid = (itemBidDTO: CriterionBidDTO): SuppliersCriterionBid => {
  return {
    userName: itemBidDTO.UserName,
    criteriaId: itemBidDTO.CriterionId,
    bid: itemBidDTO.Bid.toString()
  };
};

export const mapToSuppliersPositionFromDTO = (dto: SuppliersPositionDTO): SuppliersPosition => {
  return {
    userName: dto.UserName,
    position: dto.Position
  };
};

export const mapMustHaveConditionFromDTO = (dto: MustHaveConditionDTO): MustHaveCondition => {
  return {
    id: dto.Id,
    text: dto.MustHaveCondition,
    hasAdditionalInfo: dto.AdditionalInfo
  };
};

/**
 * maps ids for itemsdetails and criteria details
 * @param data - tenderDTO from backend response
 * @param tender - current tender
 */
export function getCriteriaDetailsWithIds(data: TenderDTO, tender: Tender): Array<CriterionDetails> {
  const criteria = tender.criteriaDetails.slice();
  for (let i = 0; i < tender.criteriaDetails.length; i++) {
    if (tender.criteriaDetails[i].id === 0) {
      criteria[i] = { ...criteria[i], id: data.CriteriaDetails![i].CriterionId };
    }
  }

  return criteria;
}

export function getMustHaveConditionsWithIds(data: TenderDTO, tender: Tender): Array<MustHaveCondition> {
  const mustHaveConditions = tender.mustHaveConditions.slice();
  for (let i = 0; i < tender.mustHaveConditions.length; i++) {
    if (tender.mustHaveConditions[i].id === 0) {
      mustHaveConditions[i] = { ...mustHaveConditions[i], id: data.MustHaveConditions![i].Id };
    }
  }

  return mustHaveConditions;
}

export function getItemsDetailsWithIds(data: TenderDTO, tender: Tender): Array<ItemDetails> {
  const items = tender.itemsDetails.slice();
  for (let i = 0; i < tender.itemsDetails.length; i++) {
    if (tender.itemsDetails[i].id === 0) {
      items[i] = { ...items[i], id: data.ItemsDetails![i].ItemDetailId };
    }
  }

  return items;
}

export const mapSavings = (dto): TenderBoardShowings => ({
  tenderId: dto.TenderId,
  currencyId: dto.Currency,
  createdDate: moment(dto.CreatedDate).toDate(),
  amount: dto.IsOwner ? dto.Savings : dto.FinalPrice,
  tenderName: dto.TenderName,
  isOwner: dto.IsOwner
});
