import { IAction } from '_types/commonTypes';
import { TenderTemplate } from '_types/tenderTypes';
import { ThunkAction } from 'redux-thunk';
import tenderApi from '_api/tenderApi';
import { IApplicationState } from 'core/reducer';
import { mapTenderTemplate } from '_mappers/tenderMapper';

export enum IActionTypes {
  LOAD_TEMPLATES = '[TENDER_TEMPLATES] LOAD_TEMPLATES'
}

interface ILoadTemplatesSuccessAction
  extends IAction<IActionTypes.LOAD_TEMPLATES, { templates: TenderTemplate[] }> {}

function loadTenderTemplates(localization: any): ThunkAction<Promise<TenderTemplate[]>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const codeList = getState().codeList;

    return tenderApi
      .fetchTenderTemplates()
      .then(res => res.data.map(d => mapTenderTemplate(d, codeList, localization)))
      .then(templates => {
        dispatch(success(templates));
        return templates;
      });
  };

  function success(templates: TenderTemplate[]): ILoadTemplatesSuccessAction {
    return { type: IActionTypes.LOAD_TEMPLATES, payload: { templates: templates } };
  }
}

export type ITenderTemplatesAction = ILoadTemplatesSuccessAction;

export { loadTenderTemplates };
