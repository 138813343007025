import { ThunkAction } from 'redux-thunk';
import qs from 'qs';
import moment from 'moment';

import * as userService from '_api/userApi';
import * as alertActions from './alertActions';
import history from '_libs/history';
import TokenStorageManager, { JWT_TOKEN, REFRESH_TOKEN } from '_libs/tokenStorageManager/tokenStorageManager';
import setDefaultAuthorizationHeader from '_libs/setDefaultAuthorizationHeader';
import { actionDispatcher } from '_libs/actionDispatcher';
import { routes } from '_constants/routesConstants';
import {
  LoginOptions,
  TenderRedirectQueryString,
  User,
  Company,
  Address,
  AddressSource,
  Role,
  GAUtmParams,
  IAction
} from '_types/commonTypes';
import { mapToUser, mapToUserDetailsDTO, mapToCompanyDTO } from '_mappers/userMapper';
import { IApplicationState } from 'core/reducer';
import { mapAddressToDto } from '_mappers/commonMapper';
import { addNewAddress } from './codeListActions';
import { ShowSuccessToast, ShowErrorToast } from '_components/toasts/toast';
import { userInfoResponseErrors } from '_constants/commonConstants';
import commonActions from './commonActions';
import { UserDetailsDTO } from '_types/backendTypes';

export type NewUser = {
  email: string;
  password: string;
  confirmationPassword: string;
};

export type NewUserErrors = NewUser;

export enum IActionTypes {
  LOGIN_REQUEST = '[login] REQUEST',
  LOGIN_SUCCESS = '[login] SUCCESS',
  LOGIN_FAILURE = '[login] FAILURE',
  LOGOUT = '[logout] LOGOUT',
  REGISTER_REQUEST = '[REGISTER] REQUEST',
  REGISTER_SUCCESS = '[REGISTER] SUCCESS',
  REGISTER_FAILURE = '[REGISTER] FAILURE',
  AUTHENTICATION_SUCCESS = '[AUTHENTICATION] SUCCESS',
  LOAD_USER_DETAILS = '[LOAD_USER_DETAILS] SUCCESS',
  UPDATE_USER_DETAILS = '[UPDATE_USER_DETAILS] SUCCESS',
  UPDATE_COMPANY_DETAILS = '[UPDATE_COMPANY_DETAILS] SUCCESS',
  SAVE_ADDRESS_TO_USER = '[SAVE_ADDRESS_TO_USER] SUCCESS',
  SAVE_ADDRESS_TO_COMPANY = '[SAVE_ADDRESS_TO_COMPANY] SUCCESS',
  USER_DETAILS_LOADED = 'USER_DETAILS_LOADED',
  UPDATE_BILING_INFO = 'UPDATE_BILLING_INFO',
  REDUCE_REMAINING_FREE_TENDERS = 'REDUCE_REMAINING_FREE_TENDERS',
  PROFILE_FILLED = 'PROFILE_FILLED',
  SURVEY_FILLED = 'SURVEY_FILLED'
}

interface ILoginRequestAction {
  type: IActionTypes.LOGIN_REQUEST;
  payload: { userName: string };
}
interface ILoginSuccessAction {
  type: IActionTypes.LOGIN_SUCCESS;
  payload: { userName: string };
}
interface ILoginFailureAction {
  type: IActionTypes.LOGIN_FAILURE;
  payload: { error: string };
}
interface ILogoutAction {
  type: IActionTypes.LOGOUT;
}
interface IRegisterRequestAction {
  type: IActionTypes.REGISTER_REQUEST;
}
interface IRegisterSuccessAction {
  type: IActionTypes.REGISTER_SUCCESS;
}
interface IRegisterFailureAction {
  type: IActionTypes.REGISTER_FAILURE;
  payload: { error: string };
}
interface IAuthenticateUserSuccessAction {
  type: IActionTypes.AUTHENTICATION_SUCCESS;
  payload: {
    userName: string;
    isProfileFilled: boolean;
    roles: Role[];
    expires: Date;
    surveyCompleted: boolean;
    remainingFreeTenders: number;
    creationDate: Date;
  };
}
interface ILoadUserDetailsSuccessAction {
  type: IActionTypes.LOAD_USER_DETAILS;
  payload: { user: User };
}
interface IUpdateUserDetailsSuccessAction {
  type: IActionTypes.UPDATE_USER_DETAILS;
  payload: { user: User };
}
interface IUpdateCompanyDetailsSuccessAction {
  type: IActionTypes.UPDATE_COMPANY_DETAILS;
  payload: { company: Company };
}
interface ISaveAddressToUserSuccessAction {
  type: IActionTypes.SAVE_ADDRESS_TO_USER;
  payload: { address: Address };
}
interface ISaveAddressToCompanySuccessAction {
  type: IActionTypes.SAVE_ADDRESS_TO_COMPANY;
  payload: { address: Address };
}
interface ISaveAddressToCompanySuccessAction {
  type: IActionTypes.SAVE_ADDRESS_TO_COMPANY;
  payload: { address: Address };
}
interface ISaveAddressToCompanySuccessAction {
  type: IActionTypes.SAVE_ADDRESS_TO_COMPANY;
  payload: { address: Address };
}
interface IUserDetailsLoadedAction {
  type: IActionTypes.USER_DETAILS_LOADED;
}
interface IProfileFilledAction {
  type: IActionTypes.PROFILE_FILLED;
}
interface ISurveyFilledAction {
  type: IActionTypes.SURVEY_FILLED;
}

interface IUpdateBillingInfo {
  type: IActionTypes.UPDATE_BILING_INFO;
  payload: { role: Role[]; expires: string };
}

interface IReduceRemainingFreeTenders extends IAction<IActionTypes.REDUCE_REMAINING_FREE_TENDERS, {}> {}

function request(userName: string): ILoginRequestAction {
  return { type: IActionTypes.LOGIN_REQUEST, payload: { userName } };
}
function success(userName: string): ILoginSuccessAction {
  return { type: IActionTypes.LOGIN_SUCCESS, payload: { userName } };
}
function failure(error: string): ILoginFailureAction {
  return { type: IActionTypes.LOGIN_FAILURE, payload: { error } };
}

function login(
  userName: string,
  password: string,
  options: LoginOptions
): ThunkAction<Promise<boolean>, any, null, any> {
  return dispatch => {
    dispatch(request(userName));
    setDefaultAuthorizationHeader('');

    return userService
      .loginBE(userName, password)
      .then(data => {
        const storage = options.staySignedIn ? localStorage : sessionStorage;
        const storageManager = new TokenStorageManager(storage);
        storageManager.setJwtToken(data.access_token);
        storageManager.setRefreshToken(data.refresh_token);
        setDefaultAuthorizationHeader(data.access_token);

        dispatch(success(data.userName));
        dispatch(authenticateUser(data.access_token));

        if (options.externalToken) {
          userService.checkExternalToken(options.externalToken);
        }

        return true;
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data.error_description));
        return false;
      });
  };
}

function impersonate(userName: string): ThunkAction<Promise<boolean>, any, null, any> {
  return dispatch => {
    dispatch(request(userName));

    return userService
      .impersonate(userName)
      .then(data => {
        const storage = sessionStorage;
        const storageManager = new TokenStorageManager(storage);
        storageManager.setJwtToken(data.access_token);
        storageManager.setRefreshToken(data.refresh_token);
        setDefaultAuthorizationHeader(data.access_token);

        dispatch(success(userName));
        dispatch(authenticateUser(data.access_token));

        return true;
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.data.error_description));
        return false;
      });
  };
}

function externalLogin(token: string, refreshToken: string): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const localization = getState().common.localization;

    return userService
      .fetchUserInfoBE(token)
      .then(data => {
        const storageManager = new TokenStorageManager(localStorage);
        storageManager.setJwtToken(token);
        storageManager.setRefreshToken(refreshToken);
        setDefaultAuthorizationHeader(token);

        dispatch(success(data.Name));
        dispatch(
          successAuthenticateUser(
            data.name,
            data.IsUserProfileFilled,
            data.InRoles,
            moment(data.Expires).toDate(),
            data.SurveyCompleted,
            data.RemainingFreeTenders,
            data.Created ? moment(data.Created).toDate() : null
          )
        );
        dispatch(
          commonActions.setPendingCounters(data.PendingAuctionInvitationsCount, data.PendingPublicBiddersCount)
        );
        actionDispatcher.dispatchAllStartupActions(dispatch, token);
        return true;
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(
          alertActions.error(
            error === userInfoResponseErrors.NOT_ACTIVATED
              ? localization.external_login_failed_not_activated
              : localization.external_login_failed_not_authenticated
          )
        );
        return false;
      });
  };
}

function fillProfile(): IProfileFilledAction {
  return { type: IActionTypes.PROFILE_FILLED };
}

function fillSurvey(): ISurveyFilledAction {
  return { type: IActionTypes.SURVEY_FILLED };
}

/**
 * Gets user info from backend if thats not possible due to wrong token user will be redirected
 * to login page and needs to be login
 * @param token - bearer token
 */
function authenticateUser(token: string): ThunkAction<Promise<boolean>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const localization = getState().common.localization;
    return userService
      .fetchUserInfoBE(token)
      .then(data => {
        dispatch(
          successAuthenticateUser(
            data.Name,
            data.IsUserProfileFilled,
            data.InRoles,
            moment(data.Expires).toDate(),
            data.SurveyCompleted,
            data.RemainingFreeTenders,
            data.Created ? moment(data.Created).toDate() : null
          )
        );
        dispatch(
          commonActions.setPendingCounters(data.PendingAuctionInvitationsCount, data.PendingPublicBiddersCount)
        );
        actionDispatcher.dispatchAllStartupActions(dispatch, token);
        return true;
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(localization.authentication_failed));
        return false;
      });
  };
}

function successAuthenticateUser(
  userName: string,
  isProfileFilled: boolean,
  roles: Role[],
  expires: Date,
  surveyCompleted: boolean,
  remainingFreeTenders,
  creationDate
): IAuthenticateUserSuccessAction {
  return {
    type: IActionTypes.AUTHENTICATION_SUCCESS,
    payload: { userName, isProfileFilled, roles, expires, surveyCompleted, remainingFreeTenders, creationDate }
  };
}

function logout(): ThunkAction<Promise<boolean>, any, null, any> {
  return dispatch => {
    return userService
      .logoutBE()
      .then(() => {
        dispatch(success());
        localStorage.removeItem(JWT_TOKEN);
        sessionStorage.removeItem(JWT_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        sessionStorage.removeItem(REFRESH_TOKEN);
        setDefaultAuthorizationHeader('');
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  function success(): ILogoutAction {
    return { type: IActionTypes.LOGOUT };
  }
}

function register(
  user: NewUser,
  subscribeForNewsletter: boolean,
  tenderRedirectQueryString: TenderRedirectQueryString | null | undefined,
  utmString?: string
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const localization = getState().common.localization;
    dispatch(request());

    debugger;
    return userService
      .registerBE(
        user,
        subscribeForNewsletter,
        tenderRedirectQueryString?.externalToken,
        tenderRedirectQueryString?.tenderId,
        utmString
      )
      .then(() => {
        dispatch(success());
        if (tenderRedirectQueryString) {
          const { redirect, tenderId, externalToken } = tenderRedirectQueryString;
          history.push({
            pathname: routes.LOGIN,
            search: qs.stringify({ redirect, tenderId, externalToken })
          });
        } else {
          history.push(routes.LOGIN);
        }

        dispatch(alertActions.success(localization.registration_successful));
      })
      .catch(error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        throw new Error(error);
      });
  };

  function request(): IRegisterRequestAction {
    return { type: IActionTypes.REGISTER_REQUEST };
  }

  function success(): IRegisterSuccessAction {
    return { type: IActionTypes.REGISTER_SUCCESS };
  }

  function failure(error: string): IRegisterFailureAction {
    return { type: IActionTypes.REGISTER_FAILURE, payload: { error } };
  }
}

function loadUserDetails(): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return userService
      .fetchUserDetailsBE()
      .then(res => mapToUser(res.data, getState().codeList.countries, getState().codeList.industries))
      .then(user => {
        dispatch(success(user));
        dispatch(userDetailsLoaded());
      })
      .catch(err => {
        dispatch(userDetailsLoaded());
        throw err;
      });
  };

  function success(user: User): ILoadUserDetailsSuccessAction {
    return { type: IActionTypes.LOAD_USER_DETAILS, payload: { user } };
  }
}

function updateUserDetails(user: User): ThunkAction<Promise<boolean>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const localization = getState().common.localization;
    const dto = mapToUserDetailsDTO(user);

    return userService
      .updateUserDetailsBE(dto)
      .then(res => {
        const data: UserDetailsDTO = res.data;
        dispatch(success({ ...user, isEmailConfirmed: data.ContactEmailConfirmed }));

        if (!data.ContactEmailConfirmed) {
          ShowSuccessToast('Please check your email to activate', { autoClose: 8000 });
        }

        ShowSuccessToast(localization.save_profile_success_toast, { autoClose: 1000 });
        return true;
      })
      .catch(err => {
        ShowErrorToast(localization.save_profile_error_toast);
        return false;
      });
  };

  function success(user: User): IUpdateUserDetailsSuccessAction {
    return { type: IActionTypes.UPDATE_USER_DETAILS, payload: { user } };
  }
}

function updateCompanyDetails(company: Company): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const localization = getState().common.localization;
    const dto = mapToCompanyDTO(company);

    return userService
      .updateCompanyDetailsBE(dto)
      .then(() => {
        dispatch(success(company));
        ShowSuccessToast(localization.save_profile_success_toast);
        return true;
      })
      .catch(err => {
        ShowErrorToast(localization.save_profile_error_toast);
        return false;
      });
  };

  function success(company: Company): IUpdateCompanyDetailsSuccessAction {
    return { type: IActionTypes.UPDATE_COMPANY_DETAILS, payload: { company } };
  }
}

function saveUserAddress(address: Address) {
  return successSaveUserAddress(address);
}

function saveCompanyAddress(address: Address) {
  return successSaveCompanyAddress(address);
}

function saveAddressToProfile(
  saveToUserAddress: boolean,
  address: Address
): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    const dto = mapAddressToDto(address);
    return userService
      .saveAddressToProfileBE(saveToUserAddress, dto)
      .then(res => {
        if (res.data) {
          if (saveToUserAddress) {
            dispatch(successSaveUserAddress(address));
            dispatch(addNewAddress({ addressSource: AddressSource.USER, address }));
          } else {
            dispatch(successSaveCompanyAddress(address));
            dispatch(addNewAddress({ addressSource: AddressSource.COMPANY, address }));
          }
        }
      })
      .catch(err => {
        throw err;
      });
  };
}

function updateBillingInfo(roles: Role[], expires: string): IUpdateBillingInfo {
  return { type: IActionTypes.UPDATE_BILING_INFO, payload: { role: roles, expires } };
}

function reduceRemainingFreeTenders(): IReduceRemainingFreeTenders {
  return { type: IActionTypes.REDUCE_REMAINING_FREE_TENDERS, payload: {} };
}

function successSaveUserAddress(address: Address): ISaveAddressToUserSuccessAction {
  return { type: IActionTypes.SAVE_ADDRESS_TO_USER, payload: { address } };
}

function successSaveCompanyAddress(address: Address): ISaveAddressToCompanySuccessAction {
  return { type: IActionTypes.SAVE_ADDRESS_TO_COMPANY, payload: { address } };
}

function userDetailsLoaded(): IUserDetailsLoadedAction {
  return { type: IActionTypes.USER_DETAILS_LOADED };
}

export type LoginAction = ILoginRequestAction | ILoginSuccessAction | ILoginFailureAction | ILogoutAction;

export type AuthenticationAction =
  | IAuthenticateUserSuccessAction
  | LoginAction
  | ILoadUserDetailsSuccessAction
  | IUpdateUserDetailsSuccessAction
  | IUpdateCompanyDetailsSuccessAction
  | ISaveAddressToUserSuccessAction
  | ISaveAddressToCompanySuccessAction
  | IUserDetailsLoadedAction
  | IProfileFilledAction
  | ISurveyFilledAction
  | IUpdateBillingInfo
  | IReduceRemainingFreeTenders;

export type RegisterAction = IRegisterRequestAction | IRegisterSuccessAction | IRegisterFailureAction;

export {
  login,
  externalLogin,
  logout,
  register,
  authenticateUser,
  loadUserDetails,
  updateUserDetails,
  updateCompanyDetails,
  saveAddressToProfile,
  saveUserAddress,
  saveCompanyAddress,
  userDetailsLoaded,
  fillProfile,
  fillSurvey,
  updateBillingInfo,
  reduceRemainingFreeTenders,
  impersonate
};
