import { Address } from '_types/commonTypes';

export const scrollbarVisible = element => {
  return element.scrollHeight > element.clientHeight;
};

export const composeShortAddressString = (address: Address): string =>
  address.country.code +
  (address.state && ', ' + address.state) +
  (address.city && ', ' + address.city) +
  (address.zipCode && ', ' + address.zipCode);

export const composeShortAddressStringWithFullCountryName = (address: Address): string =>
  address.country.name +
  (address.state && ', ' + address.state) +
  (address.city && ', ' + address.city) +
  (address.zipCode && ', ' + address.zipCode);

export const composeLongAddressString = (address: Address): string =>
  address.country.name +
  (address.state && ', ' + address.state) +
  (address.city && ', ' + address.city) +
  (address.zipCode && ', ' + address.zipCode) +
  (address.streetAddress && ', ' + address.streetAddress) +
  (address.streetAddress2 && ', ' + address.streetAddress2);

export const transformToStarUsername = (userName: string): string => {
  const part1 = userName[1] !== '@' ? userName.slice(0, 2) : userName[0];
  const matchAfterAtSign = userName.match('@(.*)');
  const part2 = matchAfterAtSign ? matchAfterAtSign[0] : '@unknown.com';
  return part1 + '*****' + part2;
};
