import { Store } from 'redux';

import { Hub } from './hub';
import { logout, updateBillingInfo } from '_actions/userActions';
import commonActions from '_actions/commonActions';
import { Role } from '_types/commonTypes';

export class AppHub extends Hub {
  constructor(connection: SignalR.Hub.Connection, store: Store) {
    super(connection, store, 'AppHub');
  }

  registerMethods = () => {
    this.proxy.on('logoutAllUsers', () => {
      this.store.dispatch<any>(logout());
    });

    this.proxy.on('billingUpdate', (res: { roles: Role[]; expires: string }) => {
      this.store.dispatch(updateBillingInfo(res.roles, res.expires));
    });

    this.proxy.on('updatePendingAuctionInvitationsCount', (count: number) => {
      this.store.dispatch(commonActions.setPendingAuctionInvCount(count));
    });

    this.proxy.on('updatePendingPublicBiddersCount', (count: number) => {
      this.store.dispatch(commonActions.setPendingPublicBiddersCount(count));
    });
  };
}
