import { IAction } from '_types/commonTypes';

import { ThunkAction } from 'redux-thunk';
import adminApi from '_api/adminApi';

export enum IActionTypes {
  LOAD_LOCALIZATION = '[COMMON] LOAD_LOCALIZATION',
  SET_PENDING_AUCTION_INV_COUNT = '[COMMON] SET_PENDING_AUCTION_INV_COUNT',
  SET_PENDING_PUBLIC_BIDDERS_COUNT = '[COMMON] SET_PENDING_PUBLIC_BIDDERS_COUNT'
}

interface ILoadLocalizationSuccessAction extends IAction<IActionTypes.LOAD_LOCALIZATION, { localization: any }> {}

interface ISetPendingAuctionInvCount
  extends IAction<IActionTypes.SET_PENDING_AUCTION_INV_COUNT, { count: number }> {}

interface ISetPendingPublicBiddersCount
  extends IAction<IActionTypes.SET_PENDING_PUBLIC_BIDDERS_COUNT, { count: number }> {}

function loadLocalization(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return adminApi.fetchOnlyFeLocalizationTexts().then(res => {
      const mappedText = {};

      res.data.forEach(loc => {
        mappedText[loc.Key] = loc.Value;
      });

      dispatch(loadLocalizationSuccess(mappedText));
    });
  };

  function loadLocalizationSuccess(texts: any): ILoadLocalizationSuccessAction {
    return { type: IActionTypes.LOAD_LOCALIZATION, payload: { localization: texts } };
  }
}

function setPendingCounters(
  auctionInvitationsCount: number,
  publicBiddersCount: number
): ThunkAction<void, any, null, any> {
  return dispatch => {
    dispatch(setPendingAuctionInvCount(auctionInvitationsCount));
    dispatch(setPendingPublicBiddersCount(publicBiddersCount));
  };
}

function setPendingAuctionInvCount(count: number): ISetPendingAuctionInvCount {
  return { type: IActionTypes.SET_PENDING_AUCTION_INV_COUNT, payload: { count } };
}

function setPendingPublicBiddersCount(count: number): ISetPendingPublicBiddersCount {
  return { type: IActionTypes.SET_PENDING_PUBLIC_BIDDERS_COUNT, payload: { count } };
}

export type CommonAction =
  | ILoadLocalizationSuccessAction
  | ISetPendingPublicBiddersCount
  | ISetPendingAuctionInvCount;

export default {
  loadLocalization,
  setPendingCounters,
  setPendingPublicBiddersCount,
  setPendingAuctionInvCount
};
