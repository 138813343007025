/* tslint:disable:no-reference */
/// <reference path="../../node_modules/@types/jquery.validation/index.d.ts" />

import { IApplicationState } from 'core/reducer';
import { store } from 'index';
import { INSERT_NEW_ADDRESS_ID } from '_constants/commonConstants';
import { CHANGE_PASSWORD_FORM, TWITTER_REGISTRATION_FORM, REPORT_ISSUE_FORM } from '_constants/formConstants';
import { routes } from '_constants/routesConstants';
import { emailRegex, passwordRegex, webUrlRegex } from '_libs/regexs';
import { InputTagName, Supplier, SuppliersTenderStatus } from '_types/commonTypes';

const init = () => {
  initValidatorMethods();
  initMessages();
};

/**
 * this needs to be called on the application start in order to init custom methods
 */
const initValidatorMethods = () => {
  $.validator.addMethod(
    'passwordUpperLowerNumber',
    function (value) {
      const regexResult = passwordRegex.test(value);
      return regexResult;
    },
    'Password needs to contain at least one number, capital and small letter'
  );

  $.validator.addMethod(
    'selectRequired',
    function (value) {
      return value !== '';
    },
    'Please select an item'
  );

  $.validator.addMethod('email', function (value) {
    const regexResult = emailRegex.test(value);
    return regexResult || value === '';
  });

  $.validator.addMethod(
    'websiteUrlFormat',
    function (value) {
      const regexResult = webUrlRegex.test(value);
      return regexResult || value === '';
    },
    'Please enter a valid website URL'
  );

  $.validator.addMethod(
    'emailDifferentThanCurrentUserName',
    function (value, _element, userName) {
      return value !== userName;
    },
    'Email can not be the same as yours, please enter another email'
  );

  $.validator.addMethod(
    'emailNotDuplicitAsSuppliers',
    function (value, _element, suppliers: Supplier[]) {
      let result = true;
      if (suppliers.find(s => s.user.userName === value)) {
        result = false;
      }

      return result;
    },
    'Email can not be duplicit, please enter another email'
  );

  $.validator.addMethod(
    'addresSourceRequired',
    function (value) {
      return JSON.parse(value).addressSource !== -1;
    },
    'Please select an item'
  );

  $.validator.addMethod(
    'addressCountryRequired',
    function (value) {
      return JSON.parse(value).addressCountryId !== 0;
    },
    'Please select a country'
  );

  $.validator.addMethod(
    'countrySelectRequired',
    function (value) {
      return Number(value) !== 0;
    },
    'Please select a country'
  );

  $.validator.addMethod(
    'maxDuration',
    function (value: string, _element, max: number) {
      const val = value.replace(' min', '');
      return Number(val) <= max;
    },
    'Please enter a value less than or equal to {0}'
  );
};

const initMessages = () => {
  jQuery.extend(jQuery.validator.messages, {
    required: 'This field is required',
    remote: 'Please fix this field',
    email: 'Please enter your correct email address',
    url: 'Please enter a valid URL',
    date: 'Please enter a valid date',
    dateISO: 'Please enter a valid date (ISO)',
    number: 'Please enter a valid number',
    digits: 'Please enter only digits',
    creditcard: 'Please enter a valid credit card number',
    equalTo: 'Please enter the same value again',
    accept: 'Please enter a value with a valid extension',
    maxlength: jQuery.validator.format('Please enter no more than {0} characters'),
    minlength: jQuery.validator.format('Please enter at least {0} characters'),
    rangelength: jQuery.validator.format('Please enter a value between {0} and {1} characters long'),
    range: jQuery.validator.format('Please enter a value between {0} and {1}'),
    max: jQuery.validator.format('Please enter a value less than or equal to {0}'),
    min: jQuery.validator.format('Please enter a value greater than or equal to {0}')
  });
};

/**
 * Sets up error and valid classes and error and sucessful validation callbacks\
 * @param errrorValidation - set state of an error
 * @param successfulValidation - clear the state of an error
 */
const setDefaults = (
  errrorValidation: (error: JQuery, element: JQuery) => void,
  unhighlight: (element: HTMLElement, errorClass: string, validClass: string) => void,
  validClass: string = 'valid',
  errorClass: string = 'invalid',
  autofocusOnError: boolean = false
) => {
  $.validator.setDefaults({
    errorClass: errorClass,
    validClass: validClass,
    errorPlacement: errrorValidation,
    unhighlight: unhighlight,
    invalidHandler: invalidHandler,
    // Don't highlight anything, will be handled by error in component
    highlight: () => {},
    ignore: ''
  });

  function invalidHandler(_, validator) {
    console.log(validator.errorList);
    if (validator.errorList?.length !== 0 && autofocusOnError) {
      const element = validator.errorList?.[0].element;
      if (element.tagName === InputTagName.SELECT) {
        $(`#${validator.errorList?.[0].element.id}`)
          .siblings('input')[0]
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        // If a element is hiddien therefore we can not scroll to it, in most cases it is react-select, that has another input not hidden we can scroll to
        if (element.getAttribute('type') === 'hidden') {
          $(`#${element.getAttribute('name')}`)[0].scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else {
          $(element)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }
};

const isValid = (formId: string) => {
  return $(`#${formId}`).valid();
};

const validateRegistration = (formId: string) => {
  $(`#${formId}`).validate({
    rules: {
      email: {
        required: true,
        email: true
      },
      password: {
        required: true,
        passwordUpperLowerNumber: true,
        minlength: 8
      },
      confirmationPassword: {
        required: true,
        equalTo: '#password'
      }
    },
    messages: {
      confirmationPassword: {
        equalTo: 'Passwords need to match'
      }
    }
  });
};

const validateForgotPassword = (formId: string) => {
  $(`#${formId}`).validate({
    rules: {
      password: {
        required: true,
        passwordUpperLowerNumber: true,
        minlength: 8
      },
      confirmationPassword: {
        required: true,
        equalTo: '#password'
      }
    },
    messages: {
      confirmationPassword: {
        equalTo: 'Passwords need to match'
      }
    }
  });
};

const validateForgotPasswordRequest = (formId: string) => {
  $(`#${formId}`).validate({
    rules: {
      email: {
        required: true,
        email: true
      }
    }
  });
};

const validateChangePassword = (): JQueryValidation.Validator => {
  return $(`#${CHANGE_PASSWORD_FORM}`).validate({
    rules: {
      oldPassword: {
        required: true
      },
      newPassword: {
        required: true,
        passwordUpperLowerNumber: true,
        minlength: 8
      },
      confirmPassword: {
        required: true,
        equalTo: '#newPassword'
      }
    }
  });
};

const validateHowDidYouHearAboutUsForm = (): JQueryValidation.Validator => {
  return $(`#howDidYouHearAboutUsForm`).validate({
    rules: {
      firstName: {
        required: true
      },
      lastName: {
        required: true
      },
      country: {
        countrySelectRequired: true
      },
      defaultCurrency: {
        selectRequired: true
      },
      message: {
        required: true,
        minlength: 2
      }
    }
  });
};

const validateAuctionsAndSuppliersFilteringForm = (formId: string): JQueryValidation.Validator => {
  return $(`#${formId}`).validate({
    rules: {
      keywords: {
        required: true,
        minlength: 2
      }
    }
  });
};

const validateLogin = (formId: string) => {
  $(`#${formId}`).validate({
    rules: {
      userName: {
        required: true
      },
      password: {
        required: true
      }
    }
  });
};

const validateNewTenderBasicData = (formId: string) => {
  $(`#${formId}`).validate({
    rules: {
      name: {
        required: true
      },
      addressSource: {
        addresSourceRequired: true,
        addressCountryRequired: true
      },
      duration: {
        maxDuration: 44000
      }
    }
  });
};

const validateUserDetailsForm = () => {
  $(`#userDetailsForm`).validate({
    rules: {
      firstName: {
        required: true
      },
      lastName: {
        required: true
      },
      country: {
        countrySelectRequired: true
      },
      linkedinProfile: {
        websiteUrlFormat: true
      },
      facebookProfile: {
        websiteUrlFormat: true
      }
    }
  });
};

const validateCompanyDetailsForm = (isVatRequired: boolean = false) => {
  const validator = $(`#companyDetailsForm`).validate({
    rules: {
      name: {
        required: true
      },
      web: {
        required: true,
        websiteUrlFormat: true
      },
      country: {
        countrySelectRequired: true
      },
      vatId: {}
    }
  });

  validator.settings.rules!['vatId'].required = isVatRequired;
};

const validateReportIssueForm = () => {
  $(`#${REPORT_ISSUE_FORM}`).validate({
    rules: {
      email: {
        required: true,
        email: true
      },
      subject: {
        required: true
      },
      description: {
        required: true
      }
    }
  });
};

const validateSelect = (formId: string, selectId: string) => {
  if ($(`#${formId}`).validate().element(`#${selectId}`)) {
    $(`#${selectId}`).siblings('input').removeClass('invalid');
  } else {
    $(`#${selectId}`).siblings('input').addClass('invalid');
  }
};

const validateCollaboratorForm = formId => {
  $(`#${formId}`).validate({
    rules: {
      email: {
        required: true,
        email: true
      }
    }
  });
};

const validateTwitterRegForm = () => {
  return $(`#${TWITTER_REGISTRATION_FORM}`).validate({
    rules: {
      email: {
        required: true,
        email: true
      }
    }
  });
};

const validateInviteByEmailForm = (formId, suppliers): JQueryValidation.Validator => {
  const applicationState: IApplicationState = store.getState();

  return $(`#${formId}`).validate({
    rules: {
      email: {
        required: true,
        email: true,
        emailDifferentThanCurrentUserName: applicationState.authentication.user.userName,
        emailNotDuplicitAsSuppliers: suppliers
      }
    }
  });
};

const validateEmailForm = id => {
  const applicationState: IApplicationState = store.getState();

  $(`#${id}`).validate({
    rules: {
      email: {
        required: true,
        email: true,
        emailDifferentThanCurrentUserName: applicationState.authentication.user.userName
      }
    }
  });
};

/**
 * validates whether the path is approved for clearing alert message purposes
 * @param fromPathName
 * @param toPathName
 */
export const validateClearingPath = (fromPathName: string, toPathName: string): boolean => {
  return !(fromPathName === '/token') && (toPathName === '/login' || toPathName === '/register');
};

/**
 * returns true if the user is authenticated and is not outhside the application
 * @param isAuthenticated
 * @param pathName
 */
export const shouldShowHeaderAndFooter = (isAuthenticated: boolean, pathName: string): boolean => {
  const landingRoutes = [routes.LOGIN, routes.REGISTER, routes.FORGOT_PASSWORD_REQUEST];
  return isAuthenticated && landingRoutes.indexOf(pathName as any) < 0;
};

/**
 * Edit delivery card should be toggled every time insert new address or delivery addres is clicked
 * @param previousAddressSource
 * @param currentAddressSource
 * @param isCardOpened
 */
export const shouldToggleEditDeliveryCardProp = (
  previousAddressSource: number,
  currentAddressSource: number,
  isCardOpened: boolean
) => {
  return (
    (previousAddressSource === INSERT_NEW_ADDRESS_ID &&
      currentAddressSource !== INSERT_NEW_ADDRESS_ID &&
      isCardOpened) ||
    (previousAddressSource !== INSERT_NEW_ADDRESS_ID && currentAddressSource === INSERT_NEW_ADDRESS_ID) ||
    (previousAddressSource === INSERT_NEW_ADDRESS_ID &&
      currentAddressSource === INSERT_NEW_ADDRESS_ID &&
      !isCardOpened)
  );
};

/**
 * Allow only for certain statuses to go to seller bidding room
 * @param status {number | null} - participation status
 */
export const isBidderAllowedToTender = (status: number | null) => {
  return (
    status === SuppliersTenderStatus.INVITATION_SENT ||
    status === SuppliersTenderStatus.INVITATION_ACCEPTED ||
    status === SuppliersTenderStatus.INVITATION_REJECTED_BY_BIDDER ||
    status === SuppliersTenderStatus.LEFT ||
    status === SuppliersTenderStatus.PENDING_PUBLIC_BIDDER ||
    status === SuppliersTenderStatus.PENDING_COMEBACK ||
    status === SuppliersTenderStatus.FAVORITE ||
    status === SuppliersTenderStatus.INVITATION_NOT_SENT
  );
};

export const isPublicBidderAllowedToTender = (status: number | null) => {
  return status === SuppliersTenderStatus.FAVORITE || status === SuppliersTenderStatus.INVITATION_NOT_SENT;
};

/**
 * Determines wheter current date time is between initial offer end and start of the auction or
 * auction has ended than we should disable bidding reset
 * @param currentDateTime - current date time
 * @param proposalEndDateTime - end date time of initial offer
 * @param tenderStartDateTime - date time of tender start
 * @param tenderEndDateTime - date time of tender end
 */
export const shouldDisableBidding = (
  currentDateTime: Date,
  proposalEndDateTime: Date,
  tenderStartDateTime: Date,
  tenderEndDateTime
): boolean => {
  return (
    (currentDateTime > proposalEndDateTime && currentDateTime < tenderStartDateTime) ||
    currentDateTime > tenderEndDateTime
  );
};

export const isBidderRelevant = (status: SuppliersTenderStatus) =>
  status === SuppliersTenderStatus.INVITATION_NOT_SENT ||
  status === SuppliersTenderStatus.INVITATION_ACCEPTED ||
  status === SuppliersTenderStatus.PENDING_PUBLIC_BIDDER;

export const shouldShowCoveredUserInformation = (userStatus: number) =>
  userStatus === SuppliersTenderStatus.INVITATION_ACCEPTED ||
  userStatus === SuppliersTenderStatus.PENDING_PUBLIC_BIDDER ||
  userStatus === SuppliersTenderStatus.INVITATION_REJECTED_BY_BUYER ||
  userStatus === SuppliersTenderStatus.BLOCKED ||
  userStatus === SuppliersTenderStatus.LEFT;

export const validator = {
  init,
  setDefaults,
  isValid,
  validateRegistration,
  validateLogin,
  validateNewTenderBasicData,
  validateTwitterRegForm,
  validateCollaboratorForm,
  validateUserDetailsForm,
  validateCompanyDetailsForm,
  validateInviteByEmailForm,
  validateSelect,
  validateChangePassword,
  validateForgotPassword,
  validateForgotPasswordRequest,
  validateEmailForm,
  validateAuctionsAndSuppliersFilteringForm,
  validateHowDidYouHearAboutUsForm,
  validateReportIssueForm
};
