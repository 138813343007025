import { ItemDetails } from '_types/commonTypes';

const calculateExpectedPrice = (price: number, quantity: number) => {
  return price * quantity;
};

const calculateBidStep = (price: number) => {
  return price !== 0 ? Number((price * 0.01).toFixed(2)) : 0;
};

const recalculateCriteriaPriceWeight = (
  currPriceWeight: number,
  currWeight: number,
  prevWeight: number
): number => {
  let result: number;
  if (currWeight > prevWeight) {
    result = Math.round((currPriceWeight - (currWeight - prevWeight)) * 100) / 100;
  } else {
    result = Math.round((currPriceWeight + (prevWeight - currWeight)) * 100) / 100;
  }

  return result;
};

const calculateTotalExpPrice = (itemsDetails: Array<ItemDetails>) => {
  return itemsDetails.reduce((a, b) => {
    return a + b.expPrice;
  }, 0);
};

export const calculateSum = values => {
  return values.reduce((a, b) => a + b, 0);
};

export const itemsDetailsCalculator = {
  calculateExpectedPrice,
  calculateBidStep,
  calculateTotalExpPrice
};

export const criteriaDetailsCalculator = {
  recalculateCriteriaPriceWeight
};
