import { combineReducers } from 'redux';
import { routerReducer, RouterState } from 'react-router-redux';

import * as fromAlert from '_reducers/alertReducer';
import * as fromAuthentication from '_reducers/authenticationReducer';
import * as fromRegistration from '_reducers/registrationReducer';
import * as fromTender from '_reducers/tenderReducer';
import * as fromTenderTemplates from '_reducers/tenderTemplatesReducer';
import * as fromMyTenders from '_reducers/myTendersReducer';
import * as fromNotification from '_reducers/notificationReducer';
import * as fromCodeList from '_reducers/codeListReducer';
import * as fromUI from '_reducers/uiReducer';
import * as fromUsers from '_reducers/usersReducer';
import * as fromTenders from '_reducers/tendersReducer';
import * as fromGovTenders from '_reducers/governmentTendersReducer';
import * as fromWiki from '_reducers/wikiReducer';
import * as fromGuide from '_reducers/guideReducer';
import * as fromContacts from '_reducers/ContactsReducer';
import * as fromCommon from '_reducers/commonReducer';
import * as fromNewsletter from '_reducers/newsletterReducer';
import * as fromAdmin from '_reducers/admin/adminReducer';

import { LoginAction, IActionTypes } from '_actions/userActions';

interface IApplicationState {
  admin: fromAdmin.IState;
  alert: fromAlert.IState;
  authentication: fromAuthentication.IState;
  common: fromCommon.IState;
  registration: fromRegistration.IState;
  tender: fromTender.IState;
  tenderTemplates: fromTenderTemplates.IState;
  myTenders: fromMyTenders.IState;
  notifcation: fromNotification.IState;
  codeList: fromCodeList.IState;
  ui: fromUI.IState;
  users: fromUsers.IState;
  tenders: fromTenders.IState;
  govTenders: fromGovTenders.IState;
  wiki: fromWiki.IState;
  guide: fromGuide.IState;
  contacts: fromContacts.IState;
  newsletter: fromNewsletter.IState;
  routing: RouterState;
}

const initialState: IApplicationState = {
  admin: fromAdmin.initialState,
  alert: fromAlert.initialState,
  authentication: fromAuthentication.initialState,
  common: fromCommon.initialState,
  registration: fromRegistration.initialState,
  tender: fromTender.initialState,
  tenderTemplates: fromTenderTemplates.initialState,
  myTenders: fromMyTenders.initialState,
  notifcation: fromNotification.initialState,
  codeList: fromCodeList.initialState,
  ui: fromUI.initialState,
  users: fromUsers.initialState,
  tenders: fromTenders.initialState,
  govTenders: fromGovTenders.initialState,
  wiki: fromWiki.initialState,
  guide: fromGuide.initialState,
  contacts: fromContacts.initialState,
  newsletter: fromNewsletter.initialState,
  routing: { locationBeforeTransitions: null }
};

const appReducer = combineReducers<IApplicationState>({
  admin: fromAdmin.reducer,
  alert: fromAlert.reducer,
  authentication: fromAuthentication.reducer,
  common: fromCommon.reducer,
  registration: fromRegistration.reducer,
  tender: fromTender.reducer,
  tenderTemplates: fromTenderTemplates.reducer,
  myTenders: fromMyTenders.reducer,
  notifcation: fromNotification.reducer,
  codeList: fromCodeList.reducer,
  ui: fromUI.reducer,
  users: fromUsers.reducer,
  tenders: fromTenders.reducer,
  govTenders: fromGovTenders.reducer,
  wiki: fromWiki.reducer,
  guide: fromGuide.reducer,
  contacts: fromContacts.reducer,
  newsletter: fromNewsletter.reducer,
  routing: routerReducer
});

const rootReducer = (state: IApplicationState, action: LoginAction) => {
  if (action.type === IActionTypes.LOGIN_SUCCESS) {
    state = {
      ...initialState,
      // Preserve localization
      common: { ...initialState.common, localization: state.common.localization }
    };
  }

  return appReducer(state, action);
};

export { IApplicationState, initialState, rootReducer };
