import { Action, ConfigGuide, IActionTypes } from '_actions/guideActions';
import { GuideNavigationTarget } from '_types/commonTypes';

export interface IState {
  opened: boolean;
  heading: string;
  message: string;
  target: GuideNavigationTarget;
  config?: ConfigGuide;
}

export const initialState: IState = {
  opened: false,
  heading: '',
  message: '',
  target: GuideNavigationTarget.NONE,
  config: {}
};

export function reducer(state: IState = initialState, action: Action): IState {
  switch (action.type) {
    case IActionTypes.OPEN_GUIDE: {
      return {
        ...state,
        opened: true
      };
    }
    case IActionTypes.CLOSE_GUIDE: {
      return {
        ...state,
        opened: false
      };
    }
    case IActionTypes.SET_GUIDE: {
      return {
        ...state,
        heading: action.payload.heading,
        message: action.payload.message,
        target: action.payload.target,
        config: action.payload.config
      };
    }
    default:
      return state;
  }
}
