import axios from 'axios';

import API_URL from '_constants/apiConstants';

const NOTIFICATION_URL = '/api/notifications';

const NOTIFICATION_API_URL = API_URL + NOTIFICATION_URL;

const fetchNotificationsBE = async (page: number, countPerPage: number): Promise<any> => {
  const res = await axios.get(`${NOTIFICATION_API_URL}/GetNotificationsForUser`, {
    params: {
      page,
      countPerPage
    }
  });
  return res;
};

const MarkMultipleNotificationsAsReadBE = async (notificationsIds: number[]): Promise<any> => {
  const res = await axios.patch(
    `${NOTIFICATION_API_URL}/SetSelectedNotificationAsRead`,
    notificationsIds
  );
  return res;
};

export { fetchNotificationsBE, MarkMultipleNotificationsAsReadBE };
