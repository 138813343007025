export const INSERT_NEW_ADDRESS_ID = 3;
export const INSERT_NEW_ADDRESS_NAME = 'Insert new address';
export const DELIVERY_ADDRESS_OPTION = 'New delivery address';
export const YES_OR_NO_CRITERION_UNIT_ID = 'YN';
export const userInfoResponseErrors = {
  NOT_ACTIVATED: 'not-activated',
  NOT_AUTHENTICATED: 'not-authenticated'
};

export const HIDE_ON_SMALL = 'hide-on-small';
export const HIDE_ON_MEDIUM_DOWN = 'hide-on-medium-and-down';
export const HIDE_ON_LARGE_UP = 'hide-on-large-and-up';

// Table constants
export const paginationTables = {
  AUCTION_SEARCH_RESULT: 'PaginationTable(AUCTION_SEARCH_RESULT)',
  GOV_AUCTION_SEARCH_RESULT: 'PaginationTable(GOV_AUCTION_SEARCH_RESULT)',
  SUPPLIERS_SEARCH_RESULT: 'PaginationTable(SUPPLIERS_SEARCH_RESULT)'
};

export const MANIFESTO_KEY = 'manifesto';
