import API_URL from '_constants/apiConstants';
import debounce from 'lodash.debounce';

import 'jquery';
import 'signalr';

import { ConnectionActionTypes, signalrConnection } from '_actions/connectionActions';
import TokenStorageManagerFactory from '_libs/tokenStorageManager/tokenStorageManagerFactory';
import { getTokenPersistance } from '_libs/tokenStorageManager/tokenStorageManager';

const connection = $.hubConnection(`${API_URL}/signalr`, {
  useDefaultPath: false
});

const MAX_RECONNECT_ATTEMPTS = 6;

/**
 * signalR middleware is maintaining connection responsibilities as start, stop,
 * invoke, methods.
 * @param actionDispatcher - method to initialize hubs and register methods to
 * dispatch events
 */
export default function createSignalrMiddleware(actionDispatcher) {
  return store => {
    const dispatch = store.dispatch.bind(store);
    const stateConversion = {
      0: 'connecting',
      1: 'connected',
      2: 'reconnecting',
      4: 'disconnected'
    };

    let keepAlive = false;
    let wasConnected = false;
    let currentState = null;
    let reconnectAttempts = 0;

    actionDispatcher(store, connection);

    function onStateChanged(state) {
      if (currentState === state) {
        return;
      }
      currentState = state;
      dispatch({
        type: ConnectionActionTypes.STATE_CHANGED,
        state: state
      });
    }

    connection.stateChanged(state => {
      const newStateName = stateConversion[state.newState];

      if (newStateName === 'connected') {
        wasConnected = true;
        reconnectAttempts = 0;
        onStateChanged('connected');
      }
    });

    // When the connection drops, try to reconnect.
    connection.disconnected(
      debounce(function () {
        console.log('reconnecting');
        if (keepAlive) {
          if (wasConnected) {
            onStateChanged('reconnecting');
          } else {
            onStateChanged('connecting');
          }

          reconnectAttempts++;

          if (reconnectAttempts <= MAX_RECONNECT_ATTEMPTS) {
            const storage = new TokenStorageManagerFactory().create(getTokenPersistance());
            const accessToken = storage.getJwtToken();

            if (accessToken) {
              dispatch(signalrConnection.start(accessToken));
            } else {
              console.log('signalR: Access token is missing');
            }
          }
        }
      }, 10000)
    );

    return next => action => {
      const { type, payload } = action;

      switch (type) {
        case ConnectionActionTypes.START:
          keepAlive = true;
          onStateChanged('connecting');
          reconnectAttempts = 0;

          connection.qs = { AUTHORIZATION: 'Bearer ' + payload.token };

          connection
            .start()
            .then(() => console.log('signalR connected'))
            .catch(() => console.log('signalR not connected'));
          return;

        case ConnectionActionTypes.STOP:
          keepAlive = false;
          wasConnected = false;
          onStateChanged('disconnected');
          connection.stop();
          return;

        case ConnectionActionTypes.INVOKE:
          const { hub, method, args } = action.payload;
          const proxy = connection[hub];
          proxy.invoke(method, ...args);
          return;

        default:
          return next(action);
      }
    };
  };
}
