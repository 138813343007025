import React from 'react';
import * as classNames from 'classnames';

import Button, { ButtonProps } from './Button';
import { IconPosition } from '_types/commonTypes';
import MaterialIcon from '../icons/MaterialIcon';

type Props = {
  iconName?: string;
  iconPosition?: IconPosition;
};

const FormButton: React.FC<Props & ButtonProps> = ({ className, iconName, iconPosition, ...restProps }) => {
  return (
    <Button className={classNames('btn--form', className)} {...restProps}>
      {iconName && (
        <MaterialIcon iconName={iconName} iconPosition={iconPosition ? iconPosition : IconPosition.LEFT} />
      )}
    </Button>
  );
};

export default FormButton;
