import React from 'react';
import { connect } from 'react-redux';

import PageSidebarButton from '_components/buttons/pageSidebar/PageSidebarButton';
import { IApplicationState } from 'core/reducer';
import { toggleHideIrrelvantSuppliers } from '_actions/tenderActions';
import { useLocalization } from '_libs/contexts/LocalizationContext';

interface IStateToProps {
  active: boolean;
}

interface IDispatchToProps {
  toggleHideIrrelevantSuppliers: () => void;
}

type Props = {
  onClick?: (active: boolean) => void;
} & IStateToProps &
  IDispatchToProps;

export const HIDE_IRRELEVANT_SUPPLIERS_STATE_CACHE = 'hideIrrelevantState';

const HideIrrelevantSuppliersButton: React.FC<Props> = ({
  onClick,
  active,
  toggleHideIrrelevantSuppliers
}) => {
  const localization = useLocalization();

  const handleClick = e => {
    const newActiveState = !active;

    toggleHideIrrelevantSuppliers();
    localStorage.setItem(HIDE_IRRELEVANT_SUPPLIERS_STATE_CACHE, String(newActiveState));

    if (onClick) {
      onClick(newActiveState);
    }
  };

  return (
    <PageSidebarButton
      label={localization.supp_def_sidebar_hide_irrelevant_button}
      className={active ? 'sideBar__btn--active' : ''}
      onClick={handleClick}
    />
  );
};

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  active: state.tender.hideIrrelevantSuppliers
});

const mapDispatchToProps: IDispatchToProps = {
  toggleHideIrrelevantSuppliers: toggleHideIrrelvantSuppliers
};

export default connect(mapStateToProps, mapDispatchToProps)(HideIrrelevantSuppliersButton);
