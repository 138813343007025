import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { ConfirmationAlertUIProps } from '_components/confirmationAlerts/ConfirmationAlertUI';
import { MustHaveConditionResponse } from './tenderTypes';

export type StripeStatus =
  | 'active'
  | 'past_due'
  | 'unpaid'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing';

export interface Currency {
  id: string;
  name: string;
  symbol: string;
}

export interface CodeList<T> {
  id: T;
  name: string;
}

export interface Industry {
  id: string;
  name: string;
  display: string;
}

export interface AddressOptionValue {
  addressSource: number;
  addressCountryId: number;
  addressZipCode: string;
  addressStreet: string;
}

export interface ItemDetails {
  id: number;
  name: string;
  unitPrice: number;
  unitOfMeasurement: CodeList<string>;
  quantity: number;
  expPrice: number;
  bidStep: number;
  bestBid: number;
  description: string;
}

export interface CriterionDetails {
  id: number;
  name: string;
  unit: CodeList<string>;
  winningThreshold: CodeList<number>;
  weight: CodeList<number>;
  bestBid: number;
  description: string;
  minBid?: number;
  maxBid?: number;
}

export interface Address {
  id: number;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  country: Country;
  gpsCoords: Coords;
}

export interface Coords {
  lat: string;
  lng: string;
}

export interface AddressCodeList {
  addressSource: AddressSource;
  address: Address;
}

export interface Country extends CodeList<number> {
  code: string;
  capital: string;
  region: string;
  banned: boolean | null;
}

export interface RegionCountries {
  regionId: number;
  region: string;
  regionGroup: number;
  countries: number[];
}

export interface AttachmentState {
  isUploading: boolean;
  list: Attachment[];
}

export interface Attachment {
  id: number;
  name: string;
}

export interface AttachmentReq {
  name: string;
  data: FormData;
}

export interface ConfirmationAlertOptions {
  title: string;
  message: string;
  customUI: React.FC<ConfirmationAlertUIProps>;
  onConfirm?: () => void;
  onCancel?: () => void;
  willUnmount?: () => void;
}

export interface User {
  userName: string;
  coveredUsername: string;
  country: string;
  email: string;
  isEmailConfirmed: boolean;
  rating: number;
  firstName: string;
  lastName: string;
  address: Address;
  accountType: string;
  defaultCurrency: string;
  phoneNumber: string;
  linkedinProfile: string;
  facebookProfile: string;
  wantToSell: boolean;
  wantToBuy: boolean;
  favouriteAuctionKeywords: string;
  isSearcheable: boolean;
  company: Company;
  hideInfo?: boolean;
  description?: string;
}

export interface Company {
  id: number;
  name: string;
  web: string;
  position: string;
  industries: Industry[];
  numberOfEmployees: string;
  yearlyRevenue: string;
  address: Address;
  branches: number;
  vatId: string;
  registrationId: string;
  taxId: string;
}

export interface Supplier {
  status: CodeList<number>;
  rejectedReason: string;
  user: User;
  isPublic: boolean;
  overallPosition: number;
  attachments: AttachmentState;
  itemsBids: Array<Bid>;
  criteriaBids: Array<Bid>;
  offerDescription: string;
  lastActivityDate: string;
  canReinvite: boolean;
  mustHaveConditionResponses: MustHaveConditionResponse[];
}

export interface Bid {
  id: number;
  value: string;
}

export interface TenderRedirectQueryString {
  redirect: string;
  tenderId: string;
  externalToken?: string;
  email?: string;
}

export interface WikiQuestionAndAnswer {
  id: number;
  categoryId: number;
  question: string;
  answer: string;
}

export interface LoginOptions {
  staySignedIn: boolean;
  redirect?: string;
  tenderId?: string;
  externalToken?: string;
}

export interface SuppliersPosition {
  userName: string;
  position: number;
}

export interface CountdownTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface Notification {
  id: number;
  title: string;
  text: string;
  date: Date;
  read: boolean;
}

export interface TooltipProps {
  dataTip?: string;
  dataFor?: string;
}

export interface Order {
  columnId: string;
  direction: SortDirection;
}

export type ReduxDispatch = ThunkDispatch<any, any, Action>;

export interface IAction<T, P> {
  type: T;
  payload: P;
}

export interface KeyValuePair<T, S> {
  key: T;
  value: S;
}

export interface LocalizationText extends KeyValuePair<string, string> {
  correction: string;
  isFeText: boolean;
  textCorrected: boolean;
}

/** ENUMS */
export enum Role {
  TRANSLATOR = 'Translator',
  ADMIN = 'Admin',
  FREE = 'Free',
  BUSINESS_STANDARD = 'BusinessStandard',
  BUSINESS_PREMIUM = 'BusinessPremium'
}

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  NONE = 'none'
}

export enum InputTagName {
  SELECT = 'SELECT'
}

export enum SuppliersTenderStatus {
  INVITATION_NOT_SENT = 0,
  INVITATION_SENT = 1,
  PENDING_PUBLIC_BIDDER = 2,
  INVITATION_ACCEPTED = 3,
  INVITATION_REJECTED_BY_BIDDER = 4,
  INVITATION_REJECTED_BY_BUYER = 5,
  REMOVED = 6,
  BLOCKED = 7,
  APPROVAL_MISSED = 8,
  FAVORITE = 9,
  LEFT = 10,
  PENDING_COMEBACK = 11
}

export enum AddressSource {
  CURRENT_LOCATION = 0,
  USER = 1,
  COMPANY = 2,
  DELIVERY = 3,
  NEW_DELIVERY = 4
}

export enum Persistance {
  LOCAL = 'local',
  SESSION = 'session',
  NONE = 'none'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

export enum Color {
  WHITE = 'white',
  GREEN = 'green'
}

export enum GuideNavigationTarget {
  PROFILE = 'profile',
  DEMO_NEXT_STEP = 'demo-next-step',
  MANIFESTO = 'manifesto',
  NONE = ''
}

export enum NewsletterStatus {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
  PENDING = 'pending',
  CLEANED = 'cleaned'
}

export interface GAUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}
