import { UtiltyAction, IActionTypes } from '_actions/uiActions';

export interface IState {
  auctionDataStep: {
    isEditDeliveryCardOpened: boolean;
    saveAddressToUserProfile: boolean;
    saveAddressToCompanyProfile: boolean;
  };
  suppliersDefinitionStep: {
    isInviteByEmailSectionOpened: boolean;
    isAddFromBestauctionUserCardOpened: boolean;
    isAddFromMyContactsCardOpened: boolean;
  };
  auctionRoom: {
    hasAuctionEnded: boolean;
    isDetailedTableViewActive: boolean;
  };
  notificationPanel: {
    isOpened: boolean;
  };
}

export const initialState: IState = {
  auctionDataStep: {
    isEditDeliveryCardOpened: false,
    saveAddressToCompanyProfile: true,
    saveAddressToUserProfile: true
  },
  suppliersDefinitionStep: {
    isInviteByEmailSectionOpened: false,
    isAddFromBestauctionUserCardOpened: false,
    isAddFromMyContactsCardOpened: false
  },
  auctionRoom: {
    hasAuctionEnded: false,
    isDetailedTableViewActive: true
  },
  notificationPanel: {
    isOpened: false
  }
};

export function reducer(state: IState = initialState, action: UtiltyAction): IState {
  switch (action.type) {
    case IActionTypes.TOGGLE_EDIT_DELIVERY_CARD:
      return {
        ...state,
        auctionDataStep: {
          ...state.auctionDataStep,
          isEditDeliveryCardOpened: !state.auctionDataStep.isEditDeliveryCardOpened
        }
      };
    case IActionTypes.TOGGLE_SAVE_ADDRESS_TO_USER_PROFILE:
      return {
        ...state,
        auctionDataStep: {
          ...state.auctionDataStep,
          saveAddressToUserProfile: !state.auctionDataStep.saveAddressToUserProfile
        }
      };
    case IActionTypes.TOGGLE_SAVE_ADDRESS_TO_COMPANY_PROFILE:
      return {
        ...state,
        auctionDataStep: {
          ...state.auctionDataStep,
          saveAddressToCompanyProfile: !state.auctionDataStep.saveAddressToCompanyProfile
        }
      };
    case IActionTypes.TOGGLE_INIVTE_BY_EMAIL_SECTION:
      return {
        ...state,
        suppliersDefinitionStep: {
          ...state.suppliersDefinitionStep,
          isInviteByEmailSectionOpened: !state.suppliersDefinitionStep.isInviteByEmailSectionOpened,
          isAddFromBestauctionUserCardOpened: false,
          isAddFromMyContactsCardOpened: false
        }
      };
    case IActionTypes.TOGGLE_ADD_FROM_BESTAUCTION_USERS_CARD:
      return {
        ...state,
        suppliersDefinitionStep: {
          ...state.suppliersDefinitionStep,
          isInviteByEmailSectionOpened: false,
          isAddFromBestauctionUserCardOpened: !state.suppliersDefinitionStep.isAddFromBestauctionUserCardOpened,
          isAddFromMyContactsCardOpened: false
        }
      };
    case IActionTypes.TOGGLE_ADD_FROM_MY_CONTACTS_CARD:
      return {
        ...state,
        suppliersDefinitionStep: {
          ...state.suppliersDefinitionStep,
          isInviteByEmailSectionOpened: false,
          isAddFromBestauctionUserCardOpened: false,
          isAddFromMyContactsCardOpened: !state.suppliersDefinitionStep.isAddFromMyContactsCardOpened
        }
      };
    case IActionTypes.TOGGLE_AUCTION_ENDED_NOTIFICATION:
      return {
        ...state,
        auctionRoom: {
          ...state.auctionRoom,
          hasAuctionEnded: action.payload.hasEnded
        }
      };
    case IActionTypes.TOGGLE_DETAILED_TABLE_VIEW: {
      return {
        ...state,
        auctionRoom: {
          ...state.auctionRoom,
          isDetailedTableViewActive: !state.auctionRoom.isDetailedTableViewActive
        }
      };
    }
    case IActionTypes.TOGGLE_NOTIFICATION_PANEL: {
      return {
        ...state,
        notificationPanel: {
          ...state.notificationPanel,
          isOpened: !state.notificationPanel.isOpened
        }
      };
    }
    default:
      return state;
  }
}
