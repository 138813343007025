declare global {
    interface Date {
        getTimeString(): string;
        addDays(days: number): Date;
        addHours(hours: number): Date;
        addMinutes(minutes: number): Date;
        addSeconds(seconds: number): Date;
        addMiliseconds(ms: number): Date;
        setHoursMinutes(hours:number, minutes: number): Date;
        addTimeFromAMPM(time: string): Date;
    }
}

Date.prototype.getTimeString = function (): string {
    let h = this.getHours().toString();
    let m = this.getMinutes().toString();
    if (Number(h) < 10) h = '0' + h;
    if (Number(m) < 10) m = '0' + m;
    return `${h}:${m}`;
}

Date.prototype.addDays = function (days: number): Date {
    this.setDate(this.getDate() + days);
    return this;
}

Date.prototype.addHours = function (hours: number): Date {
    this.setHours(this.getHours() + hours);
    return this;
}

Date.prototype.addMinutes = function (minutes: number): Date {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
}

Date.prototype.setHoursMinutes = function (hours: number, minutes: number): Date {
    this.setHours(hours);
    this.setMinutes(minutes);
    return this;
}

Date.prototype.addSeconds = function (seconds: number): Date {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
}

Date.prototype.addMiliseconds = function (ms: number): Date {
    this.setMilliseconds(this.getMilliseconds() + ms);
    return this;
}

Date.prototype.addTimeFromAMPM = function (time: string): Date {
    const ampm = time.slice(time.length - 2,time.length);
    const timeArr: string[] = time.slice(0,5).split(':');
    if(ampm === 'PM'){
        this.setHours(Number(timeArr[0]) + 12);
    }else{
        this.setHours(Number(timeArr[0]));
    } 
    this.setMinutes(Number(timeArr[1]));

    return this;
}


export { };
