import TokenStorageManager from './tokenStorageManager';
import { Persistance } from '_types/commonTypes';

class TokenStorageManagerFactory {
  create = (persistance: Persistance): TokenStorageManager => {
    let storage;

    if (persistance === Persistance.LOCAL) {
      storage = localStorage;
    } else {
      storage = sessionStorage;
    }

    return new TokenStorageManager(storage);
  };
}

export default TokenStorageManagerFactory;
