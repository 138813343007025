import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MustHaveConditions from './MustHaveConditions';
import { IApplicationState } from 'core/reducer';
import {
  addNewMusHaveCondition,
  removeMustHaveCondition,
  updateMustHaveCondition,
  updateTenderProps
} from '_actions/tenderActions';
import { LocalizationContext } from '_libs/contexts/LocalizationContext';
import { MustHaveCondition } from '_types/tenderTypes';
import { v4 as uuidv4 } from 'uuid';

interface IOwnProps {
  isEditable: boolean;
}

interface IStateToProps extends IOwnProps {
  mustHaveConditions: MustHaveCondition[];
}

interface IDispatchToProps {
  addMustHaveCondition: (condition: MustHaveCondition) => Promise<void>;
  updateMustHaveCondition: (index: number, condition: MustHaveCondition) => void;
  removeMustHaveCondition: (index: number) => void;
  updateTenderProps: (name: string, value: any, shouldUpdateOnBackend: boolean) => void;
}

export const getMandatoryMustHaveCondition = (localization: any) => ({
  id: 0,
  text: localization.auct_params_gdpr_must_have_condition,
  hasAdditionalInfo: false
});

/**
 * isFocused array determins what input is focused and shows confirmation button
 */
type State = {
  mustHaveConditions: MustHaveCondition[];
  isFocused: boolean[];
};

type Props = IStateToProps & IDispatchToProps;

class MustHaveConditionsContainer extends React.Component<Props, State> {
  static contextType = LocalizationContext;

  constructor(props) {
    super(props);
    this.state = {
      mustHaveConditions: this.props.mustHaveConditions,
      isFocused: []
    };
  }

  componentDidMount() {
    // Default MHC is added before even tender is created, so we have to add it without updating backend
    if (this.props.mustHaveConditions.length === 0) {
      this.props.updateTenderProps('mustHaveConditions', [getMandatoryMustHaveCondition(this.context)], false);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.mustHaveConditions !== prevProps.mustHaveConditions) {
      this.setState({ mustHaveConditions: this.props.mustHaveConditions });
    }
  }

  handleConfirmCondition = (index: number) => {
    this.updateCondition(index);
  };

  /**
   * Handle keydown and when enter is hit, condition is updated in redux store
   */
  handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      this.updateCondition(index);
      e.currentTarget.blur();
    }
  };

  updateCondition = (index: number) => {
    const propsValue = this.props.mustHaveConditions[index];
    const value = this.state.mustHaveConditions[index];

    if (propsValue.text !== value.text || propsValue.hasAdditionalInfo !== value.hasAdditionalInfo) {
      this.props.updateMustHaveCondition(index, value);
    }
  };

  handlerRemoveCondition = (index: number) => {
    this.props.removeMustHaveCondition(index);
  };

  handleAddCondition = () => {
    this.props.addMustHaveCondition({ id: uuidv4(), text: '', hasAdditionalInfo: false });
    this.setState({ isFocused: [...this.state.isFocused, false] });
  };

  handleConditionChange = (row: number, condition: MustHaveCondition) => {
    const mustHaveConditions = this.state.mustHaveConditions.slice();
    const isCheckboxChanged = condition.hasAdditionalInfo !== mustHaveConditions[row].hasAdditionalInfo;
    mustHaveConditions[row] = condition;

    this.setState({ mustHaveConditions }, () => {
      if (isCheckboxChanged) {
        this.updateCondition(row);
      }
    });
  };

  handleFocusCondition = (index: number) => {
    const isFocused = this.state.isFocused;
    isFocused[index] = true;
    this.setState({ isFocused });
  };

  handleBlur = (index: number) => {
    const isFocused = this.state.isFocused;
    isFocused[index] = false;

    const propValue = this.props.mustHaveConditions[index];
    const value = this.state.mustHaveConditions[index];

    if (propValue.text !== value.text || propValue.hasAdditionalInfo !== value.hasAdditionalInfo) {
      this.setState({ isFocused }, () => {
        if (!value.text) {
          this.handlerRemoveCondition(index);
        } else {
          this.updateCondition(index);
        }
      });
    } else {
      this.setState({ isFocused }, () => {
        if (!value.text && !propValue.text) {
          this.handlerRemoveCondition(index);
        }
      });
    }
  };

  render() {
    return (
      <MustHaveConditions
        onConditionChange={this.handleConditionChange}
        onAddConditionClick={this.handleAddCondition}
        onComfirmCondition={this.handleConfirmCondition}
        onRemoveCondition={this.handlerRemoveCondition}
        onConditionBlur={this.handleBlur}
        onFocusCondition={this.handleFocusCondition}
        onInputKeyDown={this.handleInputKeyDown}
        isEditable={this.props.isEditable}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state: IApplicationState, ownProps: IOwnProps): IStateToProps => ({
  mustHaveConditions: state.tender.currentTender.mustHaveConditions,
  isEditable: ownProps.isEditable
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
  addMustHaveCondition: (condition: MustHaveCondition) => dispatch<any>(addNewMusHaveCondition(condition)),
  removeMustHaveCondition: (index: number) => dispatch(removeMustHaveCondition(index)),
  updateMustHaveCondition: (index: number, condition: MustHaveCondition) =>
    dispatch(updateMustHaveCondition(index, condition)),

  updateTenderProps: (name: string, value: any, shouldUpdateOnBackend: boolean) =>
    dispatch<any>(updateTenderProps(name, value, shouldUpdateOnBackend))
});

export default connect(mapStateToProps, mapDispatchToProps)(MustHaveConditionsContainer);
