import { Action, IActionTypes } from '_actions/alertActions';

export interface IState {
  type: string;
  message: string;
}

export const initialState: IState = {
  type: '',
  message: ''
};

export function reducer(state: IState = initialState, action: Action): IState {
  switch (action.type) {
    case IActionTypes.SUCCESS:
      return {
        type: 'alert--success',
        message: action.payload.message
      };
    case IActionTypes.ERROR:
      return {
        type: 'alert--error',
        message: action.payload.message
      };
    case IActionTypes.CLEAR:
      return {
        type: '',
        message: ''
      };
    default:
      return state;
  }
}
