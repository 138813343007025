import React, { createContext, useContext, useState, useEffect } from 'react';

import useWindowWidth from '../hooks/useWindowWidth';

const mobileScreenBreakpoint = Number(
  getComputedStyle(document.documentElement).getPropertyValue('--data-small-screen')
);

const tabletScreenBreakpoint = Number(
  getComputedStyle(document.documentElement).getPropertyValue('--data-medium-screen')
);

const largeScreenBreakpoint = Number(
  getComputedStyle(document.documentElement).getPropertyValue('--data-large-screen')
);

type BaseMedia = {
  largeDesktop: boolean;
  smallDesktop: boolean;
  tablet: boolean;
  mobile: boolean;
};

type Media = {
  mobileAndTablet: boolean;
  desktop: boolean;
} & BaseMedia;

const getMedia = (width): Media => {
  const baseMedia: BaseMedia = {
    largeDesktop: width > largeScreenBreakpoint,
    smallDesktop: width > tabletScreenBreakpoint && width <= largeScreenBreakpoint,
    tablet: width > mobileScreenBreakpoint && width <= tabletScreenBreakpoint,
    mobile: width <= mobileScreenBreakpoint
  };

  return {
    ...baseMedia,
    mobileAndTablet: baseMedia.tablet || baseMedia.mobile,
    desktop: baseMedia.largeDesktop || baseMedia.smallDesktop
  };
};

const MediaContext = createContext<Media>(getMedia(window.innerWidth));

const MediaProvider = ({ children }) => {
  const width = useWindowWidth();

  const [media, setMedia] = useState(getMedia(width));

  useEffect(() => {
    const newMedia = getMedia(width);

    if (
      newMedia.smallDesktop !== media.smallDesktop ||
      newMedia.largeDesktop !== media.largeDesktop ||
      newMedia.desktop !== media.desktop ||
      newMedia.mobile !== media.mobile ||
      newMedia.tablet !== media.tablet ||
      newMedia.mobileAndTablet !== media.mobileAndTablet
    ) {
      setMedia(newMedia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return <MediaContext.Provider value={media}>{children}</MediaContext.Provider>;
};

export default MediaProvider;

export const useMedia = () => useContext(MediaContext);
