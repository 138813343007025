import React from 'react';
import * as classNames from 'classnames';

type Props = {
  children?: any;
  style?: React.CSSProperties;
  className?: string;
  hidden?: boolean;
  noMargin?: boolean;
};

const Row: React.FC<Props> = ({ noMargin, ...props }) => {
  return (
    <div
      hidden={props.hidden}
      className={classNames('row', props.className, { ['margin-bottom-0']: noMargin })}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default Row;
