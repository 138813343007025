import {
  ItemDetails,
  CriterionDetails,
  Address,
  CodeList,
  Supplier,
  User,
  AttachmentState,
  Attachment
} from './commonTypes';

export interface Tender {
  tenderId: number;
  tenderStatus: number;
  name: string;
  keywords: string;
  currency: string;
  totExpPrice: number;
  mainPictureUrl: string;
  totExpPriceInUserCurr: number;
  industries: Array<CodeList<string>>;
  itemsDetails: ItemDetails[];
  criteriaDetails: CriterionDetails[];
  suppliers: Supplier[];
  attachments: AttachmentState;
  mustHaveConditions: MustHaveCondition[];
  addressSource: number;
  newDeliveryAddress: Address;
  proposalEndDate: string;
  startDate: string;
  duration: number;
  stepProgress: number;
  canceledReason?: string;
  announcements: Message[];
  isPublic: boolean;
  isFavorite: boolean;
  isVatIncluded: boolean;
  owner: User;
  totalActiveBidders: number;
  createdDate: Date;
}

export interface TenderTemplate {
  isDemo: boolean;
  name: string;
  currency: string;
  addressSource: number;
  deliveryAddress: Address;
  itemsDetails: ItemDetails[];
  criteriaDetails: CriterionDetails[];
  mustHaveConditions: MustHaveCondition[];
  duration: number;
  proposalEndDateTime: string;
  startDateTime: string;
  attachments: Attachment[];
}

export interface TenderBoardShowings {
  tenderId: number;
  tenderName: string;
  amount: number;
  createdDate: Date;
  currencyId: string;
  isOwner: boolean;
}

export interface TenderLightWeight {
  id: number;
  name: string;
  createDate: Date;
  mainPictureUrl: string;
  totalActiveBiddersCount: number;
  newAppliesCount: number | null;
  isFavorite: boolean;
  isDemo: boolean;
  currency: string;
  totalExpectedPrice: number;
  startDate: Date;
  isOwner: boolean;
  ownerName: string;
  ownerCompanyId: number;
  isPublic: boolean;
  supplierStatus: number | null;
  tenderStatus: number;
  keywords: string;
  industries: string;
}

export interface MustHaveCondition {
  id: number;
  text: string;
  hasAdditionalInfo: boolean;
}

export interface MustHaveConditionResponse {
  mhcId: number;
  response: string;
}

type Bid = 'Criterion' | 'Item';

export type SuppliersBidChange = {
  id: number;
  type: Bid;
  userName: string;
};

export type SuppliersItemBid = {
  userName: string;
  itemId: number;
  bid: string;
};

export type Message = {
  date: Date;
  text: string;
};

export type SuppliersCriterionBid = {
  userName: string;
  criteriaId: number;
  bid: string;
};

export enum TenderStatus {
  NEW = 0,
  SCHEDULED = 5,
  RFP_SUBMITTED = 10,
  RFP_ENDED = 11,
  SUBMITTED = 20,
  ENDED = 22,
  CANCELED = 50,
  FREEZE = 100
}
