import React from 'react';
import cs from 'classnames';

import { IconPosition } from '_types/commonTypes';

export type MaterialIconProps = {
  iconName: string;
  iconPosition?: IconPosition;
  iconClassName?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  innerRef?: ((instance: HTMLElement) => void) | React.RefObject<HTMLElement> | null;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const MaterialIcon: React.FC<MaterialIconProps> = ({
  iconName,
  iconPosition = IconPosition.NONE,
  iconClassName,
  disabled,
  innerRef,
  ...restProps
}) => {
  return (
    <i
      ref={innerRef}
      className={cs(
        'material-icons',
        {
          ['left']: iconPosition === IconPosition.LEFT,
          ['right']: iconPosition === IconPosition.RIGHT,
          ['disabled']: disabled
        },
        iconClassName
      )}
      {...restProps}
    >
      {iconName}
    </i>
  );
};

export default MaterialIcon;
