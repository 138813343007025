import { User, Order, SortDirection } from '_types/commonTypes';
import { ContactsAction, IContactsActionTypes } from '_actions/contactsActions';
import { initialUserState } from './partialInitialStates';
// import { initialUserState, initialCompanyState } from './partialInitialStates';

export interface IState {
  isSorting: boolean;
  sort: Order;
  recordsPerPage: number;
  loadedPages: number[];
  totalCount: number;
  list: User[];
  filter: string;
  reload: boolean;
}

export const initialState: IState = {
  isSorting: false,
  sort: { direction: SortDirection.DESC, columnId: 'id' },
  recordsPerPage: 0,
  loadedPages: [],
  totalCount: 0,
  list: [],
  filter: '',
  reload: false
};

export function reducer(state: IState = initialState, action: ContactsAction): IState {
  switch (action.type) {
    case IContactsActionTypes.LOAD_CONTACTS: {
      const { recordsPerPage, page } = action.payload;
      const clearCache = recordsPerPage !== state.recordsPerPage || state.isSorting || state.reload;

      return {
        ...state,
        recordsPerPage,
        loadedPages: !clearCache ? [...state.loadedPages, page] : [page],
        totalCount: action.payload.totalCount,
        list: !clearCache
          ? [
              ...state.list.filter((i, idx) => idx < recordsPerPage * (page - 1)),
              ...action.payload.users,
              ...state.list.filter((_, idx) => idx > page * recordsPerPage)
            ]
          : !state.isSorting
          ? [...action.payload.users]
          : [...new Array(recordsPerPage * page - recordsPerPage).fill(initialUserState), ...action.payload.users],
        reload: false
      };
    }
    case IContactsActionTypes.ADD_CONTACT:
      return {
        ...state,
        totalCount: state.totalCount + 1,
        list: [action.payload.user, ...state.list]
      };
    case IContactsActionTypes.EDIT_CONTACT: {
      const contactsList = state.list.slice();
      const contactIndex = contactsList.findIndex(c => c.userName === action.payload.user.userName);

      if (contactIndex > -1) {
        contactsList[contactIndex] = action.payload.user;
      }

      return {
        ...state,
        list: contactsList
      };
    }
    case IContactsActionTypes.REMOVE_CONTACT:
      return {
        ...state,
        totalCount: state.totalCount - 1,
        list: state.list.filter(u => u.userName !== action.payload.username)
      };
    case IContactsActionTypes.SORT_REQUEST_START: {
      return {
        ...state,
        isSorting: true,
        sort: action.payload.sort
      };
    }
    case IContactsActionTypes.SORT_REQUEST_END: {
      return {
        ...state,
        isSorting: false
      };
    }
    case IContactsActionTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
        reload: true
      };
    }
    default: {
      return state;
    }
  }
}
