import React from 'react';
import cs from 'classnames';

export type CheckboxProps = {
  reference?: (e) => void;
  name: string;
  checkedValue?: boolean;
  label: any;
  checkboxClassType?: string;
  className?: string;
  wrapperClassName?: string;
  checkboxClassNames?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
  wrapperStyle?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  reference,
  name,
  checkedValue,
  label,
  checkboxClassType,
  checkboxClassNames,
  className,
  wrapperClassName,
  style,
  wrapperStyle,
  readOnly,
  onChange,
  onBlur
}) => {
  return (
    <label className={wrapperClassName} style={wrapperStyle}>
      <input
        ref={reference}
        name={name}
        type="checkbox"
        className={cs(checkboxClassType, checkboxClassNames)}
        checked={checkedValue}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
      />
      <span className={cs('checkbox-label', className)} style={style}>
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
