import React, { Fragment, FC } from 'react';

import Row from '_components/grid/Row';
import MustHaveConditionsList from './lists/MustHaveConditionsList';
import FormButton from '_components/buttons/FormButton';
import { IconPosition } from '_types/commonTypes';
import { useLocalization } from '_libs/contexts/LocalizationContext';

import cardStyles from '_components/cards/Card.module.scss';
import { MustHaveCondition } from '_types/tenderTypes';

type Props = {
  mustHaveConditions: MustHaveCondition[];
  isFocused: boolean[];
  isEditable: boolean;
  onAddConditionClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onConditionChange: (index: number, condition: MustHaveCondition) => void;
  onRemoveCondition: (index: number) => void;
  onInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  onComfirmCondition: (index: number) => void;
  onConditionBlur: (index: number) => void;
  onFocusCondition: (index: number) => void;
};

const MustHaveConditions: FC<Props> = ({ onAddConditionClick, isEditable, ...restProps }) => {
  const localization = useLocalization();

  return (
    <Fragment>
      <Row>
        <MustHaveConditionsList isEditable={isEditable} {...restProps} />
      </Row>
      <div className={cardStyles.footer}>
        <FormButton
          onClick={onAddConditionClick}
          label={localization.auct_params_add_condition_button}
          iconPosition={IconPosition.LEFT}
          iconName="control_point"
          disabled={!isEditable}
        />
      </div>
    </Fragment>
  );
};

export default MustHaveConditions;
