import { Currency, CodeList, Industry, Country, AddressCodeList, AddressSource } from '_types/commonTypes';
import { CodeListAction, IActionTypes } from '_actions/codeListActions';
import { initialDeliveryAddressState } from './partialInitialStates';

export interface IState {
  currencies: Currency[];
  unitsOfMeasurement: Array<CodeList<string>>;
  unitsOfTenderCriterion: Array<CodeList<string>>;
  industries: Industry[];
  tenderStatuses: CodeList<number>[];
  countries: Array<Country>;
  addresses: AddressCodeList[];
  suppliersInvitationStatuses: Array<CodeList<number>>;
  winningTreshold: Array<CodeList<number>>;
  tenderCodelistsLoaded: boolean;
  canadaProvinces: Array<CodeList<number>>;
  usaStates: Array<CodeList<number>>;
}

export const initialState: IState = {
  currencies: [],
  unitsOfMeasurement: [],
  unitsOfTenderCriterion: [],
  industries: [],
  tenderStatuses: [],
  countries: [],
  addresses: [
    {
      addressSource: AddressSource.NEW_DELIVERY,
      address: initialDeliveryAddressState
    }
  ],
  suppliersInvitationStatuses: [],
  winningTreshold: [
    { id: 0, name: 'Lowest wins' },
    { id: 1, name: 'Highest wins' }
  ],
  tenderCodelistsLoaded: false,
  canadaProvinces: [],
  usaStates: []
};

export function reducer(state: IState = initialState, action: CodeListAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload.currencies
      };
    case IActionTypes.LOAD_UNITS_OF_MEASUREMENT_SUCCESS:
      return {
        ...state,
        unitsOfMeasurement: action.payload.unitsOfMeasurement
      };
    case IActionTypes.LOAD_UNITS_OF_TENDER_CRITERION_SUCCESS:
      return {
        ...state,
        unitsOfTenderCriterion: action.payload.unitsOfTenderCriterion
      };
    case IActionTypes.LOAD_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: action.payload.industries
      };
    case IActionTypes.LOAD_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries
      };
    case IActionTypes.LOAD_CANADA_PROVINCES_SUCCESS:
      return {
        ...state,
        canadaProvinces: action.payload.provinces
      };
    case IActionTypes.LOAD_USA_STATES_SUCCESS:
      return {
        ...state,
        usaStates: action.payload.states
      };
    case IActionTypes.LOAD_TENDER_STATUSES_SUCCESS:
      return {
        ...state,
        tenderStatuses: action.payload.statuses
      };
    case IActionTypes.LOAD_ADDRESSES_CODELIST_SUCCESS:
      return {
        ...state,
        addresses: [...action.payload.addresses, ...state.addresses]
      };
    case IActionTypes.LOAD_SUPPLIERS_INVITATION_STATUSES_SUCCESS:
      return {
        ...state,
        suppliersInvitationStatuses: [...state.suppliersInvitationStatuses, ...action.payload.statuses]
      };
    case IActionTypes.UPDATE_ADDRESS_CODELIST_SUCCESS: {
      const addresses = state.addresses.slice();
      const { address, index } = action.payload;

      addresses[index] = { ...addresses[index], address: address };

      return {
        ...state,
        addresses: addresses
      };
    }
    case IActionTypes.ADD_NEW_ADDRESS_SUCCESS: {
      const addresses = state.addresses.slice();

      return {
        ...state,
        addresses: [...addresses, action.payload.address].sort((a, b) => a.addressSource - b.addressSource)
      };
    }
    case IActionTypes.RESET_ADDRESSES_SUCCES:
      return {
        ...state,
        addresses: [
          ...initialState.addresses,
          ...state.addresses.filter(a => a.addressSource === AddressSource.CURRENT_LOCATION)
        ]
      };
    case IActionTypes.TENDER_CODELISTS_LOADED_SUCCESS:
      return {
        ...state,
        tenderCodelistsLoaded: true
      };
    default:
      return state;
  }
}
