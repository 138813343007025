import React from 'react';
import cx from 'classnames';

import styles from './Maintenance.module.scss';
import maintImg from '_assets/images/maintenance.png';

const Maintenance: React.FC = () => {
  return (
    <div className={cx('centered-flex-wrapper', styles.flexWrapper)}>
      <div className={styles.container}>
        <img src={maintImg} className={styles.image} />
        <h3>Server is under maintenance</h3>
        <h6>Please be patient</h6>
      </div>
    </div>
  );
};

export default Maintenance;
