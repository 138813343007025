import { CommonAction, IActionTypes } from '_actions/commonActions';

export interface IState {
  localization: any;
  pendingAuctionInvitationsCount: number;
  pendingPublicBiddersCount: number;
}

export const initialState: IState = {
  localization: [],
  pendingAuctionInvitationsCount: 0,
  pendingPublicBiddersCount: 0
};

export function reducer(state: IState = initialState, action: CommonAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_LOCALIZATION:
      return {
        ...state,
        localization: action.payload.localization
      };
    case IActionTypes.SET_PENDING_AUCTION_INV_COUNT:
      return {
        ...state,
        pendingAuctionInvitationsCount: action.payload.count
      };
    case IActionTypes.SET_PENDING_PUBLIC_BIDDERS_COUNT:
      return {
        ...state,
        pendingPublicBiddersCount: action.payload.count
      };
    default:
      return state;
  }
}
