import React, { Fragment, FC, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import MustHaveConditionsListRow from './MustHaveConditionsListRow';
import { MustHaveCondition } from '_types/tenderTypes';

const mandatoryConditionsCount = 1;

type Props1 = {
  mustHaveConditions: MustHaveCondition[];
  isFocused: boolean[];
  isEditable: boolean;
  onConditionChange: (index: number, condition: MustHaveCondition) => void;
  onRemoveCondition: (index: number) => void;
  onComfirmCondition: (index: number) => void;
  onInputKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
  onConditionBlur: (index: number) => void;
  onFocusCondition: (index: number) => void;
};

const MustHaveConditionsList: FC<Props1> = ({
  mustHaveConditions,
  onConditionChange,
  onRemoveCondition,
  onComfirmCondition,
  onInputKeyDown,
  onConditionBlur,
  onFocusCondition,
  isFocused,
  isEditable
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [mustHaveConditions]);

  return (
    <Fragment>
      <ul
        className={mustHaveConditions.length === 0 ? 'hide' : 'collection with-header'}
        style={{ marginTop: 0, marginBottom: 0, borderLeft: 'none', borderRight: 'none' }}
      >
        {mustHaveConditions.map((condition, index) => {
          return (
            <MustHaveConditionsListRow
              key={index}
              index={index}
              isMandatory={index < mandatoryConditionsCount}
              condition={condition}
              isEditable={isEditable}
              isFocused={isFocused[index]}
              onConditionChange={e => {
                if (e.target.type === 'checkbox') {
                  onConditionChange(index, {
                    id: condition.id,
                    text: condition.text,
                    hasAdditionalInfo: !condition.hasAdditionalInfo
                  });
                } else {
                  onConditionChange(index, {
                    id: condition.id,
                    text: e.target.value,
                    hasAdditionalInfo: condition.hasAdditionalInfo
                  });
                }
              }}
              onConfirmCondition={() => onComfirmCondition(index)}
              onEnterConfirmCondition={() => onComfirmCondition(index)}
              onRemoveCondition={() => onRemoveCondition(index)}
              onFocusCondition={() => onFocusCondition(index)}
              onKeyDown={e => onInputKeyDown(e, index)}
              onBlur={e => {
                e.stopPropagation();
                onConditionBlur(index);
              }}
            />
          );
        })}
      </ul>
    </Fragment>
  );
};

export default MustHaveConditionsList;
