import { Persistance } from '_types/commonTypes';

export const JWT_TOKEN = 'JWT_TOKEN';
export const REFRESH_TOKEN = 'jid';

class TokenStorageManager {
  storage: Storage;
  token: string;
  refresh_token: string;

  constructor(storage: Storage) {
    this.storage = storage;
    this.token = this.storage.getItem(JWT_TOKEN) || '';
    this.refresh_token = this.storage.getItem(REFRESH_TOKEN) || '';
  }

  setJwtToken(token: string) {
    this.storage.setItem(JWT_TOKEN, token);
    this.token = token;
  }

  setRefreshToken(token: string) {
    this.storage.setItem(REFRESH_TOKEN, token);
    this.refresh_token = token;
  }

  getJwtToken = (): string => {
    return this.token;
  };

  getRefreshToken = (): string => {
    return this.refresh_token;
  };

  removeJwtToken() {
    this.storage.removeItem(JWT_TOKEN);
    this.token = '';
  }

  removeRefreshTOken() {
    this.storage.removeItem(REFRESH_TOKEN);
    this.refresh_token = '';
  }

  removeTokens() {
    this.removeJwtToken();
    this.removeRefreshTOken();
  }
}

export default TokenStorageManager;

export const getTokenPersistance = (): Persistance => {
  let persistance: Persistance;

  if (localStorage.getItem(REFRESH_TOKEN)) {
    persistance = Persistance.LOCAL;
  } else if (sessionStorage.getItem(REFRESH_TOKEN)) {
    persistance = Persistance.SESSION;
  } else {
    persistance = Persistance.NONE;
  }

  return persistance;
};
