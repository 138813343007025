import { Dispatch } from 'redux';

import {
  loadCurrenciesCodeList,
  loadUnitsOfMeasurementCodeList,
  loadUnitsOfTenderCriterionCodeList,
  loadIndustriesCodeList,
  loadAddressesCodeList,
  loadCountriesCodeList,
  loadSuppiersInvitationStatusCodeList,
  tenderCodelistsLoaded,
  resetAddressesCodeList,
  loadTenderStatusesCodeList,
  loadCanadaProvinces,
  loadUSAStates
} from '_actions/codeListActions';
import { loadMyTenders } from '_actions/myTendersAction';
import { signalrConnection } from '_actions/connectionActions';
import history from './history';
import { routes } from '_constants/routesConstants';
import { loadNotifications } from '_actions/notificationActions';
import { NOTIFICATIONS_PER_PAGE } from '_components/notifications/Notifications';
import { loadUserDetails } from '_actions/userActions';
import { SortDirection } from '_types/commonTypes';

async function dispatchAllStartupActions(dispatch: Dispatch<any>, token: string) {
  dispatch(loadNotifications(1, NOTIFICATIONS_PER_PAGE));
  await loadCodeLists(dispatch);

  if (history.location.pathname !== routes.MY_AUCTIONS) {
    // pre fetch my tenders so they're available for last unfinished tender & invite to tender modal
    dispatch(loadMyTenders(1, 30, 'owner;allActiveInactive', { columnId: '', direction: SortDirection.DESC }, ''));
  }
  dispatch(loadUserDetails());
  dispatch(signalrConnection.start(token));
}

export async function loadCodeLists(dispatch: Dispatch<any>, loadAddresses: boolean = true) {
  await Promise.all([
    dispatch(loadCurrenciesCodeList()),
    dispatch(loadUnitsOfMeasurementCodeList()),
    dispatch(loadUnitsOfTenderCriterionCodeList()),
    dispatch(loadIndustriesCodeList()),
    // Prevents from appending to address codelist when server crash and user is not properly loggedout
    dispatch(resetAddressesCodeList()),
    await dispatch(loadCountriesCodeList()),
    loadAddresses ? dispatch(loadAddressesCodeList()) : null,
    dispatch(loadCanadaProvinces()),
    dispatch(loadUSAStates()),
    dispatch(loadTenderStatusesCodeList()),
    dispatch(loadSuppiersInvitationStatusCodeList())
  ]);
  dispatch(tenderCodelistsLoaded());
}

export const actionDispatcher = {
  dispatchAllStartupActions
};
