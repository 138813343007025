import axios from 'axios';

import API_URL from '_constants/apiConstants';

const SETTINGS_URL = '/api/settings';

const URL = API_URL + SETTINGS_URL;

/**
 * returns servers date time
 */
const getServerTime = async (): Promise<string> => {
  try {
    const res = await axios.get(`${URL}/GetServerTime`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

const fetchNewsletterStatus = () => {
  return axios.get(`${URL}/GetNewsLetterStatus`);
};

const subscribeForNewsletter = () => {
  return axios.put(`${URL}/SubscribeForNewsLetter`);
};

const unsubscribeForNewsletter = () => {
  return axios.put(`${URL}/UnSubscribeForNewsLetter`);
};

const saveSurvey = (answer: string) => {
  return axios.post(`${URL}/SaveSurvey`, {}, { params: { surveyAnswer: answer } });
};

export const settingsApi = {
  getServerTime,
  fetchNewsletterStatus,
  subscribeForNewsletter,
  unsubscribeForNewsletter,
  saveSurvey
};
