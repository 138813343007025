import axios from 'axios';

import API_URL from '_constants/apiConstants';
import { LocalizationText, Order } from '_types/commonTypes';
import { b64EncodeUnicode } from '_libs/utils';

const ADMIN_URL = '/api/admin';
const URL = API_URL + ADMIN_URL;

const checkLocalizationIntegrity = () => {
  return axios.get(URL + '/CheckLocalizationsIntegrity');
};

const fetchUsers = (pageNo: number, recordsPerPage: number, sort: Order) => {
  return axios.get(URL + '/GetUserList', {
    params: { pageNo, recordsPerPage, orderBy: sort.columnId, order: sort.direction }
  });
};

const fetchProfiles = (pageNo: number, recordsPerPage: number) => {
  return axios.get(URL + '/GetUserProfiles', { params: { pageNo, recordsPerPage } });
};

const fetchLocalizationTexts = () => {
  return axios.get(URL + '/GetLocalizationTexts');
};

const fetchOnlyFeLocalizationTexts = () => {
  return axios.get(URL + '/GetFeLocalizationTexts');
};

const addLocalizationText = (text: LocalizationText) => {
  const encodedText: LocalizationText = {
    ...text,
    value: b64EncodeUnicode(text.value),
    correction: b64EncodeUnicode(text.correction)
  };

  return axios.post(URL + '/AddLocalizationText', { ...encodedText, newCorrection: false });
};

const editLocalizationText = (text: LocalizationText) => {
  const encodedText: LocalizationText = {
    ...text,
    value: b64EncodeUnicode(text.value),
    correction: b64EncodeUnicode(text.correction)
  };

  return axios.post(URL + '/EditLocalizationText', { ...encodedText, newCorrection: false });
};

const removeLocalizationText = (key: string) => {
  return axios.delete(URL + '/DeleteLocalizationText', { params: { key } });
};

const giveTrial = (username, days = 30) => {
  return axios.post(URL + '/GiveTrialToUser', {}, { params: { username, days } });
};

const logErr = (message: string, toEmail: boolean = false) => {
  return axios.post(URL + '/SaveClientErrorLog', { Error: message, ToEmail: toEmail });
};

const deleteUser = (username: string) => {
  return axios.delete(URL + '/DeleteUserFromSystem', { params: { user: username } });
};

export default {
  fetchUsers,
  fetchProfiles,
  fetchLocalizationTexts,
  addLocalizationText,
  editLocalizationText,
  removeLocalizationText,
  fetchOnlyFeLocalizationTexts,
  logErr,
  giveTrial,
  checkLocalizationIntegrity,
  deleteUser
};
