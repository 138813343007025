import { TenderLightWeight } from '_types/tenderTypes';
import { MyTendersAction, IMyTendersActionTypes } from '_actions/myTendersAction';
import { Order, SortDirection, SuppliersTenderStatus } from '_types/commonTypes';
import { myAuctionsTableColumns } from 'myAuctions/components/tables/MyAuctionsTable';

export interface IState {
  isLoaded: boolean;
  isSorting: boolean;
  sort: Order;
  isFiltering: boolean;
  filter: string;
  search: string;
  totalCount: number;
  tenders: TenderLightWeight[];
}

export const initialState: IState = {
  isLoaded: false,
  isSorting: false,
  sort: { direction: SortDirection.DESC, columnId: 'default' },
  isFiltering: false,
  filter: 'all;allActiveInactive',
  search: '',
  totalCount: 0,
  tenders: []
};

export function reducer(state: IState = initialState, action: MyTendersAction): IState {
  switch (action.type) {
    case IMyTendersActionTypes.SAVE_NEW_TENDER_LW_SUCCESS:
      return {
        ...state,
        tenders: [action.payload.tenderLW, ...state.tenders]
      };
    case IMyTendersActionTypes.UPDATE_TENDER_LW_SUPPLIERS_STATUS_SUCCESS:
    case IMyTendersActionTypes.UPDATE_TENDER_LW_STATUS_SUCCESS:
    case IMyTendersActionTypes.UPDATE_TENDER_LW_SUCCESS: {
      const tenders = state.tenders.slice();
      tenders[action.payload.index] = action.payload.tenderLW;

      return {
        ...state,
        tenders: tenders
      };
    }
    case IMyTendersActionTypes.LOAD_MY_TENDERS_SUCCESS:
      return {
        ...state,
        tenders: action.payload.tendersLW,
        totalCount: action.payload.totalCount,
        isLoaded: true
      };
    case IMyTendersActionTypes.REMOVE_TENDER_SUCCESS: {
      return {
        ...state,
        tenders: [...state.tenders.filter(t => t.id !== action.payload.tenderId)]
      };
    }
    case IMyTendersActionTypes.TOGGLE_FAVORITE_TENDER: {
      let tenders = state.tenders.slice();
      const index = tenders.findIndex(t => t.id === action.payload.tenderId);

      if (index > -1) {
        if (
          // We need to remove when unfavoriting tender where supp is not participating just got it added as favorite
          tenders[index].supplierStatus === SuppliersTenderStatus.FAVORITE &&
          tenders[index].isFavorite
        ) {
          tenders = tenders.filter((_, i) => i !== index);
        } else {
          tenders[index] = { ...tenders[index], isFavorite: !tenders[index].isFavorite };
        }
      }

      return {
        ...state,
        tenders
      };
    }
    case IMyTendersActionTypes.SORT_REQUEST_START: {
      return {
        ...state,
        isSorting: true,
        sort: action.payload.sort
      };
    }
    case IMyTendersActionTypes.SORT_REQUEST_END: {
      return {
        ...state,
        isSorting: false
      };
    }
    case IMyTendersActionTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      };
    }
    case IMyTendersActionTypes.SET_SEARCH: {
      return {
        ...state,
        search: action.payload.search
      };
    }
    case IMyTendersActionTypes.FILTER_REQUEST_START: {
      return {
        ...state,
        isFiltering: true,
        filter: action.payload.filter
      };
    }
    case IMyTendersActionTypes.FILTER_REQUEST_END: {
      return {
        ...state,
        isFiltering: false
      };
    }
    case IMyTendersActionTypes.CLEAR:
      return initialState;
    default:
      return state;
  }
}
