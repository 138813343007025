import axios from 'axios';

export default function setDefaultAuthorizationHeader(token): void {
  const authorization: string = 'Authorization';

  if (token) {
    axios.defaults.headers.common[authorization] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common[authorization];
  }
}
