import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';

import * as tenderService from '_api/tenderApi';
import { Tender, TenderLightWeight, TenderStatus } from '_types/tenderTypes';
import { IApplicationState } from 'core/reducer';
import { fetchMyTendersBE } from '_api/tenderApi';
import { mapTendersLightWeight, mapTenderLWfromTender, mapTenderLWfromTenderLWDTO } from '_mappers/tenderMapper';
import { addTenderToFavoritesBE } from '_api/tenderSupplierApi';
import { ShowSuccessToast, ShowErrorToast } from '_components/toasts/toast';
import { Order, IAction } from '_types/commonTypes';
import { TenderLightWeightDTO } from '_types/backendTypes';
import { initialState } from '_reducers/myTendersReducer';

export enum IMyTendersActionTypes {
  SAVE_NEW_TENDER_LW_SUCCESS = '[MY_TENDERS] SAVE_NEW_TENDER_LW_SUCCESS',
  UPDATE_TENDER_LW_STATUS_SUCCESS = '[MY_TENDERS] UPDATE_TENDER_LW_STATUS_SUCCESS',
  UPDATE_TENDER_LW_SUPPLIERS_STATUS_SUCCESS = '[MY_TENDERS] UPDATE_TENDER_LW_SUPPLIERS_STATUS_SUCCESS',
  UPDATE_TENDER_LW_SUCCESS = '[MY_TENDERS] UPDATE_TENDER_LW_SUCCESS',
  LOAD_MY_TENDERS_SUCCESS = '[MY_TENDERS] LOAD_MY_TENDERS_SUCCESS',
  REMOVE_TENDER_SUCCESS = '[MY_TENDERS] REMOVE_TENDER_SUCCESS',
  SORT_REQUEST_START = '[MY_TENDERS] SORT_REQUEST_START',
  SORT_REQUEST_END = '[MY_TENDERS] SORT_REQUEST_END',
  FILTER_REQUEST_START = '[MY_TENDERS] FILTER_REQUEST_START',
  FILTER_REQUEST_END = '[MY_TENDERS] FILTER_REQUEST_END',
  SET_FILTER = '[MY_TENDERS] SET FILTER',
  SET_SEARCH = '[MY_TENDERS] SET SEARCH',
  TOGGLE_FAVORITE_TENDER = '[MY_TENDERS] TOGGLE_FAVORITE',
  CLEAR = '[MY_TENDERS] CLEAR'
}

interface ISaveNewTenderSuccessAction {
  type: IMyTendersActionTypes.SAVE_NEW_TENDER_LW_SUCCESS;
  payload: { tenderLW: TenderLightWeight };
}

interface IUpdateTenderSuccess {
  type: IMyTendersActionTypes.UPDATE_TENDER_LW_SUCCESS;
  payload: { index: number; tenderLW: TenderLightWeight };
}

interface IUpdateTenderStatusSuccess {
  type: IMyTendersActionTypes.UPDATE_TENDER_LW_STATUS_SUCCESS;
  payload: { index: number; tenderLW: TenderLightWeight };
}

interface ISetFilterAction extends IAction<IMyTendersActionTypes.SET_FILTER, { filter: string }> {}
interface ISetSearchAction extends IAction<IMyTendersActionTypes.SET_SEARCH, { search: string }> {}

interface IUpdateTenderSuppliersStatusSuccess {
  type: IMyTendersActionTypes.UPDATE_TENDER_LW_SUPPLIERS_STATUS_SUCCESS;
  payload: { index: number; tenderLW: TenderLightWeight };
}

interface ILoadMyTendersSuccessAction {
  type: IMyTendersActionTypes.LOAD_MY_TENDERS_SUCCESS;
  payload: { tendersLW: TenderLightWeight[]; totalCount: number };
}

interface IRemoveTenderSuccessAction {
  type: IMyTendersActionTypes.REMOVE_TENDER_SUCCESS;
  payload: { tenderId: number };
}

interface ISortRequestStartAction {
  type: IMyTendersActionTypes.SORT_REQUEST_START;
  payload: { sort: Order };
}

interface ISortRequestEndAction {
  type: IMyTendersActionTypes.SORT_REQUEST_END;
}

interface IFilterRequestStartAction {
  type: IMyTendersActionTypes.FILTER_REQUEST_START;
  payload: { filter: string };
}

interface IFilterRequestEndAction {
  type: IMyTendersActionTypes.FILTER_REQUEST_END;
}

interface IToggleFavoriteAction {
  type: IMyTendersActionTypes.TOGGLE_FAVORITE_TENDER;
  payload: { tenderId: number };
}

interface IClearAction {
  type: IMyTendersActionTypes.CLEAR;
}

/**
 * This method is called to save tender in my tenders, it determines wheter to save tender
 * as a new tender or update one, this is mainly to be able to get the last tender, without
 * need to call loadTenders from backend
 * @param tenderLW - tender to be saved in my tenders
 */
function saveTenderLW(tender: Tender): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const tenderLW = mapTenderLWfromTender(tender);
    const tenderIndex = getState().myTenders.tenders.findIndex(x => x.id === tenderLW.id);

    if (tenderIndex > -1) {
      dispatch(updateTenderSuccess(tenderIndex, tenderLW));
    } else {
      dispatch(saveNewTenderSuccess(tenderLW));
    }
  };
}

function addTenderLW(dto: TenderLightWeightDTO): ThunkAction<void, any, null, any> {
  return dispatch => {
    const tenderLw = mapTenderLWfromTenderLWDTO(dto);

    dispatch(saveNewTenderSuccess(tenderLw));
  };
}

function addTenderToFavorite(tenderId: number, localization: any) {
  return addTenderToFavoritesBE(tenderId)
    .then(() => ShowSuccessToast(localization.add_tender_to_favorites_success_toast))
    .catch(() => ShowErrorToast(localization.add_tender_to_favorites_error_toast));
}

function updateTenderSuccess(index: number, tenderLW: TenderLightWeight): IUpdateTenderSuccess {
  return {
    type: IMyTendersActionTypes.UPDATE_TENDER_LW_SUCCESS,
    payload: { index, tenderLW }
  };
}

function updateTenderLwActiveBidders(tenderId: number, count: number): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const myTenders = getState().myTenders.tenders;

    const index = myTenders.findIndex(t => t.id === tenderId);

    if (index > -1) {
      dispatch(updateTenderSuccess(index, { ...myTenders[index], totalActiveBiddersCount: count }));
    }
  };
}

function updateTenderLwNewApplies(tenderId: number, count: number): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const myTenders = getState().myTenders.tenders;

    const index = myTenders.findIndex(t => t.id === tenderId);

    if (index > -1) {
      dispatch(updateTenderSuccess(index, { ...myTenders[index], newAppliesCount: count }));
    }
  };
}

function updateTenderLwVisibility(tenderId: number, visibility: boolean): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const myTenders = getState().myTenders.tenders;

    const index = myTenders.findIndex(t => t.id === tenderId);

    if (index > -1) {
      dispatch(updateTenderSuccess(index, { ...myTenders[index], isPublic: visibility }));
    }
  };
}

function updateTenderLwStatus(tenderId: number, status: TenderStatus): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const myTenders = getState().myTenders.tenders;

    const index = myTenders.findIndex(t => t.id === tenderId);

    if (index > -1) {
      dispatch(updateTenderLwStatusSuccess(index, { ...myTenders[index], tenderStatus: status }));
    }
  };

  function updateTenderLwStatusSuccess(index: number, tenderLW: TenderLightWeight): IUpdateTenderStatusSuccess {
    return {
      type: IMyTendersActionTypes.UPDATE_TENDER_LW_STATUS_SUCCESS,
      payload: { index, tenderLW }
    };
  }
}

function updateTenderLwSuppliersStatus(tenderId: number, statusId: number): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const myTenders = getState().myTenders.tenders;

    const index = myTenders.findIndex(t => t.id === tenderId);

    if (index > -1) {
      dispatch(
        updateTenderLwSuppliersStatusSuccess(index, {
          ...myTenders[index],
          supplierStatus: statusId
        })
      );
    }
  };

  function updateTenderLwSuppliersStatusSuccess(
    index: number,
    tenderLW: TenderLightWeight
  ): IUpdateTenderSuppliersStatusSuccess {
    return {
      type: IMyTendersActionTypes.UPDATE_TENDER_LW_SUPPLIERS_STATUS_SUCCESS,
      payload: { index, tenderLW }
    };
  }
}

/**
 * Gets last unfinished tender from backend and saves it to my tenders
 */
function loadLastUnfinishedTenderLW(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService
      .fetchLastUnfinishedTenderBE()
      .then(res => mapTenderLWfromTenderLWDTO(res.data))
      .then(tenderLW => dispatch(saveNewTenderSuccess(tenderLW)))
      .catch(error => {
        throw error;
      });
  };
}

function saveNewTenderSuccess(tenderLW: TenderLightWeight): ISaveNewTenderSuccessAction {
  return {
    type: IMyTendersActionTypes.SAVE_NEW_TENDER_LW_SUCCESS,
    payload: { tenderLW }
  };
}

function sortMyTenders(
  currPage: number,
  pageLimit: number,
  sort: Order
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch: Dispatch, getState: () => IApplicationState) => {
    const { filter, search } = getState().myTenders;

    dispatch(sortRequestStart(sort));

    return dispatch<any>(loadMyTenders(currPage, pageLimit, filter, sort, search)).finally(() => {
      dispatch(sortRequestEnd());
    });
  };

  function sortRequestStart(sort: Order): ISortRequestStartAction {
    return {
      type: IMyTendersActionTypes.SORT_REQUEST_START,
      payload: { sort }
    };
  }

  function sortRequestEnd(): ISortRequestEndAction {
    return {
      type: IMyTendersActionTypes.SORT_REQUEST_END
    };
  }
}

function setMyTendersFilter(filter: string): ISetFilterAction {
  return { type: IMyTendersActionTypes.SET_FILTER, payload: { filter } };
}

function resetMyTendersFilter(): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const { search, filter } = getState().myTenders;

    if (filter !== initialState.filter) {
      dispatch(clearMyTenders());
    }

    if (search) {
      dispatch(setMyTendersSearch(''));
    }
  };
}

function setMyTendersSearch(search: string): ISetSearchAction {
  return { type: IMyTendersActionTypes.SET_SEARCH, payload: { search } };
}

function filterMyTendersByName(pageLimit: number, search: string): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch: Dispatch, getState: () => IApplicationState) => {
    const { sort, search, filter } = getState().myTenders;

    dispatch(filterRequestStart(filter));

    return dispatch<any>(loadMyTenders(1, pageLimit, filter, sort, search)).finally(() => {
      dispatch(filterRequestEnd());
    });
  };
}

function filterMyTenders(
  currPage: number,
  pageLimit: number,
  filter: string
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch: Dispatch, getState: () => IApplicationState) => {
    const { sort, search } = getState().myTenders;

    dispatch(filterRequestStart(filter));

    return dispatch<any>(loadMyTenders(currPage, pageLimit, filter, sort, search)).finally(() => {
      dispatch(filterRequestEnd());
    });
  };
}

function filterRequestStart(filter: string): IFilterRequestStartAction {
  return {
    type: IMyTendersActionTypes.FILTER_REQUEST_START,
    payload: { filter }
  };
}

function filterRequestEnd(): IFilterRequestEndAction {
  return {
    type: IMyTendersActionTypes.FILTER_REQUEST_END
  };
}

/**
 * Loads tenders from backend in Leight weight form
 */
function loadMyTenders(currentPage: number, pageLimit: number, filter: string, sort: Order, search: string) {
  return dispatch => {
    return fetchMyTendersBE(currentPage, pageLimit, filter, sort, search)
      .then(res => ({
        list: mapTendersLightWeight(res.data.MyAuctions),
        totalCount: res.data.TotalResults
      }))
      .then(res => dispatch(success(res.list, res.totalCount)))
      .catch(error => {
        throw error;
      });
  };

  function success(tendersLW: TenderLightWeight[], totalCount: number): ILoadMyTendersSuccessAction {
    return {
      type: IMyTendersActionTypes.LOAD_MY_TENDERS_SUCCESS,
      payload: { tendersLW, totalCount }
    };
  }
}

function removeTender(tenderId: number): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return tenderService
      .removeTender(tenderId)
      .then(res => {
        dispatch(success(tenderId));
        return res.data;
      })
      .catch(error => {
        throw error;
      });
  };

  function success(tenderId: number): IRemoveTenderSuccessAction {
    return {
      type: IMyTendersActionTypes.REMOVE_TENDER_SUCCESS,
      payload: { tenderId }
    };
  }
}

function toggleFavoriteMyTender(tenderId: number, isFavorite: boolean): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return addTenderToFavoritesBE(tenderId, isFavorite).then(() => {
      dispatch(success(tenderId));
    });
  };

  function success(tenderId: number): IToggleFavoriteAction {
    return {
      type: IMyTendersActionTypes.TOGGLE_FAVORITE_TENDER,
      payload: { tenderId }
    };
  }
}

function clearMyTenders() {
  return {
    type: IMyTendersActionTypes.CLEAR
  };
}

export type MyTendersAction =
  | ISaveNewTenderSuccessAction
  | IUpdateTenderSuccess
  | IUpdateTenderStatusSuccess
  | IUpdateTenderSuppliersStatusSuccess
  | ILoadMyTendersSuccessAction
  | IRemoveTenderSuccessAction
  | ISortRequestStartAction
  | ISortRequestEndAction
  | IFilterRequestStartAction
  | IFilterRequestEndAction
  | IClearAction
  | IToggleFavoriteAction
  | ISetFilterAction
  | ISetSearchAction;

export {
  saveTenderLW,
  addTenderLW,
  addTenderToFavorite,
  loadMyTenders,
  sortMyTenders,
  filterMyTenders,
  loadLastUnfinishedTenderLW,
  removeTender,
  updateTenderLwStatus,
  updateTenderLwVisibility,
  updateTenderLwSuppliersStatus,
  updateTenderLwActiveBidders,
  updateTenderLwNewApplies,
  clearMyTenders,
  toggleFavoriteMyTender,
  setMyTendersSearch,
  setMyTendersFilter,
  resetMyTendersFilter as resetMyTendersSearch,
  filterMyTendersByName
};
