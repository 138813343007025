import { NotificationDTO } from '_types/backendTypes';
import { Notification } from '_types/commonTypes';

export const mapToNotifications = (dtoArr: NotificationDTO[]): Notification[] => {
  const result: Notification[] = [];
  if (typeof dtoArr !== 'undefined') {
    dtoArr.forEach(dto => {
      result.push({
        id: dto.Id,
        title: dto.Title,
        text: dto.Text,
        date: new Date(dto.Date + 'Z'),
        read: dto.Read
      });
    });
  }
  return result;
};

export const mapToNotification = (dto: NotificationDTO): Notification => {
  return {
    id: dto.Id,
    title: dto.Title,
    text: dto.Text,
    date: new Date(dto.Date),
    read: dto.Read
  };
};
