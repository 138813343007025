import { ThunkAction } from 'redux-thunk';
import { User, Order, IAction } from '_types/commonTypes';
import contactsApi from '_api/contactsApi';
import { IApplicationState } from 'core/reducer';
import { mapToUserFromContact } from '_mappers/userMapper';
import { Dispatch } from 'redux';
import { initialUserState } from '_reducers/partialInitialStates';

export enum IContactsActionTypes {
  ADD_CONTACT = '[ADD_CONTACT] SUCCESS',
  EDIT_CONTACT = '[EDIT_CONTACT] SUCCESS',
  LOAD_CONTACTS = '[LOAD_CONTACTS] SUCCESS',
  REMOVE_CONTACT = '[REMOVE_CONTACT] SUCCESS',
  SORT_REQUEST_START = '[CONTACTS_SORT_REQUEST_START]',
  SORT_REQUEST_END = '[CONTACTS_SORT_REQUEST_END]',
  SET_FILTER = '[CONTACTS_SET_FILTER]'
}

interface IAddContactSuccessAction {
  type: IContactsActionTypes.ADD_CONTACT;
  payload: { user: User };
}

interface IEditContactSuccessAction {
  type: IContactsActionTypes.EDIT_CONTACT;
  payload: { user: User };
}

interface IRemoveContactSuccessAction {
  type: IContactsActionTypes.REMOVE_CONTACT;
  payload: { username: string };
}

interface ILoadContactsSuccessAction {
  type: IContactsActionTypes.LOAD_CONTACTS;
  payload: { users: User[]; totalCount: number; page: number; recordsPerPage: number };
}

interface ISortRequestStartAction {
  type: IContactsActionTypes.SORT_REQUEST_START;
  payload: { sort: Order };
}

interface ISortRequestEndAction {
  type: IContactsActionTypes.SORT_REQUEST_END;
}

interface ISetFilterAction extends IAction<IContactsActionTypes.SET_FILTER, string> {}

export function removeContact(username: string): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return contactsApi.removeContact(username).then(res => dispatch(removeContactSuccessAction(username)));
  };

  function removeContactSuccessAction(username: string): IRemoveContactSuccessAction {
    return {
      type: IContactsActionTypes.REMOVE_CONTACT,
      payload: { username }
    };
  }
}

export function edtitContact(
  username: string,
  customName: string,
  companyName: string,
  companyWeb: string,
  countryId: number,
  keywords: string,
  description: string
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const countries = getState().codeList.countries;
    const industries = getState().codeList.industries;

    return contactsApi
      .editContact(username, customName, companyName, companyWeb, countryId, keywords, description)
      .then(res => {
        dispatch(editContactSuccessAction(mapToUserFromContact(res.data as any, countries, industries)));
      });
  };

  function editContactSuccessAction(user: User): IEditContactSuccessAction {
    return {
      type: IContactsActionTypes.EDIT_CONTACT,
      payload: { user }
    };
  }
}

export function addContact(
  username: string,
  customName?: string,
  companyName?: string,
  companyWeb?: string,
  countryId?: number,
  keywords?: string,
  industries?: string[],
  hideContactInformation?: boolean,
  description?: string
): ThunkAction<Promise<boolean>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return contactsApi
      .addContact(
        username,
        customName || '',
        companyName || '',
        companyWeb || '',
        countryId || 0,
        keywords || '',
        industries || [],
        hideContactInformation || false,
        description || ''
      )
      .then(res => mapToUserFromContact(res.data, getState().codeList.countries, getState().codeList.industries))
      .then(user => {
        dispatch(addContactSuccessAction(user));
        return user.company.id !== 0;
      });
  };

  function addContactSuccessAction(user: User): IAddContactSuccessAction {
    return {
      type: IContactsActionTypes.ADD_CONTACT,
      payload: { user }
    };
  }
}

export function loadContacts(
  pageNumber: number,
  recordsPerPage: number,
  filter: string,
  sort?: Order
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return contactsApi
      .getContacts(pageNumber, recordsPerPage, sort || getState().contacts.sort, filter)
      .then(res => {
        const countries = getState().codeList.countries;
        const industries = getState().codeList.industries;

        // Email filter
        return {
          totalCount: res.data.NoOfAllContacts,
          list: res.data.Results.map(json => mapToUserFromContact(json, countries, industries))
        };
      })
      .then(result =>
        dispatch(loadContactsSuccessAction(result.list, result.totalCount, pageNumber, recordsPerPage))
      );
  };

  function loadContactsSuccessAction(
    users: User[],
    totalCount: number,
    page: number,
    recordsPerPage: number
  ): ILoadContactsSuccessAction {
    return {
      type: IContactsActionTypes.LOAD_CONTACTS,
      payload: { users, totalCount, page, recordsPerPage }
    };
  }
}

export function sortContacts(
  currPage: number,
  pageLimit: number,
  filter: string,
  sort: Order
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch: Dispatch) => {
    dispatch(sortRequestStart(sort));

    return dispatch<any>(loadContacts(currPage, pageLimit, filter, sort)).finally(() => {
      dispatch(sortRequestEnd());
    });
  };

  function sortRequestStart(sort: Order): ISortRequestStartAction {
    return {
      type: IContactsActionTypes.SORT_REQUEST_START,
      payload: { sort }
    };
  }

  function sortRequestEnd(): ISortRequestEndAction {
    return {
      type: IContactsActionTypes.SORT_REQUEST_END
    };
  }
}

export function setContactsFilter(filter: string): ISetFilterAction {
  return { type: IContactsActionTypes.SET_FILTER, payload: filter };
}

export type ContactsAction =
  | ILoadContactsSuccessAction
  | IAddContactSuccessAction
  | IEditContactSuccessAction
  | IRemoveContactSuccessAction
  | ISortRequestStartAction
  | ISortRequestEndAction
  | ISetFilterAction;
