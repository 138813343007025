import { SuppliersTenderStatus, AddressSource, Address } from '_types/commonTypes';
import { TenderStatus } from '_types/tenderTypes';

const getSuppliersInvitationStatusLabel = (suppliersInvitationStatus: SuppliersTenderStatus) => {
  let label = '';

  if (suppliersInvitationStatus === SuppliersTenderStatus.INVITATION_SENT) {
    label = 'Invited';
  } else if (suppliersInvitationStatus === SuppliersTenderStatus.INVITATION_ACCEPTED) {
    label = 'Accepted';
  } else if (
    suppliersInvitationStatus === SuppliersTenderStatus.INVITATION_REJECTED_BY_BIDDER ||
    suppliersInvitationStatus === SuppliersTenderStatus.INVITATION_REJECTED_BY_BUYER
  ) {
    label = 'Rejected';
  } else if (suppliersInvitationStatus === SuppliersTenderStatus.BLOCKED) {
    label = 'Blocked';
  } else if (suppliersInvitationStatus === SuppliersTenderStatus.LEFT) {
    label = 'Auction Left';
  } else if (
    suppliersInvitationStatus === SuppliersTenderStatus.PENDING_PUBLIC_BIDDER ||
    suppliersInvitationStatus === SuppliersTenderStatus.PENDING_COMEBACK
  ) {
    label = 'Wait for Approval';
  } else if (suppliersInvitationStatus === SuppliersTenderStatus.APPROVAL_MISSED) {
    label = 'Approval missed';
  } else if (suppliersInvitationStatus === SuppliersTenderStatus.FAVORITE) {
    label = 'Need to Apply!';
  }

  return label;
};

const getSuppliersStatusWithReason = (suppliersInvitationStatus: SuppliersTenderStatus, reason: string) => {
  return (
    getSuppliersInvitationStatusLabel(suppliersInvitationStatus) +
    (suppliersInvitationStatus === SuppliersTenderStatus.INVITATION_REJECTED_BY_BUYER ||
    suppliersInvitationStatus === SuppliersTenderStatus.BLOCKED
      ? `, with reason: ${reason}`
      : '')
  );
};

const getAddressOptionLabel = (
  addressSource: AddressSource,
  address: Address,
  deliveryLabelCounter: number,
  isLast: boolean
) => {
  let label = '';

  if (addressSource === AddressSource.CURRENT_LOCATION) {
    label = 'Your current GPS locality';
  } else if (addressSource === AddressSource.USER) {
    label = `Profile home - ${address.country.code}` + (address.zipCode && `, ${address.zipCode}`);
  } else if (addressSource === AddressSource.COMPANY) {
    label = `Profile company - ${address.country.code}` + (address.zipCode && `, ${address.zipCode}`);
  } else {
    if (address.id !== 0) {
      if (!isLast) {
        label =
          `Previous ${deliveryLabelCounter} - ${address.country.code}` +
          (address.zipCode && `, ${address.zipCode}`);
      } else {
        label =
          `New delivery ${deliveryLabelCounter} - ${address.country.code}` +
          (address.zipCode && `, ${address.zipCode}`);
      }
    } else {
      label = 'Insert new address';
    }
  }

  return label;
};

const getOverallPositionLabel = (position: number): string => {
  return position !== 9999 ? position.toString() : 'N/A';
};

const MS_PER_DAY = 1000 * 60 * 60 * 24;
const MS_PER_HOUR = 1000 * 60 * 60;
const MS_PER_MINUTE = 1000 * 60;
const MS_PER_SECOND = 1000;

const getDateTimeDeltaLabel = (date: Date): string => {
  let label = '';
  const timeDiff = Math.abs(new Date().getTime() - date.getTime());
  if (timeDiff / MS_PER_SECOND < 60) {
    const seconds = Math.floor(timeDiff / MS_PER_SECOND);
    label = seconds !== 1 ? `${seconds} seconds ago` : '1 second ago';
  } else if (timeDiff / MS_PER_MINUTE < 60) {
    const minutes = Math.floor(timeDiff / MS_PER_MINUTE);
    label = minutes !== 1 ? `${minutes} minutes ago` : '1 minute ago';
  } else if (timeDiff / MS_PER_HOUR < 24) {
    const hours = Math.floor(timeDiff / MS_PER_HOUR);
    label = hours !== 1 ? `${hours} hours ago` : '1 hour ago';
  } else {
    const days = Math.floor(timeDiff / MS_PER_DAY);
    label = days !== 1 ? `${days} days ago` : '1 day ago';
  }

  return label;
};

const getTenderStatusLabel = (status: number): string => {
  let result = '';

  if (status === TenderStatus.NEW) {
    result = 'Draft';
  } else if (status === TenderStatus.SCHEDULED) {
    result = 'Req. for Proposals'; // Temp fix to not show schedule label
  } else if (status === TenderStatus.RFP_SUBMITTED) {
    result = 'Req. for Proposals';
  } else if (status === TenderStatus.RFP_ENDED) {
    result = 'Proposals check';
  } else if (status === TenderStatus.SUBMITTED) {
    result = 'Running';
  } else if (status === TenderStatus.ENDED) {
    result = 'Ended';
  } else if (status === TenderStatus.CANCELED) {
    result = 'Canceled';
  } else if (status === TenderStatus.FREEZE) {
    result = 'Freezed';
  }

  return result;
};

export const labelsManager = {
  getSuppliersInvitationStatusLabel,
  getSuppliersStatusWithReason,
  getAddressOptionLabel,
  getOverallPositionLabel,
  getDateTimeDeltaLabel,
  getTenderStatusLabel
};
