import axios from 'axios';
import { Dispatch } from 'redux';

import history from './history';
import { routes } from '_constants/routesConstants';
import { IActionTypes } from '_actions/userActions';
import { userApi } from '_api/userApi';
import { getTokenPersistance } from './tokenStorageManager/tokenStorageManager';
import TokenStorageManagerFactory from './tokenStorageManager/tokenStorageManagerFactory';
import setDefaultAuthorizationHeader from './setDefaultAuthorizationHeader';

/**
 * Backend returns 200 even if sometinhg went wrong and to data
 * puts Data with null and Error filled, this method handles that
 * @param res - repsonse from api call
 * MyLastOpenTender returns null without data if no tender was found
 */
const handleResponse = (res): Promise<any> => {
  if (res.data === false || (res.data && res.data.Data === null)) {
    return Promise.reject(res.data && res.data.Error);
  } else {
    return res;
  }
};

const setupInterceptors = (dispatch: Dispatch) => {
  axios.interceptors.response.use(
    response => {
      if (
        response.config.url!.match('account') ||
        response.config.url!.match('IsInContactList') ||
        response.config.url!.match('IsInContactListByUsername')
      ) {
        return response;
      }
      return handleResponse(response);
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const storage = new TokenStorageManagerFactory().create(getTokenPersistance());
        const refreshToken = storage.getRefreshToken();

        // Token refresh
        if (!originalRequest._retry && refreshToken) {
          try {
            originalRequest._retry = true;
            setDefaultAuthorizationHeader('');
            const { access_token, refresh_token } = await userApi.refreshToken(refreshToken);
            storage.setJwtToken(access_token);
            storage.setRefreshToken(refresh_token);

            setDefaultAuthorizationHeader(access_token);
            originalRequest.headers.Authorization = `Bearer ${access_token}`;

            return axios({
              ...originalRequest
            });
          } catch {
            console.log('invalid token');
          }
        }

        if (history.location.pathname !== routes.LOGIN) {
          dispatch({ type: IActionTypes.LOGOUT });
          history.push(routes.LOGIN, { target: history.location });
        }
      }

      return Promise.reject(error.response);
    }
  );
};

const axiosSettings = { setupInterceptors };

export default axiosSettings;
