import { combineReducers } from 'redux';

import * as fromLocalization from './localizationReducer';
import * as fromWiki from './wikiReducer';

export interface IState {
  localization: fromLocalization.IState;
  wiki: fromWiki.IState;
}

export const initialState: IState = {
  localization: fromLocalization.initialState,
  wiki: fromWiki.initialState
};

export const reducer = combineReducers<IState>({
  localization: fromLocalization.reducer,
  wiki: fromWiki.reducer
});
