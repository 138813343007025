import { observer } from 'redux-observers';

import { IApplicationState } from 'core/reducer';
import { Address, User, AddressSource } from '_types/commonTypes';
import { initialDeliveryAddressState } from '_reducers/partialInitialStates';
import { upsertAddressCodelist } from '_actions/codeListActions';

/**
 * Whenever something changed in tender reducer */
export const userHasChangedObserver = observer(
  (state: IApplicationState) => state.authentication.user,
  (dispatch: any, currentUserState: User, previousUserState: User) => {
    // if user address changed update address codelist
    if (hasAddressChanged(currentUserState.address, previousUserState.address)) {
      dispatch(upsertAddressCodelist(AddressSource.USER, currentUserState.address));
    }
    // if company address changed update company codelist
    if (
      currentUserState.company &&
      hasAddressChanged(
        currentUserState.company.address,
        previousUserState.company ? previousUserState.company.address : initialDeliveryAddressState
      )
    ) {
      dispatch(upsertAddressCodelist(AddressSource.COMPANY, currentUserState.company.address));
    }
  }
);

const hasAddressChanged = (currAddres: Address, prevAddress: Address): boolean => {
  return (
    currAddres.city !== prevAddress.city ||
    currAddres.country.id !== prevAddress.country.id ||
    currAddres.state !== prevAddress.state ||
    currAddres.streetAddress !== prevAddress.streetAddress ||
    currAddres.streetAddress2 !== prevAddress.streetAddress2 ||
    currAddres.zipCode !== prevAddress.zipCode
  );
};
