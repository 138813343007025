import { ThunkAction } from 'redux-thunk';

import { IApplicationState } from 'core/reducer';
import { fetchTendersBE } from '_api/tenderApi';
import { Tender } from '_types/tenderTypes';
import { mapTender } from '_mappers/tenderMapper';
import { IAction } from '_types/commonTypes';
import { IActionTypes } from './alertActions';
import { toggleFavoriteTender } from './tenderActions';
import { addTenderToFavoritesBE } from '_api/tenderSupplierApi';

export enum ITendersActionTypes {
  ADD_TENDERS = '[ADD_TENDERS] SUCCESS',
  TOGGLE_FAVORITE = '[TENDERS] TOGGLE_FAVORITE',
  CLEAR_TENDERS = '[TENDERS] CLEAR'
}

interface IAddTendersSuccessAction {
  type: ITendersActionTypes.ADD_TENDERS;
  payload: { tenders: Tender[] };
}

interface IClearTendersActions extends IAction<ITendersActionTypes.CLEAR_TENDERS, {}> {}

interface IToggleTenderFavoritesAction
  extends IAction<ITendersActionTypes.TOGGLE_FAVORITE, { tenderId: number; isFavorite: boolean }> {}

/**
 * Add only those tenders, which are not present in store
 * @param ids - tender ids
 * @param pageNumber - pagination prop
 * @param recordsPerPage - pagination prop
 */
function getTenders(
  ids: number[],
  pageNumber?: number,
  recordsPerPage?: number
): ThunkAction<Promise<any>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    // TODO Kvoli bugu kde mozu byt v cashi duplicitne data, po odstraneni toho bugu odstranit
    const tendersFound = getState().tenders.filter(
      (tender, index, self) =>
        self.findIndex(t => t.tenderId === tender.tenderId && ids.indexOf(tender.tenderId) > -1) === index
    );

    // const tendersFound = getState().tenders.filter(t => ids.indexOf(t.tenderId) > -1);
    const tendersFoundLength = tendersFound.length;

    if (ids.length !== tendersFoundLength) {
      let tendersToFetch = ids;

      if (tendersFoundLength > 0) {
        tendersToFetch = ids.filter(i => tendersFound.findIndex(x => x.tenderId === i) < 0);
      }

      return fetchTendersBE(tendersToFetch, pageNumber, recordsPerPage)
        .then(res => {
          const codeList = getState().codeList;

          const tenders = res.data.map(dto => mapTender(dto, codeList));

          dispatch(addUsersSuccessAction(tenders));

          return tenders;
        })
        .catch(err => {
          return [];
        });
    } else {
      return Promise.resolve(tendersFound);
    }
  };
}

function addUsersSuccessAction(tenders: Tender[]): IAddTendersSuccessAction {
  return {
    type: ITendersActionTypes.ADD_TENDERS,
    payload: { tenders }
  };
}

function clearTenders(): IClearTendersActions {
  return {
    type: ITendersActionTypes.CLEAR_TENDERS,
    payload: {}
  };
}

function toggleTenderFavorite(tenderId: number, isFavorite: boolean): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return addTenderToFavoritesBE(tenderId, isFavorite).then(() => {
      dispatch(toggleFavorite(tenderId, isFavorite));
    });
  };

  function toggleFavorite(tenderId: number, isFavorite: boolean): IToggleTenderFavoritesAction {
    return {
      type: ITendersActionTypes.TOGGLE_FAVORITE,
      payload: { tenderId, isFavorite }
    };
  }
}

export type TendersAction = IAddTendersSuccessAction | IToggleTenderFavoritesAction | IClearTendersActions;

export { getTenders, toggleTenderFavorite, clearTenders };
