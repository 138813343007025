import { User } from '_types/commonTypes';
import { IUsersActionTypes, UsersAction } from '_actions/usersActions';

export interface IState extends Array<User> {}

export const initialState: IState = [];

export function reducer(state: IState = initialState, action: UsersAction): IState {
  switch (action.type) {
    case IUsersActionTypes.ADD_USERS:
      return [...state, ...action.payload.users];
    default: {
      return state;
    }
  }
}
