import React from 'react';
import cx from 'classnames';
import { TooltipProps } from '_types/commonTypes';

type Props = {
  tooltip?: string; // Legacy
  icon: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  iconClassName?: string;
  dataTarget?: string;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  disabled?: boolean;
  last?: boolean;
  href?: string;
  rel?: string;
  target?: string;
} & TooltipProps;

const Action: React.FC<Props> = ({
  tooltip,
  icon,
  onClick,
  className,
  iconClassName,
  dataTarget,
  style,
  iconStyle,
  disabled,
  last = false,
  dataTip,
  dataFor,
  href,
  rel,
  target,
  ...restProps
}) => {
  return (
    <a
      className={cx(
        'actions__action',
        {
          ['disabled']: disabled,
          ['last']: last
        },
        className
      )}
      {...(!disabled && { onClick: onClick })}
      data-target={dataTarget}
      style={style}
      href={href}
      rel={rel}
      target={target}
      {...restProps}
    >
      {tooltip ? (
        <span className="tooltip" data-title={tooltip}>
          <i className={cx('material-icons actions__icon', iconClassName)} style={iconStyle}>
            {icon}
          </i>
        </span>
      ) : (
        <i
          className={cx('material-icons actions__icon', iconClassName)}
          style={iconStyle}
          data-tip={dataTip}
          data-for={dataFor}
        >
          {icon}
        </i>
      )}
    </a>
  );
};

export default Action;
