import { Store } from 'redux';
import moment from 'moment';

import { Hub } from './hub';
import {
  updateSuppliersItemBid,
  updateSuppliersCriterionBid,
  updateSuppliersPositions,
  updateItemDetails,
  updateCriterionDetails,
  updateSuppliersParictipationStatus,
  updateTenderProps,
  updateTenderStatus,
  addSupplier,
  addBidChange,
  removeBidChange,
  updateSuppliersBlockReason,
  updateSuppliersOfferDescriptionSuccess,
  updateSuppliersLastActivity,
  addAnnouncement,
  addSuppliersAttachmentsSuccess,
  removeSuppliersAttachmentSuccess,
  updateSuppliersMHCResponsesSuccess
} from '_actions/tenderActions';
import {
  ItemBidDTO,
  CriterionBidDTO,
  SuppliersPositionDTO,
  SuppliersEntity,
  TenderLightWeightDTO,
  MhcResponseDTO
} from '_types/backendTypes';
import {
  mapToSuppliersItemBid,
  mapToSuppliersCriteriaBid,
  mapToSuppliersPositionFromDTO
} from '_mappers/tenderMapper';
import { SuppliersPosition, SuppliersTenderStatus } from '_types/commonTypes';
import { IApplicationState } from 'core/reducer';
import history from '_libs/history';
import { routes } from '_constants/routesConstants';
import { mapToSupplierFromTenderSupplierDTO } from '_mappers/tenderSupplierMapper';
import {
  updateTenderLwStatus,
  updateTenderLwSuppliersStatus,
  updateTenderLwVisibility,
  updateTenderLwActiveBidders,
  updateTenderLwNewApplies,
  addTenderLW
} from '_actions/myTendersAction';
import { SuppliersBidChange } from '_types/tenderTypes';
import { ShowSuccessToast } from '_components/toasts/toast';

export class TenderHub extends Hub {
  bidChangedDuration: number;

  constructor(connection: SignalR.Hub.Connection, store: Store) {
    super(connection, store, 'tenderHub');

    this.bidChangedDuration = Number(
      getComputedStyle(document.documentElement).getPropertyValue('--bid-changed-duration')
    );
  }

  registerMethods = () => {
    this.proxy.on('updateItemBestBid', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();
      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const index = state.tender.currentTender.itemsDetails.findIndex(i => i.id === data.id);

          if (index > -1) {
            this.store.dispatch(updateItemDetails(index, 'bestBid', data.value, false));
          }
        }
      }
    });

    this.proxy.on('updateCriterionBestBid', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();

      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const index = state.tender.currentTender.criteriaDetails.findIndex(i => i.id === data.id);

          if (index > -1) {
            this.store.dispatch(updateCriterionDetails(index, 'bestBid', data.value, false));
          }
        }
      }
    });

    this.proxy.on('updateTotalActiveBiddersInTender', (tenderId: number, totalActiveBidders) => {
      const state: IApplicationState = this.store.getState();

      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof totalActiveBidders !== 'undefined' && totalActiveBidders !== null) {
          this.store.dispatch<any>(updateTenderProps('totalActiveBidders', totalActiveBidders, false));
        }
      } else if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
        this.store.dispatch<any>(updateTenderLwActiveBidders(tenderId, totalActiveBidders));
      }
    });

    this.proxy.on('addPublicBidder', (tenderId: number, data: SuppliersEntity) => {
      const state: IApplicationState = this.store.getState();

      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const supplier = mapToSupplierFromTenderSupplierDTO(
            data,
            state.codeList.suppliersInvitationStatuses,
            state.codeList.countries
          );
          this.store.dispatch(addSupplier(supplier, false));
        }
      }
    });

    this.proxy.on('updateSuppliersItemBid', (tenderId: number, data: ItemBidDTO) => {
      const state: IApplicationState = this.store.getState();
      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const suppliersItemBid = mapToSuppliersItemBid(data);
          this.store.dispatch(updateSuppliersItemBid(suppliersItemBid));

          // Process bid change
          const bidChange: SuppliersBidChange = {
            type: 'Item',
            id: suppliersItemBid.itemId,
            userName: suppliersItemBid.userName
          };

          this.store.dispatch(addBidChange(bidChange));
          const id = setTimeout(() => {
            this.store.dispatch(removeBidChange(bidChange));
            clearTimeout(id);
          }, this.bidChangedDuration + 200);
        }
      }
    });

    this.proxy.on('updateSuppliersCriterionBid', (tenderId: number, data: CriterionBidDTO) => {
      const state: IApplicationState = this.store.getState();
      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const suppliersCriterionBid = mapToSuppliersCriteriaBid(data);
          this.store.dispatch(updateSuppliersCriterionBid(suppliersCriterionBid));
          // Process bid change
          const bidChange: SuppliersBidChange = {
            type: 'Criterion',
            id: suppliersCriterionBid.criteriaId,
            userName: suppliersCriterionBid.userName
          };
          this.store.dispatch(addBidChange(bidChange));
          const id = setTimeout(() => {
            this.store.dispatch(removeBidChange(bidChange));
            clearTimeout(id);
          }, this.bidChangedDuration + 200);
        }
      }
    });

    this.proxy.on('updateSuppliersPositions', (tenderId: number, data: Array<SuppliersPositionDTO>) => {
      const state: IApplicationState = this.store.getState();

      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof data !== 'undefined' && data !== null) {
          const suppliersPositions: Array<SuppliersPosition> = [];
          for (const dto of data) {
            suppliersPositions.push(mapToSuppliersPositionFromDTO(dto));
          }
          this.store.dispatch(updateSuppliersPositions(suppliersPositions));
        }
      }
    });

    this.proxy.on('updateSuppliersParticipationStatus', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();
      if (typeof data !== 'undefined' && data !== null) {
        if (tenderId === state.tender.currentTender.tenderId) {
          this.store.dispatch<any>(updateSuppliersParictipationStatus(data.userName, data.id));

          if (
            data.id === SuppliersTenderStatus.INVITATION_ACCEPTED &&
            state.tender.currentTender.owner.userName === state.authentication.user.userName
          ) {
            ShowSuccessToast(`${data.userName} has accepted your invitation`);
          }
        }

        if (data.userName === state.authentication.user.userName) {
          if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
            this.store.dispatch<any>(updateTenderLwSuppliersStatus(tenderId, data.id));
          }
        }
      }
    });

    this.proxy.on('blockBidder', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();
      if (typeof data !== 'undefined' && data !== null) {
        if (tenderId === state.tender.currentTender.tenderId) {
          if (data.username === state.authentication.user.userName) {
            this.store.dispatch(updateSuppliersBlockReason(data.username, data.reason));
          }
        }
      }
    });

    this.proxy.on('commentOffer', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();
      if (typeof data !== 'undefined' && data !== null) {
        if (tenderId === state.tender.currentTender.tenderId) {
          if (state.tender.currentTender.owner.userName === state.authentication.user.userName) {
            this.store.dispatch(
              updateSuppliersOfferDescriptionSuccess(data.username, data.offerDescription || '')
            );
          }
        }
      }
    });

    this.proxy.on('addBiddersAttachments', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();

      if (typeof data !== 'undefined' && data !== null) {
        if (tenderId === state.tender.currentTender.tenderId) {
          this.store.dispatch(addSuppliersAttachmentsSuccess(data.username, data.list));
        }
      }
    });

    this.proxy.on('removeBiddersAttachment', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();

      if (typeof data !== 'undefined' && data !== null) {
        if (tenderId === state.tender.currentTender.tenderId) {
          this.store.dispatch(removeSuppliersAttachmentSuccess(data.attachmentId));
        }
      }
    });

    this.proxy.on('updateTenderStatus', (tenderId: number, status) => {
      const state: IApplicationState = this.store.getState();

      // update current tender
      if (tenderId === state.tender.currentTender.tenderId) {
        if (typeof status !== 'undefined' && status !== null) {
          this.store.dispatch<any>(updateTenderStatus(status));
        }
      }

      if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
        this.store.dispatch<any>(updateTenderLwStatus(tenderId, status));
      }
    });

    this.proxy.on('updateTenderNewAppliesCount', (tenderId: number, value: number) => {
      if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
        this.store.dispatch<any>(updateTenderLwNewApplies(tenderId, value));
      }
    });

    this.proxy.on('addMyTender', (data: { data: TenderLightWeightDTO }) => {
      if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
        this.store.dispatch<any>(addTenderLW(data.data));
      }
    });

    this.proxy.on('updateTenderVisibility', (tenderId: number, visibility: boolean) => {
      if (history.location.pathname.startsWith(routes.MY_AUCTIONS)) {
        this.store.dispatch<any>(updateTenderLwVisibility(tenderId, visibility));
      }
    });

    this.proxy.on(
      'addTenderAnnouncement',
      (tenderId: number, announcement: { created: string; announcement: string }) => {
        const state: IApplicationState = this.store.getState();

        if (tenderId === state.tender.currentTender.tenderId) {
          this.store.dispatch<any>(
            addAnnouncement({ date: moment(announcement.created).toDate(), text: announcement.announcement })
          );
        }
      }
    );

    this.proxy.on('prolongAuction', (tenderId: number, data) => {
      const state: IApplicationState = this.store.getState();
      const isSupplier = state.tender.currentTender.owner.userName !== state.authentication.user.userName;

      if (tenderId === state.tender.currentTender.tenderId && isSupplier) {
        this.store.dispatch<any>(updateTenderProps('duration', data, false));
      }
    });

    this.proxy.on('SetUserAsActive', (tenderId: number, user: string, date: string) => {
      const state: IApplicationState = this.store.getState();

      if (tenderId === state.tender.currentTender.tenderId) {
        this.store.dispatch<any>(updateSuppliersLastActivity(user, moment(date).toISOString(true)));
      }
    });

    this.proxy.on('notifyOwnerWhenCompletedOffer', (tenderId: number, owner: string, bidder: string) => {
      const state: IApplicationState = this.store.getState();
      const { currentTender } = state.tender;

      if (tenderId === currentTender.tenderId && currentTender.owner.userName === owner) {
        ShowSuccessToast(`${bidder} has completed his offer`);
      }
    });

    this.proxy.on(
      'saveSuppliersMustHaveConditionResponses',
      (tenderId: number, user: string, responses: MhcResponseDTO[]) => {
        const state: IApplicationState = this.store.getState();

        if (tenderId === state.tender.currentTender.tenderId) {
          this.store.dispatch<any>(
            updateSuppliersMHCResponsesSuccess(
              user,
              responses.map(r => ({ mhcId: r.MustHaveConditionId, response: r.NewResponse }))
            )
          );
        }
      }
    );

    this.proxy.on('Disconnect', () => this.store.dispatch({ type: 'connection:stop' }));
  };
}
