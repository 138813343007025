import { GovernmentTendersAction, IGovTendersActionTypes } from '_actions/governmentTendersActions';
import { ExternalTenderDTO } from '_types/backendTypes';
import { Tender } from '_types/tenderTypes';

export interface IState extends Array<ExternalTenderDTO> {}

export const initialState: IState = [];

export function reducer(state: IState = initialState, action: GovernmentTendersAction): IState {
  switch (action.type) {
    case IGovTendersActionTypes.ADD_TENDERS:
      return [...state, ...action.payload.tenders];
    case IGovTendersActionTypes.TOGGLE_FAVORITE: {
      let tenders = state.slice();
      const index = tenders.findIndex(t => t.id === action.payload.tenderId);

      if (index > -1) {
        // TODO GOV FAVORITE
        // tenders[index] = { ...tenders[index], isFavorite: !tenders[index].isFavorite };
      }

      return tenders;
    }
    case IGovTendersActionTypes.CLEAR_TENDERS:
      return [];
    default: {
      return state;
    }
  }
}
