import React, { PureComponent } from 'react';
import cx from 'classnames';

import { labelsManager } from '_libs/labelsManager';
import { routes } from '_constants/routesConstants';
import history from '_libs/history';

type Props = {
  title: string;
  text: string;
  read: boolean;
  className?: string;
  date: Date;
  onNotificationClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  onLinkClick: () => void;
};

const NewLabel = () => <div className="notification__new">NEW</div>;

enum NotificationAction {
  TO_AUCTION_OWNER = 'TO_AUCTION_OWNER',
  TO_AUCTION_SUPPLIER = 'TO_AUCTION_SUPPLIER'
}

interface INotificationAction {
  action: string; // Type of action
  description: string; // What needs to be rendered as link
  data: any; // id or whatever data that needs to be passed
}

class Notification extends PureComponent<Props> {
  /**
   * Retrieves a link if clicked, stop redirect and then makes history push to redirect via router
   */
  contentClickHandler = (e: any) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();
    const pathname = targetLink.href.slice(window.location.origin.length);
    history.push(pathname);
    this.props.onLinkClick();
  };

  getAnchor = (notifAction: INotificationAction): string => {
    switch (notifAction.action) {
      case NotificationAction.TO_AUCTION_OWNER:
        return `<a href="${routes.OWNER_AUCTION}/${notifAction.data}" class="auction-link">${notifAction.description}</a>`;
      case NotificationAction.TO_AUCTION_SUPPLIER:
        return `<a href="${routes.SUPPLIER_AUCTION}/${notifAction.data}" class="auction-link">${notifAction.description}</a>`;
      default:
        return '[WRONG NOTIFICATION AUCTION]';
    }
  };

  /**
   * Returns a enhanced text when link is provided
   */
  getNotificationText = (text: string) => {
    const pattern = /\[(.*)\]/;
    const match = text.match(pattern);
    let anchor = '';
    let result = text;

    if (match) {
      const notifAction = JSON.parse(match[1]);

      if (notifAction) {
        anchor = this.getAnchor(notifAction);
      }

      result = text.replace(pattern, anchor);
    }

    return result;
  };

  render() {
    const { title, text, read, date, onNotificationClick, className } = this.props;

    return (
      <li
        className={cx('notification', className, { ['notification--read']: read })}
        onClick={onNotificationClick}
      >
        <div className="margin-bottom-15">
          <div className="notification__title">{title}</div>
          <span className="notification__date">{labelsManager.getDateTimeDeltaLabel(date)}</span>
          {!read && <NewLabel />}
        </div>
        <div
          onClick={this.contentClickHandler}
          className="notification__text"
          dangerouslySetInnerHTML={{ __html: this.getNotificationText(text) }}
        />
      </li>
    );
  }
}

export default Notification;
