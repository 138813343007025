import { Store } from 'redux';

import { Hub } from './hub';
import { addNotification } from '_actions/notificationActions';
import { mapToNotification } from '_mappers/notificationMapper';

export class NotificationHub extends Hub {
  constructor(connection: SignalR.Hub.Connection, store: Store) {
    super(connection, store, 'notificationHub');
  }

  registerMethods = () => {
    this.proxy.on('addNotification', notificationDto => {
      if (typeof notificationDto !== 'undefined' && notificationDto !== null) {
        const notification = mapToNotification(notificationDto);
        this.store.dispatch(addNotification(notification));
      }
    });
  };
}
