import React from 'react';
import { withRouter } from 'react-router';

interface IOwnProps {
  location: Location;
}

type Props = IOwnProps;

class ScrollToTop extends React.Component<Props> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo({ top: 0 });
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter<any, any>(ScrollToTop);
