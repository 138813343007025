import axios from 'axios';

import API_URL from '_constants/apiConstants';
import { WikiQuestionAndAnswer, CodeList } from '_types/commonTypes';

const ADMIN_URL = '/api/admin';

const ADMIN_API_URL = API_URL + ADMIN_URL;

const fetchWikiContent = async (): Promise<any> => {
  const res = await axios.get(`${ADMIN_API_URL}/GetQuestionsAndAnswersFromWiki`);
  return mapWikiContent(res.data);
};

const addQuestionAndAnswer = (body: WikiQuestionAndAnswer) => {
  return axios.post(`${ADMIN_API_URL}/AddQuestionAndAnswerToWiki`, body);
};

const editQuestionAndAnswer = (body: WikiQuestionAndAnswer) => {
  return axios.put(`${ADMIN_API_URL}/EditQuestionAndAnswerToWiki`, body);
};

const removeQuestion = (id: number) => {
  return axios.delete(`${ADMIN_API_URL}/DeleteQuestionAndAnswerFromWiki`, {
    params: { QuestionId: id }
  });
};

const addCategory = (name: string) => {
  return axios.post(`${ADMIN_API_URL}/AddWikiCategory`, {}, { params: { wikiCategoryName: name } });
};

const editCategory = (category: CodeList<number>) => {
  return axios.post(
    `${ADMIN_API_URL}/EditWikiCategory`,
    {},
    {
      params: {
        wikiCategoryId: category.id,
        wikiCategoryName: category.name
      }
    }
  );
};

const removeCategory = (id: number) => {
  return axios.post(
    `${ADMIN_API_URL}/RemoveWikiCategory`,
    {},
    {
      params: { wikiCategoryId: id }
    }
  );
};

export const mapWikiContent = data => {
  const result: WikiQuestionAndAnswer[] = [];

  if (typeof data !== 'undefined') {
    data.forEach(element => {
      result.push({
        id: element.Id,
        question: element.Question,
        answer: element.Answer,
        categoryId: element.CategoryId
      });
    });
  }

  return result;
};

export const wikiApi = {
  addCategory,
  editCategory,
  removeCategory,
  fetchWikiContent,
  addQuestionAndAnswer,
  editQuestionAndAnswer,
  removeQuestion
};
