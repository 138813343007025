import { Address, Company, User } from '_types/commonTypes';

export const initialCountry = {
  id: 0,
  name: '',
  code: '',
  capital: '',
  region: '',
  banned: false
};

export const initialDeliveryAddressState: Address = {
  id: 0,
  streetAddress: '',
  streetAddress2: '',
  zipCode: '',
  city: '',
  state: '',
  country: initialCountry,
  gpsCoords: {
    lat: '',
    lng: ''
  }
};

export const initialCompanyState: Company = {
  id: 0,
  name: '',
  web: '',
  position: '',
  industries: [],
  address: initialDeliveryAddressState,
  numberOfEmployees: '',
  registrationId: '',
  taxId: '',
  branches: 1,
  vatId: '',
  yearlyRevenue: ''
};

export const initialUserState: User = {
  userName: '',
  coveredUsername: '',
  email: '',
  isEmailConfirmed: true,
  country: '',
  rating: 0,
  firstName: '',
  lastName: '',
  accountType: '',
  address: initialDeliveryAddressState,
  company: initialCompanyState,
  defaultCurrency: '',
  facebookProfile: '',
  favouriteAuctionKeywords: '',
  linkedinProfile: '',
  phoneNumber: '',
  isSearcheable: true,
  wantToBuy: true,
  wantToSell: true
};
