import React, { useEffect, Fragment } from 'react';
import ReactGA, { EventArgs } from 'react-ga';

import history from '_libs/history';
import { GAUtmParams } from '_types/commonTypes';

export const GA_REGISTER_CAMPAIGN = 'Registracia';

export const getGAUtmParams = (qs: any): GAUtmParams => {
  return Object.keys(qs).reduce((obj, currKey) => {
    if (currKey.startsWith('utm_')) {
      obj[currKey] = qs[currKey];
    }

    return obj;
  }, {});
};

export const createUtmParamString = (utmParams: GAUtmParams) => {
  return Object.keys(utmParams).reduce(
    (str, currKey, i) => str + `${(i === 0 ? '' : ';') + currKey}:${utmParams[currKey]}`,
    ''
  );
};

export const GARegisterEvent = (utmParams: string) => {
  GAEvent({ category: GA_REGISTER_CAMPAIGN, action: 'Button', label: utmParams });
};

export const GAEvent = (args: EventArgs) => {
  ReactGA.event({
    ...args
  });
};

const GoogleAnalytics: React.FC = ({ children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_GA_UA) {
      ReactGA.initialize(process.env.REACT_APP_GA_UA);
    } else {
      console.log('GOOGLE ANALYTICS TRACKING ID NOT SET');
    }

    ReactGA.pageview(history.location.pathname + history.location.search);

    history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
    });
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default GoogleAnalytics;
