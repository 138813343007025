import axios from 'axios';

import API_URL from '_constants/apiConstants';
import { Order } from '_types/commonTypes';

const URL = API_URL + '/api/company';

export const getContacts = (page: number, recordsPerPage: number, sort: Order, filter: string) => {
  return axios.get(URL + '/GetContacts', {
    params: { orderByColumn: sort.columnId, asc: sort.direction, pageNo: page, recordsPerPage, filter }
  });
};

export const removeContact = (username: string) => {
  return axios.delete(URL + '/RemoveFromContactList', { params: { userName: username } });
};

export const addContact = (
  userName: string,
  customName: string,
  companyName: string,
  companyWeb: string,
  countryId: number,
  keywords: string,
  industries: string[],
  fromExternal: boolean,
  description: string
) => {
  return axios.post(
    URL + '/AddToContactList',
    { industries },
    {
      params: {
        userName,
        customName,
        companyName,
        companyWeb,
        countryId,
        keywords,
        fromExternal,
        personalDescription: description
      }
    }
  );
};

export const editContact = (
  userName: string,
  customName: string,
  companyName: string,
  companyWeb: string,
  countryId: number,
  keywords: string,
  description: string
) => {
  return axios.put(
    URL + '/EditCompanyName',
    {},
    {
      params: {
        userName,
        customName,
        companyName,
        companyWeb,
        countryId,
        keywords,
        personalDescription: description
      }
    }
  );
};

export const isInContactList = (companyId: string) => {
  return axios.get(URL + '/IsInContactList', {
    params: { companyId }
  });
};

export const isInContactListByUsername = (username: string) => {
  return axios.get(URL + '/IsInContactListByUsername', {
    params: { username }
  });
};

const contactsApi = {
  getContacts,
  removeContact,
  addContact,
  editContact,
  isInContactList,
  isInContactListByUsername
};

export default contactsApi;
