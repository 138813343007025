import axios, { AxiosRequestConfig } from 'axios';

import API_URL from '_constants/apiConstants';
import { Tender } from '_types/tenderTypes';
import { mapToTenderDTO } from '_mappers/tenderMapper';
import { Order } from '_types/commonTypes';
export const TENDER_URL = '/api/tender';

const TENDER_API_URL = API_URL + TENDER_URL;

export const fetchTenderTemplates = (): Promise<any> => {
  return axios.get(`${TENDER_API_URL}/TenderDtoWithDefaults`);
};

/**
 * Fetches my tenders list
 */
export const fetchMyTendersBE = async (
  currentPage: number,
  pageLimit: number,
  filter: string,
  sort: Order,
  search: string
): Promise<any> => {
  const res = await axios.get(`${TENDER_API_URL}/GetMyTenders`, {
    params: {
      filter,
      searchByColumn: sort.columnId,
      order: sort.direction,
      pageNo: currentPage,
      recordsPerPage: pageLimit,
      name: search
    }
  });
  return res;
};

/**
 * Returns tender
 */
export const fetchTenderBE = async (id: number): Promise<any> => {
  const res = await axios.get(`${TENDER_API_URL}/GetTender`, { params: { id } });
  return res;
};

export const messageToAllBidders = (id: number, message: string): Promise<any> => {
  return axios.post(`${TENDER_API_URL}/MessageToBidders`, { msg: message }, { params: { id } });
};

/**
 * Returns last unfinished tender id, if not found tender id is -1
 */
export const fetchLastUnfinishedTenderBE = async (): Promise<any> => {
  const res = await axios.get(`${TENDER_API_URL}/MyLastOpenTender`);
  return res;
};

/**
 * Create new tender and receives tenderId
 */
export const createNewTenderBE = async (): Promise<number> => {
  const res = await axios.post(`${TENDER_API_URL}/CreateNewTenderId`);
  return res.data;
};

export const duplicateTenderBE = async (tenderId: number): Promise<any> => {
  const res = await axios.post(`${TENDER_API_URL}/DuplicateTender`, {}, { params: { tenderId } });
  return res;
};

const fetchLatestPublicTenders = (pageNumber: number, recordsPerPage: number) => {
  return axios.get(`${TENDER_API_URL}/LatestPublicTenders`, { params: { pageNo: pageNumber, recordsPerPage } });
};

export const CancelTender = async (tenderId: number, reason: string): Promise<any> => {
  const res = await axios.post(`${TENDER_API_URL}/CancelTender`, {}, { params: { tenderId, reason } });
  return res;
};

export const HideTender = async (tenderId: number, hidden: boolean): Promise<any> => {
  const res = await axios.patch(`${TENDER_API_URL}/HideTender`, {}, { params: { tenderId, hidden } });

  return res;
};

export const uploadAttachmentBE = async (tenderId: number, formData: FormData) => {
  return axios.post(`${TENDER_API_URL}/UploadAttachment`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      tenderId
    }
  });
};

export const uploadMainPicture = (tenderId: number, formData: FormData) => {
  return axios.post(`${TENDER_API_URL}/UploadMainPicture`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      tenderId
    }
  });
};

export const removeMainPicture = (tenderId: number) => {
  return axios.delete(`${TENDER_API_URL}/DeleteMainPicture`, { params: { tenderId } });
};

export const downloadAttachmentBE = async (id: number) => {
  return axios.get(`${TENDER_API_URL}/GetFileById`, {
    params: {
      attachmentId: id
    },
    responseType: 'blob'
  });
};

export const removeAttachmentBE = async (id: number) => {
  return axios.delete(`${TENDER_API_URL}/DeleteAttachmentById`, {
    params: {
      attachmentId: id
    }
  });
};

const removeTenderDraft = async (id: number) => {
  return axios.delete(`${TENDER_API_URL}/DeleteTenderDraft`, {
    params: {
      tenderId: id
    }
  });
};
/**
 * Update tender
 */
export const updateTenderBE = async (tender: Tender, deliveryAddressChanged: boolean = false): Promise<any> => {
  const data: any = mapToTenderDTO(tender, deliveryAddressChanged);
  const res = await axios.post(`${TENDER_API_URL}/UpdateTender`, data);
  return res;
};

/**
 * Removes tender
 * @param tenderId - tender id
 */
export const removeTender = async (tenderId: number): Promise<any> => {
  const res = await axios.post(
    `${TENDER_API_URL}/DeleteTender`,
    {},
    {
      params: {
        Id: tenderId
      }
    }
  );
  return res;
};

/**
 * Prolongs tender
 * @param tenderId
 * @param additionalDuration
 */
export const prolongTender = async (tenderId: number, additionalDuration: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      length: additionalDuration
    }
  };

  const res = await axios.put(`${TENDER_API_URL}/ProlongTender`, {}, config);
  return res;
};

export const SearchAuctionsBE = async (
  keywords: string[],
  countries: number[],
  minimalExpectedValue: number,
  maximalExpectedValue: number,
  order: Order,
  auctionNameCheck,
  deliveryAddressCheck,
  buyerDetailsCheck
) => {
  const res = await axios.post(`${API_URL}/api/company/SearchInAuctions`, {
    keywords,
    countries,
    minimalExpectedValue,
    maximalExpectedValue,
    AuctionGroup: auctionNameCheck,
    DeliveryAddressGroup: deliveryAddressCheck,
    BuyerDetailGroup: buyerDetailsCheck,
    searchByColumn: order.columnId,
    order: order.direction
  });

  return res;
};

export const SearchGovAuctionsBE = async (
  keywords: string[],
  countries: number[],
  minimalExpectedValue: number,
  maximalExpectedValue: number,
  order: Order,
  auctionNameCheck,
  deliveryAddressCheck,
  buyerDetailsCheck
) => {
  const res = await axios.post(`${API_URL}/api/company/SearchInGovAuctions`, {
    keywords,
    countries,
    minimalExpectedValue,
    maximalExpectedValue,
    AuctionGroup: auctionNameCheck,
    DeliveryAddressGroup: deliveryAddressCheck,
    BuyerDetailGroup: buyerDetailsCheck,
    searchByColumn: order.columnId,
    order: order.direction
  });

  return res;
};
export const fetchTendersBE = async (ids: number[], pageNumber?: number, recordsPerPage?: number) => {
  const res = await axios.post(`${API_URL}/api/company/GetInfoTendersFromSearch`, {
    ids,
    ...(pageNumber && { PageNo: pageNumber }),
    ...(recordsPerPage && { recordsPerPage })
  });

  return res;
};

export const fetchGovTendersBE = async (ids: string[], pageNumber?: number, recordsPerPage?: number) => {
  const res = await axios.post(`${API_URL}/api/company/GetInfoGovTendersFromSearch`, {
    ids,
    ...(pageNumber && { PageNo: pageNumber }),
    ...(recordsPerPage && { recordsPerPage })
  });

  return res;
};

export const scheduleTenderBE = async (tenderId: number) => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId
    }
  };

  const res = await axios.put(`${TENDER_API_URL}/ScheduleTender`, {}, config);
  return res;
};

const pingActivityOnTender = (tenderId: number) => {
  return axios.get(`${TENDER_API_URL}/ActiveOnTender`, { params: { tenderId } });
};

const shareAuctionFacebook = (tenderId: number) => {
  return axios.post(`${TENDER_API_URL}/ShareAuction`, {}, { params: { tenderId } });
};

const tenderApi = {
  fetchTenderBE,
  fetchTenderTemplates,
  messageToAllBidders,
  scheduleTenderBE,
  fetchTendersBE,
  SearchAuctionsBE,
  prolongTender,
  fetchMyTendersBE,
  removeTender,
  fetchLatestPublicTenders,
  updateTenderBE,
  removeAttachmentBE,
  downloadAttachmentBE,
  pingActivityOnTender,
  HideTender,
  uploadMainPicture,
  removeMainPicture,
  shareAuctionFacebook,
  removeTenderDraft
};

export default tenderApi;
