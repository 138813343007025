import { observer } from 'redux-observers';

import { IApplicationState } from 'core/reducer';
import { submitTenderChange, updateTenderOnBackend } from '_actions/tenderActions';

/**
 * whenever something changed in tender reducer */
export const tenderHasChangedObserver = observer(
  (state: IApplicationState) => state,
  (dispatch: any, currentState: IApplicationState, previousState: IApplicationState) => {
    const { currentTender } = currentState.tender;
    const prevTender = previousState.tender.currentTender;

    // We need to update backend when props are changed
    if (
      currentState.tender.shouldUpdateBackend &&
      currentTender.tenderId !== 0 &&
      prevTender.tenderId === currentTender.tenderId // make sure to not trigger update when some fields were filled before create tender. Tender name
    ) {
      dispatch(updateTenderOnBackend());
      dispatch(submitTenderChange());
    }
  }
);
