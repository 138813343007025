// const productionUrl = 'https://devapi.bestauction.com'; // DEV;
// const developmentUrl = 'https://devapi.bestauction.com'; // DEV;
// const productionUrl = 'https://appapi.bestauction.com';
// const developmentUrl = 'https://appapi.bestauction.com';

const API_URL = process.env.REACT_APP_API_URL;
export default API_URL;

const googleApiKey = 'AIzaSyAuIfurumzj2HWLloCg1me63r65U_pWoRY';

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&language=en&v=3.exp&libraries=geometry,drawing,places`;

export const RECAPTCHA_SITE_KEY = '6LdSqagUAAAAAP9oxL0SKqAbYKezYrB5fTI8eBqF';

export const MAILCHIMP_API_KEY = 'f19da5925795626f6d65babbc4fc2028-us17';
export const MAILCHIMP_LIST_ID = '12b8de72b5';

export const IMAGE_ORIGIN =
  process.env.NODE_ENV === 'development' ? 'https://dev.bestauction.com' : window.location.origin;
