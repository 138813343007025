import React, { Fragment, Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

import Notification from './Notification';
import { IApplicationState } from 'core/reducer';
import { Notification as INotification } from '_types/commonTypes';
import { loadNotifications, markAllUnreadNotificationsAsRead } from '_actions/notificationActions';
import { scrollbarVisible } from '_libs/helpers';
import { toggleNotificationPanel } from '_actions/uiActions';

interface IStateToProps {
  showNotifications: boolean;
  notifications: INotification[];
}

interface IDispatchToProps {
  loadNotifications: (page: number, countPerPage: number) => Promise<any>;
  toggleNotificationPanel: () => void;
  markNotificationsAsRead: () => void;
}

type Props = IStateToProps & IDispatchToProps;

export const NOTIFICATIONS_PER_PAGE = 10;

type State = {
  lastLoadedPage: number;
};

class Notifications extends PureComponent<Props, State> {
  listElem: HTMLElement;
  // Used for UI to know if the notification is clicked to have not the panel closed down
  notificationClicked: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      lastLoadedPage: 1
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.showNotifications && prevProps.showNotifications !== this.props.showNotifications) {
      this.listElem.addEventListener('scroll', this.onScroll, false);
      // Checks if scroll bar is present in view, if not, we need to load more notifications to have the ability to load more on scroll
      if (!scrollbarVisible(this.listElem)) {
        this.loadMoreNotifications();
      }
    }
    if (prevProps.showNotifications && !this.props.showNotifications && this.listElem) {
      this.listElem.removeEventListener('scroll', this.onScroll, false);
    }
  }

  onScroll = () => {
    const scrollVerticalEnd = this.listElem.scrollHeight - this.listElem.offsetHeight;
    // Scroll position end must be offset by some number 20 because zoom is screwing with scrollTop by some numbers. If we want to load more notificatios we can do it here, by simply increasing lower interval offset.
    if (this.listElem.scrollTop > scrollVerticalEnd - 20 && this.listElem.scrollTop < scrollVerticalEnd + 20) {
      this.loadMoreNotifications();
    }
  };

  loadMoreNotifications = () => {
    this.setState(
      prevState => ({ lastLoadedPage: prevState.lastLoadedPage + 1 }),
      () => this.props.loadNotifications(this.state.lastLoadedPage, NOTIFICATIONS_PER_PAGE)
    );
  };

  handleNotificationClick = () => {
    this.notificationClicked = true;
  };

  // We can close notification panel when user click outside any notification. It's because notification wrapper has right padding to not showing the vertical scrollbar, but in this place we want to close notification panel as well.
  closePanel = () => {
    if (!this.notificationClicked) {
      this.props.toggleNotificationPanel();
      this.props.markNotificationsAsRead();
    }
    this.notificationClicked = false;
  };

  render() {
    const { showNotifications, notifications } = this.props;
    return (
      <Fragment>
        {showNotifications && (
          <div className="notifications-wrapper" onClick={this.closePanel}>
            <ul ref={list => list && (this.listElem = list)} className="notifications">
              {notifications.length > 0 ? (
                notifications.map((s, i) => (
                  <Notification
                    onNotificationClick={this.handleNotificationClick}
                    onLinkClick={this.closePanel}
                    key={i}
                    {...s}
                  />
                ))
              ) : (
                <li className="notification">
                  <div className="notification__text">
                    <h5>You haven't received any notifications yet.</h5>
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
        {showNotifications && <div className="notifications-overlay" onClick={this.closePanel} />}
        {this.props.children}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IApplicationState): IStateToProps => ({
  showNotifications: state.ui.notificationPanel.isOpened,
  notifications: state.notifcation.notifications
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => ({
  loadNotifications: (page: number, countPerPage: number) => dispatch<any>(loadNotifications(page, countPerPage)),
  toggleNotificationPanel: () => dispatch(toggleNotificationPanel()),
  markNotificationsAsRead: () => dispatch<any>(markAllUnreadNotificationsAsRead())
});

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(Notifications));
