import { ThunkAction } from 'redux-thunk';

import { IAction, NewsletterStatus } from '_types/commonTypes';
import { settingsApi } from '_api/settingsApi';

export enum IActionTypes {
  LOAD_STATUS_SUCCESS = '[NEWSLETTER_LOAD_STATUS] SUCCESS',
  SUBSCRIBE_SUCCESS = '[NEWSLETTER_SUBSCRIBE] SUCCESS',
  UNSUBSCRIBE_SUCCESS = '[NEWSLETTER_UNSUBSCRIBE] SUCCESS',
  TOGGLE_SUBSCRIBING = '[NEWSLETTER] TOGGLE_SUBSCRIBING'
}

interface ILoadStatusSuccess extends IAction<IActionTypes.LOAD_STATUS_SUCCESS, { status: NewsletterStatus }> {}
interface ISubscribeSuccess extends IAction<IActionTypes.SUBSCRIBE_SUCCESS, { status: NewsletterStatus }> {}
interface IUnsubscribeSuccess extends IAction<IActionTypes.UNSUBSCRIBE_SUCCESS, { status: NewsletterStatus }> {}
interface IToggleSubscribing extends IAction<IActionTypes.TOGGLE_SUBSCRIBING, {}> {}

function loadNewsletterStatus(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return settingsApi.fetchNewsletterStatus().then(res => {
      dispatch(loadStatusSuccess(res.data));
    });
  };

  function loadStatusSuccess(status: NewsletterStatus): ILoadStatusSuccess {
    return { type: IActionTypes.LOAD_STATUS_SUCCESS, payload: { status } };
  }
}

function subscribeForNewsletter(): ThunkAction<Promise<NewsletterStatus>, any, null, any> {
  return dispatch => {
    dispatch(toggleSubscribing());
    return settingsApi
      .subscribeForNewsletter()
      .then(res => {
        dispatch(subscribeForNewsletterSuccess(res.data));
        return res.data;
      })
      .finally(() => {
        dispatch(toggleSubscribing());
      });
  };

  function subscribeForNewsletterSuccess(status: NewsletterStatus): ISubscribeSuccess {
    return { type: IActionTypes.SUBSCRIBE_SUCCESS, payload: { status } };
  }
}

function unsubscribeForNewsletter(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    dispatch(toggleSubscribing());
    return settingsApi
      .unsubscribeForNewsletter()
      .then(res => {
        dispatch(unsubscribeForNewsletterSuccess(res.data));
      })
      .finally(() => {
        dispatch(toggleSubscribing());
      });
  };

  function unsubscribeForNewsletterSuccess(status: NewsletterStatus): IUnsubscribeSuccess {
    return { type: IActionTypes.UNSUBSCRIBE_SUCCESS, payload: { status } };
  }
}

function toggleSubscribing(): IToggleSubscribing {
  return { type: IActionTypes.TOGGLE_SUBSCRIBING, payload: {} };
}

export type NewsletterAction = ILoadStatusSuccess | ISubscribeSuccess | IUnsubscribeSuccess | IToggleSubscribing;

export default {
  loadNewsletterStatus,
  subscribeForNewsletter,
  unsubscribeForNewsletter
};
