export enum ConnectionActionTypes {
  START = '[CONNECTION] START',
  STOP = '[CONNECTION] STOP',
  INVOKE = '[CONNECTION] INVOKE',
  STATE_CHANGED = '[CONNECTION] STAGE_CHANGED'
}

interface IStart {
  type: ConnectionActionTypes.START;
  payload: { token: string };
}

interface IStop {
  type: ConnectionActionTypes.STOP;
}

interface IInvoke {
  type: ConnectionActionTypes.INVOKE;
  payload: { hub: any; method: any; args: any };
}

function start(token: string): IStart {
  return { type: ConnectionActionTypes.START, payload: { token } };
}

function stop(): IStop {
  return { type: ConnectionActionTypes.STOP };
}

function invoke(hub, method, args): IInvoke {
  return { type: ConnectionActionTypes.INVOKE, payload: { hub, method, args } };
}

export type ConnectionAction = IStart | IStop | IInvoke;

export const signalrConnection = {
  start,
  stop,
  invoke
};
