export enum routes {
  ADMIN = '/admin',
  DASHBOARD = '/',
  NEW_AUCTION = '/auctions/new',
  OWNER_AUCTION_WITH_ID = '/auctions/owner/:id/:step?',
  OWNER_AUCTION = '/auctions/owner',
  SUPPLIER_AUCTION_WITH_ID = '/auctions/supplier/:id',
  SUPPLIER_AUCTION = '/auctions/supplier',
  MY_AUCTIONS = '/auctions/my',
  MY_AUCTIONS_WITH_ID = '/auctions/my/:id',
  PUBLIC_AUCTIONS_AND_SUPPLIERS = '/public-auctions-and-suppliers',
  MY_CONTACTS = '/contacts/my',
  WIKI = '/wiki',
  PROFILE = '/profile',
  EXTERNAL_LOGIN = '/token',
  LOGIN = '/login',
  FORGOT_PASSWORD_REQUEST = '/forgot-password-request',
  FORGOT_PASSWORD = '/forgot-password/:token',
  REGISTER = '/register',
  ACTIVATION = '/activation',
  ACTIVATION_EMAIL = '/activation-email',
  ACCOUNT_REMOVAL = '/account-removal',
  TENDER_OVERVIEW = '/auction-overview',
  TENDER_OVERVIEW_WITH_ID = '/auction-overview/:id',
  USER_OVERVIEW = '/user-overview',
  USER_OVERVIEW_WITH_ID = '/user-overview/:id',
  PUBLIC_AUCTION_OVERVIEW = '/public/auction-overview/:id'
}

export enum adminRoutes {
  DASHBOARD = '/admin/',
  LOGIN = '/admin/login',
  LOCALIZATION = '/admin/localization',
  WIKI = '/admin/wiki',
  USERS = '/admin/users',
  PROFILES = '/admin/profiles',
  USER_OVERVIEW_WIDTH_ID = '/admin/user-overview/:id',
  USER_OVERVIEW = '/admin/user-overview'
}
