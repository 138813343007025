import { ThunkAction } from 'redux-thunk';

import { IApplicationState } from 'core/reducer';
import { fetchGovTendersBE } from '_api/tenderApi';
import { IAction } from '_types/commonTypes';
import { addTenderToFavoritesBE } from '_api/tenderSupplierApi';
import { ExternalTenderDTO } from '_types/backendTypes';

export enum IGovTendersActionTypes {
  ADD_TENDERS = '[ADD_GOV_TENDERS] SUCCESS',
  TOGGLE_FAVORITE = '[GOV_TENDERS] TOGGLE_FAVORITE',
  CLEAR_TENDERS = '[GOV_TENDERS] CLEAR'
}

interface IAddTendersSuccessAction {
  type: IGovTendersActionTypes.ADD_TENDERS;
  payload: { tenders: ExternalTenderDTO[] };
}

interface IClearTendersActions extends IAction<IGovTendersActionTypes.CLEAR_TENDERS, {}> {}

interface IToggleTenderFavoritesAction
  extends IAction<IGovTendersActionTypes.TOGGLE_FAVORITE, { tenderId: string; isFavorite: boolean }> {}

/**
 * Add only those tenders, which are not present in store
 * @param ids - tender ids
 * @param pageNumber - pagination prop
 * @param recordsPerPage - pagination prop
 */
function getGovTenders(
  ids: string[],
  pageNumber?: number,
  recordsPerPage?: number
): ThunkAction<Promise<ExternalTenderDTO[]>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    // TODO Kvoli bugu kde mozu byt v cashi duplicitne data, po odstraneni toho bugu odstranit
    const tendersFound = getState().govTenders.filter(
      (tender, index, self) => self.findIndex(t => t.id === tender.id && ids.indexOf(tender.id) > -1) === index
    );

    // const tendersFound = getState().tenders.filter(t => ids.indexOf(t.tenderId) > -1);
    const tendersFoundLength = tendersFound.length;

    if (ids.length !== tendersFoundLength) {
      let tendersToFetch = ids;

      if (tendersFoundLength > 0) {
        tendersToFetch = ids.filter(i => tendersFound.findIndex(x => x.id === i) < 0);
      }

      // TODO GOV FETCH
      return fetchGovTendersBE(tendersToFetch, pageNumber, recordsPerPage)
        .then(res => {
          dispatch(addTendersSuccessAction(res.data));

          return res.data;
        })
        .catch(err => {
          return [];
        });
    } else {
      return Promise.resolve(tendersFound);
    }
  };
}

function getGovTender(id: string): ThunkAction<Promise<ExternalTenderDTO>, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    return fetchGovTendersBE([id], 0, 1)
      .then(res => {
        dispatch(addTendersSuccessAction(res.data));

        return res.data[0];
      })
      .catch(err => {
        return [];
      });
  };
}

function addTendersSuccessAction(tenders: ExternalTenderDTO[]): IAddTendersSuccessAction {
  return {
    type: IGovTendersActionTypes.ADD_TENDERS,
    payload: { tenders }
  };
}

function clearGovTenders(): IClearTendersActions {
  return {
    type: IGovTendersActionTypes.CLEAR_TENDERS,
    payload: {}
  };
}

function toggleGovTenderFavorite(
  tenderId: string,
  isFavorite: boolean
): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    // TODO
    return new Promise(res => res());

    // return addTenderToFavoritesBE(tenderId, isFavorite).then(() => {
    //   dispatch(toggleFavorite(tenderId, isFavorite));
    // });
  };

  function toggleFavorite(tenderId: string, isFavorite: boolean): IToggleTenderFavoritesAction {
    return {
      type: IGovTendersActionTypes.TOGGLE_FAVORITE,
      payload: { tenderId, isFavorite }
    };
  }
}

export type GovernmentTendersAction =
  | IAddTendersSuccessAction
  | IToggleTenderFavoritesAction
  | IClearTendersActions;

export { getGovTenders, toggleGovTenderFavorite, clearGovTenders, getGovTender };
