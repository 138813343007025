import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';

import { IApplicationState, rootReducer } from 'core/reducer';
import createSignalrMiddleware from '_middlewares/singalR/singalRMiddleware';
import { TenderHub } from '_middlewares/singalR/hubs/tenderHub';
import { NotificationHub } from '_middlewares/singalR/hubs/notificationHub';
import { AppHub } from '_middlewares/singalR/hubs/appHub';

/**
 * signalR middleware, hub initialization with method hooks
 */
const signalrMiddleware = createSignalrMiddleware((store: Store, connection: SignalR.Hub.Connection) => {
  const tenderHub = new TenderHub(connection, store);
  tenderHub.registerMethods();

  const notificationHub = new NotificationHub(connection, store);
  notificationHub.registerMethods();

  const appHub = new AppHub(connection, store);
  appHub.registerMethods();
});

const middleware = [thunk, signalrMiddleware];

const enhancers = [];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default function configureStore(initialState?) {
  return createStore<IApplicationState, any, any, any>(rootReducer, initialState, composedEnhancers);
}
