import debounce from 'lodash.debounce';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Role } from '_types/commonTypes';
import qs from 'qs';

export const resetBids = (bids: { id: number; value: string }[]) => {
  for (let i = 0; i < bids.length; i++) {
    bids[i] = { ...bids[i], value: '' };
  }
};

/**
 * Debounce synthetic events
 * @param  {...any} args
 */
export const debounceEvent = (...args: [any, number]) => {
  const debouncedEvent = debounce(...args);
  return e => {
    e.persist();
    return debouncedEvent(e);
  };
};

export const updateFavicon = faviconUrl => {
  const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = faviconUrl;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const momentWithSecAndMsReset = () => moment().set({ s: 0, ms: 0 });

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const hasRole = (roles: Role[], role: Role) => roles.some(r => r === role);

export const SaveFile = (fileData, fileName) => FileSaver.saveAs(fileData, fileName);

export const sliderSettings = {
  slidesToShow: 2,
  className: 'slick-wrapper',
  slidesToScroll: 2,
  responsive: [
    { breakpoint: 99999, settings: 'unslick' },
    { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 3 } },
    { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2 } }
  ]
};

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(('0x' + p1) as any);
    })
  );
}

export const isNumber = (str: string) => {
  if (typeof str != 'string') {
    return false;
  }

  if (str.trim() === '') {
    return false;
  }

  return !Number.isNaN(Number(str));
};

export function dateIsValid(date: Date) {
  return date instanceof Date && !isNaN(date.getTime());
}

export const getQueryParams = <T extends unknown>() => {
  const { search } = window.location;

  return qs.parse(search, { ignoreQueryPrefix: true }) as T;
};
