import { IWikiActionTypes, WikiActions } from '_actions/wikiActions';
import { WikiQuestionAndAnswer, CodeList } from '_types/commonTypes';

export interface IState {
  content: WikiQuestionAndAnswer[];
  categories: CodeList<number>[];
}

export const initialState: IState = {
  content: [],
  categories: []
};

export function reducer(state: IState = initialState, action: WikiActions): IState {
  switch (action.type) {
    case IWikiActionTypes.LOAD_WIKI_CONTENT:
      return {
        ...state,
        content: action.payload.wikiContent
      };
    case IWikiActionTypes.LOAD_WIKI_CATEGORIES:
      return {
        ...state,
        categories: action.payload.wikiCategories
      };
    default: {
      return state;
    }
  }
}
