import { useEffect } from 'react';

const LuckyOrange = () => {
  useEffect(() => {
    if (process.env.REACT_APP_LUCKY_ORANGE_ID) {
      (window as any).__lo_site_id = 263082;
      const wa = document.createElement('script');
      wa.type = 'text/javascript';
      wa.async = true;
      wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
      const s = document.getElementsByTagName('script')[0];

      if (s && s.parentNode) {
        s.parentNode.insertBefore(wa, s);
      }
    }
  }, []);

  return null;
};

export default LuckyOrange;
