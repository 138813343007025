import { LocalizationText } from '_types/commonTypes';
import { LocalizationAction, IActionTypes } from '_actions/admin/localizationActions';

export interface IState {
  texts: LocalizationText[];
  // Saved text to replace changed one if its not submitted or if it's discart
  prevText: { index: number; text: LocalizationText | null };
}

export const initialState: IState = {
  texts: [],
  prevText: { index: -1, text: null }
};

export function reducer(state: IState = initialState, action: LocalizationAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_TEXTS:
      return {
        ...state,
        texts: [
          ...action.payload.texts,
          { key: '', value: '', correction: '', isFeText: true, textCorrected: false }
        ]
      };
    case IActionTypes.ADD_TEXT: {
      const slice = state.texts.slice();
      slice[slice.length - 1] = action.payload.text;
      slice.push({ key: '', value: '', correction: '', isFeText: true, textCorrected: false });

      return {
        ...state,
        texts: slice
      };
    }
    case IActionTypes.CHANGE_TEXT: {
      const slice = state.texts.slice();
      slice[action.payload.index] = action.payload.text;

      // Last text is used for Add action
      let prevText = state.prevText;
      if (action.payload.index !== state.texts.length - 1 && action.payload.index !== state.prevText.index) {
        slice[prevText.index] = prevText.text!;
        prevText = { index: action.payload.index, text: state.texts[action.payload.index] };
      }

      return {
        ...state,
        texts: slice,
        prevText
      };
    }
    case IActionTypes.EDIT_TEXT: {
      return {
        ...state,
        prevText: { index: -1, text: null }
      };
    }
    case IActionTypes.REMOVE_TEXT: {
      return {
        ...state,
        texts: state.texts.filter(s => s.key !== action.payload.key)
      };
    }
    default:
      return state;
  }
}
