import { ThunkAction } from 'redux-thunk';
import { IApplicationState } from 'core/reducer';
import collapsible from '_libs/collapsible';

export enum IActionTypes {
  TOGGLE_EDIT_DELIVERY_CARD = 'TOGGLE_EDIT_DELIVERY_CARD',
  TOGGLE_INIVTE_BY_EMAIL_SECTION = 'TOGGLE_INIVTE_BY_EMAIL_SECTION',
  TOGGLE_ADD_FROM_BESTAUCTION_USERS_CARD = 'TOGGLE_ADD_FROM_BESTAUCTION_USERS_CARD',
  TOGGLE_ADD_FROM_MY_CONTACTS_CARD = 'TOGGLE_ADD_FROM_MY_CONTACTS_CARD',
  TOGGLE_AUCTION_ENDED_NOTIFICATION = 'TOGGLE_AUCTION_ENDED_NOTIFICATION',
  TOGGLE_NOTIFICATION_PANEL = 'TOGGLE_NOTIFICATION_PANEL',
  TOGGLE_DETAILED_TABLE_VIEW = 'TOGGLE_DETAILED_TABLE_VIEW',
  TOGGLE_SAVE_ADDRESS_TO_USER_PROFILE = 'TOGGLE_SAVE_ADDRESS_TO_USER_PROFILE',
  TOGGLE_SAVE_ADDRESS_TO_COMPANY_PROFILE = 'TOGGLE_SAVE_ADDRESS_TO_COMPANY_PROFILE',
  TOGGLE_EDIT_DELIVERY_MAP = 'TOGGLE_EDIT_DELIVERY_MAP'
}

interface IToggleInviteByEmailSection {
  type: IActionTypes.TOGGLE_INIVTE_BY_EMAIL_SECTION;
}
interface IToggleEditDeliveryCard {
  type: IActionTypes.TOGGLE_EDIT_DELIVERY_CARD;
}

interface IToggleAddFromBestauctionUsersCard {
  type: IActionTypes.TOGGLE_ADD_FROM_BESTAUCTION_USERS_CARD;
}

interface IToggleAddFromMyContactsCard {
  type: IActionTypes.TOGGLE_ADD_FROM_MY_CONTACTS_CARD;
}

interface IToggleNotificationPanel {
  type: IActionTypes.TOGGLE_NOTIFICATION_PANEL;
}

interface IToggleAuctionEndedNotification {
  type: IActionTypes.TOGGLE_AUCTION_ENDED_NOTIFICATION;
  payload: { hasEnded: boolean };
}

interface IToggleDetailedTableView {
  type: IActionTypes.TOGGLE_DETAILED_TABLE_VIEW;
}

interface IToggleSaveAddressToUserProfileAction {
  type: IActionTypes.TOGGLE_SAVE_ADDRESS_TO_USER_PROFILE;
}

interface IToggleSaveAddressToCompanyProfileAction {
  type: IActionTypes.TOGGLE_SAVE_ADDRESS_TO_COMPANY_PROFILE;
}

function toggleInviteByEmailSection(): IToggleInviteByEmailSection {
  return { type: IActionTypes.TOGGLE_INIVTE_BY_EMAIL_SECTION };
}

function toggleEditDeliveryCard(): ThunkAction<void, any, null, any> {
  return (dispatch, getState: () => IApplicationState) => {
    const card = document.querySelector('.card.accordion');
    const isCardShown = getState().ui.auctionDataStep.isEditDeliveryCardOpened;

    if (card) {
      if (isCardShown) {
        collapsible.collapseSection(card);
      } else {
        collapsible.expandSection(card);
      }
    }

    dispatch(success());
  };

  function success(): IToggleEditDeliveryCard {
    return { type: IActionTypes.TOGGLE_EDIT_DELIVERY_CARD };
  }
}

function toggleAddFromBestauctionUsersCard(): IToggleAddFromBestauctionUsersCard {
  return { type: IActionTypes.TOGGLE_ADD_FROM_BESTAUCTION_USERS_CARD };
}
function toggleAddFromMyContactsCard(): IToggleAddFromMyContactsCard {
  return { type: IActionTypes.TOGGLE_ADD_FROM_MY_CONTACTS_CARD };
}
function toggleNotificationPanel(): IToggleNotificationPanel {
  return { type: IActionTypes.TOGGLE_NOTIFICATION_PANEL };
}
function toggleAuctionEndedNotification(hasEnded: boolean): IToggleAuctionEndedNotification {
  return { type: IActionTypes.TOGGLE_AUCTION_ENDED_NOTIFICATION, payload: { hasEnded } };
}
function toggleDetailedTableView(): IToggleDetailedTableView {
  return { type: IActionTypes.TOGGLE_DETAILED_TABLE_VIEW };
}
function toggleSaveAddressToUserProfile(): IToggleSaveAddressToUserProfileAction {
  return { type: IActionTypes.TOGGLE_SAVE_ADDRESS_TO_USER_PROFILE };
}
function toggleSaveAddressToCompanyProfile(): IToggleSaveAddressToCompanyProfileAction {
  return { type: IActionTypes.TOGGLE_SAVE_ADDRESS_TO_COMPANY_PROFILE };
}

export type UtiltyAction =
  | IToggleInviteByEmailSection
  | IToggleEditDeliveryCard
  | IToggleAddFromBestauctionUsersCard
  | IToggleAddFromMyContactsCard
  | IToggleAuctionEndedNotification
  | IToggleNotificationPanel
  | IToggleDetailedTableView
  | IToggleSaveAddressToUserProfileAction
  | IToggleSaveAddressToCompanyProfileAction;

export {
  toggleEditDeliveryCard,
  toggleAddFromBestauctionUsersCard,
  toggleAddFromMyContactsCard,
  toggleInviteByEmailSection,
  toggleNotificationPanel,
  toggleAuctionEndedNotification,
  toggleDetailedTableView,
  toggleSaveAddressToUserProfile,
  toggleSaveAddressToCompanyProfile
};
