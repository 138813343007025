import axios, { AxiosRequestConfig } from 'axios';

import API_URL from '_constants/apiConstants';
import { MustHaveConditionResponse } from '_types/tenderTypes';
import { MhcResponseDTO, SuppliersEntity } from '_types/backendTypes';

const TENDER_SUPPLIER_URL = '/api/tendersupplier';

/**
 * Creates suppplier and return supplierDTO
 */
export const createSupplierBE = async (userName: string, tenderId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      username: userName,
      tenderId: tenderId
    }
  };

  return await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/CreateTenderSeller`, {}, config);
};

/**
 * Creates suppplier and return supplierDTO
 */
export const leaveTender = async (tenderId: number, reason: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId,
      response: reason
    }
  };

  return await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/LeaveAuction`, {}, config);
};

export const rejoinTender = async (tenderId: number, reason: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId,
      response: reason
    }
  };

  return await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/RejoinAuction`, {}, config);
};

/**
 * Sends invitation to users for some tender
 * @param userNames - user names to whom were sending invitations
 * @param tenderId
 */
export const sendInvitationsToSuppliersBE = (userNames: string[], tenderId: number, msg: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: { tenderId: tenderId, msg }
  };

  return axios.post(`${API_URL + TENDER_SUPPLIER_URL}/SendInvitationsToTenderSellers`, userNames, config);
};

/**
 * Changes status to accepted or rejected by bidder
 * @param response
 * @param tenderId
 */
export const respondToInvitationBySellerBE = async (response: boolean, tenderId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      response: response
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/ResponseInvitation`, {}, config);

  return res;
};

/**
 * Buyer can accept or reject public bidder
 * @param tenderId
 * @param userName - public bidder user name
 * @param response
 */
export const respondToPublicBidderBE = async (
  tenderId: number,
  userName: string,
  response: boolean,
  msg: string = ''
) => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId,
      publicUser: userName,
      response,
      msg
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/ResponseToPublicBidder`, {}, config);

  return res;
};

/**
 * Bid to item price by id
 * @param tenderId
 * @param itemId
 * @param bid
 */
export const bidToItem = async (tenderId: number, itemId: number, bid: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      ItemId: itemId,
      Bid: bid
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/BidToItem`, {}, config);

  return res;
};

export const addTenderToFavoritesBE = async (tenderId: number, favorite: boolean = true) => {
  return axios.post(
    `${API_URL + TENDER_SUPPLIER_URL}/AddTenderToFavoriter`,
    {},
    { params: { tenderId, favorite } }
  );
};

export const saveMHCResponses = (response: MhcResponseDTO[], tenderId: number) => {
  return axios.post(
    `${API_URL + TENDER_SUPPLIER_URL}/ResponseMustHaveCondition`,
    {
      MhcResponse: response
    },
    { params: { tenderId } }
  );
};

export const uploadSuppliersAttachmentBE = async (tenderId: number, formData: FormData) => {
  return axios.post(`${API_URL + TENDER_SUPPLIER_URL}/UploadAttachmentFromBidder`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      tenderId
    }
  });
};

export const applyToPublicTender = async (
  tenderId: number,
  responses: MhcResponseDTO[]
): Promise<SuppliersEntity> => {
  const res = await axios.post(
    `${API_URL + TENDER_SUPPLIER_URL}/ApplyToPublicAuction`,
    {
      MhcResponse: responses
    },
    { params: { tenderId } }
  );

  if (res.data.ErrorMessage) Promise.reject(res.data.ErrorMessage);

  return res.data.Response;
};

/**
 * Bid to criterion by id
 * @param tenderId
 * @param criterionId
 * @param bid
 */
export const bidToCriterion = async (tenderId: number, criterionId: number, bid: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      CriterionId: criterionId,
      Bid: bid
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/BidToCriterion`, {}, config);

  return res;
};

export const unblockSupplierBE = async (tenderId: number, userName: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      publicUser: userName
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/UnblockBidder`, {}, config);

  return res;
};

export const blockSupplierBE = async (tenderId: number, userName: string, message: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: {
      tenderId: tenderId,
      user: userName,
      reason: message
    }
  };

  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/BlockBidder`, {}, config);

  return res;
};

export const addDemoBiddersBE = async (tenderId): Promise<any> => {
  const res = await axios.post(`${API_URL + TENDER_SUPPLIER_URL}/AddDemoBidders`, {}, { params: { tenderId } });
  return res;
};

export const editOfferDescriptionBE = async (tenderId: number, offerDescription: string) => {
  return axios.post(
    `${API_URL + TENDER_SUPPLIER_URL}/EditOfferDescription`,
    {},
    { params: { tenderId, offerDescription } }
  );
};

const removeSupplierFromTender = (username: string, tenderId: number) => {
  return axios.post(`${API_URL + TENDER_SUPPLIER_URL}/DeleteTenderSeller`, {}, { params: { username, tenderId } });
};

const finalizeProposal = (tenderId: number) => {
  return axios.post(`${API_URL + TENDER_SUPPLIER_URL}/FinalizedProposal`, {}, { params: { tenderId } });
};

export const tenderSupplierApi = {
  saveMHCResponses,
  leaveTender,
  rejoinTender,
  editOfferDescriptionBE,
  addDemoBiddersBE,
  blockSupplierBE,
  unblockSupplierBE,
  bidToCriterion,
  applyToPublicTender,
  removeSupplierFromTender,
  finalizeProposal
};
