import axios from 'axios';

import API_URL from '_constants/apiConstants';
import { Currency, CodeList, Industry, Country } from '_types/commonTypes';
import { TENDER_URL } from './tenderApi';

const CODELIST_URL = '/api/codelist';

export const fetchCurrencies = async (): Promise<Currency[]> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/Currency`);
  return mapCurrency(res.data);
};

export const fetchUnitsOfMeasurement = async (): Promise<Array<CodeList<string>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/UnitOfMeasurement`);
  return mapUnitsOfMeasurement(res.data);
};

export const fetchIndustries = async (): Promise<Industry[]> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/Industry`);
  return mapIndustry(res.data);
};

export const fetchUnitsOfTenderCriterion = async (): Promise<Array<CodeList<string>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/UnitOfTenderCriterion`);
  return mapUnitsOfTenderCriterion(res.data);
};

export const fetchCountries = async (): Promise<Array<Country>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/Country`);
  return mapCountries(res.data);
};

export const fetchSuppliersInvitationStatuses = async (): Promise<Array<CodeList<number>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/SupplierInvitationStatus`);
  return mapSupplierInvitationStatuses(res.data);
};

export const fetchTenderStatuses = async (): Promise<Array<CodeList<number>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/TenderStatus`);
  return mapTenderStatus(res.data);
};

export const fetchWikiCategories = async (): Promise<Array<CodeList<number>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/tWikiCategory`);
  return mapCategory(res.data);
};

export const fetchAddressesBE = async (): Promise<any> => {
  const res = await axios.get(`${API_URL + TENDER_URL}/GetMyAddresses`);
  return res;
};

export const fetchCanadaProvinces = async (): Promise<Array<CodeList<number>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/CanadaProvince`);
  return mapCanadaProvince(res.data);
};

export const fetchUSAStates = async (): Promise<Array<CodeList<number>>> => {
  const res = await axios.get(`${API_URL + CODELIST_URL}/USState`);
  return mapUSStates(res.data);
};

const mapSupplierInvitationStatuses = (data: any) => {
  const result: Array<CodeList<number>> = [];
  data.forEach(element => {
    result.push({
      id: element.SupplierStatusId,
      name: element.Name
    });
  });
  return result;
};

const mapCategory = (data: any) => {
  const result: Array<CodeList<number>> = [];
  data.forEach(element => {
    result.push({ id: element.Id, name: element.Name });
  });
  return result;
};

const mapCountries = (data: any) => {
  const result: Array<Country> = [];
  data.forEach(element => {
    result.push({
      id: element.CountryId,
      name: element.Name,
      code: element.Code,
      capital: element.Capital,
      region: element.Region,
      banned: element.Banned
    });
  });
  return result;
};

const mapUnitsOfTenderCriterion = (data: any) => {
  const result: Array<CodeList<string>> = [];
  data.forEach(element => {
    result.push({
      id: element.UnitOfTenderCriterionId,
      name: element.Name
    });
  });
  return result;
};

const mapTenderStatus = (data: any) => {
  const result: Array<CodeList<number>> = [];
  data.forEach(element => {
    result.push({
      id: element.TenderStatusId,
      name: element.Name
    });
  });
  return result;
};

const mapIndustry = (data: any) => {
  const result: Industry[] = [];
  if (data !== null && data.Data !== null) {
    data.forEach(element => {
      result.push({
        id: element.IndustryId,
        name: element.Name,
        display: element.Display
      });
    });
  }
  return result;
};

const mapUnitsOfMeasurement = (data: any) => {
  const result: Array<CodeList<string>> = [];
  data.forEach(element => {
    result.push({
      id: element.UnitOfMeasurementId,
      name: element.Name
    });
  });
  return result;
};

const mapCanadaProvince = (data: any) => {
  const result: Array<CodeList<number>> = [];
  data.forEach(element => {
    result.push({
      id: element.CanadaProvinceId,
      name: element.Name
    });
  });
  return result;
};

const mapUSStates = (data: any) => {
  const result: Array<CodeList<number>> = [];
  data.forEach(element => {
    result.push({
      id: element.USStateId,
      name: element.Name
    });
  });
  return result;
};

const mapCurrency = (data: any): Currency[] => {
  const result: Currency[] = [];
  data.forEach(element => {
    result.push({
      id: element.CurrencyId,
      name: element.Name,
      symbol: element.Symbol
    });
  });
  return result;
};
