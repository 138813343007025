import React from 'react';

import Checkbox from './Checkbox';

type Props = {
  name: string;
  checkedValue?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  readOnly?: boolean;
  wrapperStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const FilledInCheckbox: React.FC<Props> = ({
  name,
  checkedValue,
  label = '',
  className,
  wrapperClassName,
  style,
  readOnly,
  wrapperStyle,
  onChange,
  onBlur,
  labelClassName
}) => {
  return (
    <Checkbox
      name={name}
      checkboxClassType="filled-in"
      checkedValue={checkedValue}
      className={labelClassName}
      checkboxClassNames={className}
      style={style}
      onChange={onChange}
      readOnly={readOnly}
      {...(onBlur && { onBlur: onBlur })}
      label={label}
      wrapperClassName={wrapperClassName}
      wrapperStyle={wrapperStyle}
    />
  );
};

export default FilledInCheckbox;
