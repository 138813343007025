import { NotificationAction, INotificationActionTypes } from '_actions/notificationActions';
import { Notification } from '_types/commonTypes';

export interface IState {
  notifications: Notification[];
}

export const initialState: IState = {
  notifications: []
};

export function reducer(state: IState = initialState, action: NotificationAction): IState {
  switch (action.type) {
    case INotificationActionTypes.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload.notification, ...state.notifications]
      };
    }
    case INotificationActionTypes.LOAD_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload.notifications]
      };
    }
    case INotificationActionTypes.MARK_ALL_UNREAD_NOTIFICATIONS_AS_READ: {
      const notifications = state.notifications.map(notif => {
        if (action.payload.notifIds.find(i => i === notif.id)) {
          return { ...notif, read: true };
        }
        return notif;
      });

      return {
        ...state,
        notifications: notifications
      };
    }
    default:
      return state;
  }
}
