import React from 'react';
import * as classNames from 'classnames';

import { ButtonProps } from '../Button';

type Props = { wavesLight?: boolean; violet?: boolean } & ButtonProps;

const PageSidebarButton: React.FC<Props> = ({
  label,
  className,
  onClick,
  violet,
  style,
  onBlur,
  dataTarget,
  wavesLight,
  ...restProps
}) => {
  return (
    <button
      className={classNames(
        'btn sideBar__btn waves-effect',
        { ['waves-light']: wavesLight, ['waves-dark']: !wavesLight, ['violet']: violet },
        className
      )}
      style={style}
      onClick={onClick}
      onBlur={onBlur}
      data-target={dataTarget}
      {...restProps}
    >
      {label || restProps.children}
    </button>
  );
};

export default PageSidebarButton;
