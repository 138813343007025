import { IWikiActionTypes, AdminWikiActions } from '_actions/wikiActions';
import { WikiQuestionAndAnswer, CodeList } from '_types/commonTypes';

export interface IState {
  content: WikiQuestionAndAnswer[];
  categories: CodeList<number>[];
}

export const initialState: IState = {
  content: [],
  categories: []
};

export function reducer(state: IState = initialState, action: AdminWikiActions): IState {
  switch (action.type) {
    case IWikiActionTypes.LOAD_WIKI_CONTENT:
      return {
        ...state,
        content: action.payload.wikiContent
      };
    case IWikiActionTypes.LOAD_WIKI_CATEGORIES:
      return {
        ...state,
        categories: action.payload.wikiCategories
      };
    case IWikiActionTypes.ADD_QUESTION_AND_ANSWER: {
      return { ...state, content: [...state.content, action.payload.question] };
    }
    case IWikiActionTypes.EDIT_WIKI_QUESTION_AND_ANSWER: {
      return {
        ...state,
        content: state.content.map(content =>
          content.id !== action.payload.question.id ? content : action.payload.question
        )
      };
    }
    case IWikiActionTypes.REMOVE_WIKI_QUESTION: {
      return {
        ...state,
        content: state.content.filter(question => question.id !== action.payload.id)
      };
    }
    case IWikiActionTypes.ADD_WIKI_CATEGORY: {
      return { ...state, categories: [...state.categories, action.payload.category] };
    }
    case IWikiActionTypes.EDIT_WIKI_CATEGORY: {
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id !== action.payload.category.id ? category : action.payload.category
        )
      };
    }
    case IWikiActionTypes.REMOVE_WIKI_CATEGORY: {
      return {
        ...state,
        categories: state.categories.filter(category => category.id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
}
