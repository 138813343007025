import { HIDE_IRRELEVANT_SUPPLIERS_STATE_CACHE } from 'newAuction/components/HideIrrelevantSuppliersButton';
import { IActionTypes, TenderAction } from '_actions/tenderActions';
import { itemsDetailsCalculator } from '_libs/calculator';
import { momentWithSecAndMsReset } from '_libs/utils';
import { SuppliersTenderStatus } from '_types/commonTypes';
import { SuppliersBidChange, Tender } from '_types/tenderTypes';
import { initialDeliveryAddressState, initialUserState } from './partialInitialStates';

export interface IState {
  currentTender: Tender;
  bidsChanges: SuppliersBidChange[];
  hideIrrelevantSuppliers: boolean;
  shouldUpdateBackend: boolean;
}

export const initialAddressSource = -1;
/**
 * shouldUpdateBackend refers to observer that is listening on application state change
 * and updates tender on backend if it's true, after the backend is updated shouldUpdateBackend
 * is set to false
 */
export const initialState: IState = {
  currentTender: {
    tenderId: 0,
    tenderStatus: 0,
    name: '',
    keywords: '',
    addressSource: initialAddressSource,
    newDeliveryAddress: initialDeliveryAddressState,
    currency: '',
    totExpPrice: 0,
    totExpPriceInUserCurr: 0,
    mainPictureUrl: '',
    industries: [],
    itemsDetails: [
      {
        id: 0,
        name: 'Item',
        unitPrice: 100,
        unitOfMeasurement: { id: 'PC', name: 'Pieces' },
        quantity: 10,
        expPrice: itemsDetailsCalculator.calculateExpectedPrice(100, 10),
        bidStep: itemsDetailsCalculator.calculateBidStep(1000),
        bestBid: -1,
        description: ''
      }
    ],
    criteriaDetails: [
      {
        id: 0,
        name: 'Total price for all items',
        unit: { id: 'CURRENCY', name: 'Currency' },
        winningThreshold: { id: 0, name: 'Lowest Wins' },
        weight: { id: 1, name: '100%' },
        bestBid: -1,
        description: '',
        minBid: 0,
        maxBid: 0
      }
    ],
    // Setting seconds to 0 so tender countdown is accurate
    proposalEndDate: momentWithSecAndMsReset().add(7, 'd').toISOString(true),
    startDate: momentWithSecAndMsReset().add(9, 'd').toISOString(true),
    createdDate: new Date(),
    duration: 120,
    announcements: [],
    suppliers: [],
    attachments: {
      isUploading: false,
      list: []
    },
    isVatIncluded: true,
    mustHaveConditions: [],
    totalActiveBidders: 0,
    stepProgress: 0,
    isPublic: false,
    isFavorite: false,
    owner: initialUserState
  },
  hideIrrelevantSuppliers: localStorage.getItem(HIDE_IRRELEVANT_SUPPLIERS_STATE_CACHE) === 'true',
  bidsChanges: [],
  shouldUpdateBackend: false
};

export function reducer(state: IState = initialState, action: TenderAction): IState {
  switch (action.type) {
    case IActionTypes.LOAD_TEMPLATE_SUCCESS: {
      const { template } = action.payload;

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          name: template.name,
          itemsDetails: template.itemsDetails,
          criteriaDetails: template.criteriaDetails,
          attachments: { isUploading: false, list: action.payload.template.attachments },
          mustHaveConditions: template.mustHaveConditions,
          addressSource: template.addressSource,
          newDeliveryAddress: template.deliveryAddress,
          duration: template.duration,
          proposalEndDate: template.proposalEndDateTime,
          startDate: template.startDateTime
        }
      };
    }
    case IActionTypes.LOAD_TENDER_SUCCESS:
      return {
        ...state,
        currentTender: action.payload.tender
      };
    case IActionTypes.UPDATE_TENDER_PROPS_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          [action.payload.name]: action.payload.value
        },
        shouldUpdateBackend: action.shouldUpdateOnBackend
      };
    case IActionTypes.UPDATE_TENDER_PROPS_SUCCESS:
      return state;
    case IActionTypes.TOGGLE_FAVORITE:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          isFavorite: action.payload.isFavorite
        }
      };
    case IActionTypes.UPDATE_TENDER_ON_BACKEND_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          itemsDetails: action.payload.items,
          criteriaDetails: action.payload.criteria,
          mustHaveConditions: action.payload.mustHaveConditions,
          mainPictureUrl: action.payload.mainPicutreUrl,
          attachments: {
            isUploading: state.currentTender.attachments.isUploading,
            list: action.payload.attachments
          },
          newDeliveryAddress: {
            ...state.currentTender.newDeliveryAddress,
            id: action.payload.deliveryAddressId
          }
        }
      };
    case IActionTypes.CREATE_TENDER_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          tenderId: action.payload.tenderId,
          createdDate: new Date(),
          owner: action.payload.user
        },
        shouldUpdateBackend: false
      };
    case IActionTypes.SCHEDULE_TENDER_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          tenderStatus: action.payload.tenderStatusId
        }
      };
    case IActionTypes.ADD_NEW_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          itemsDetails: [...state.currentTender.itemsDetails, action.payload.item]
        },
        shouldUpdateBackend: true
      };
    case IActionTypes.UPDATE_ITEM_DETAILS_SUCCESS: {
      const { index, name, value } = action.payload;
      const items = state.currentTender.itemsDetails.slice();
      items[index] = { ...items[index], [name]: value };
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          itemsDetails: items
        },
        shouldUpdateBackend: action.shouldUpdateOnBackend
      };
    }
    case IActionTypes.UPLOAD_MAIN_PICTURE_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          mainPictureUrl: action.payload.url
        }
      };
    }
    case IActionTypes.REMOVE_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          itemsDetails: state.currentTender.itemsDetails.filter((_, i) => i !== action.payload.index)
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.ADD_NEW_CRITERION_DETAILS_SUCCESS:
      const criteria = state.currentTender.criteriaDetails.slice();
      const criteriaWeight = Math.round((criteria[0].weight.id - action.payload.criterion.weight.id) * 100) / 100;
      criteria[0] = { ...criteria[0], weight: { id: criteriaWeight, name: criteriaWeight * 100 + '%' } };

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          criteriaDetails: [...criteria, action.payload.criterion]
        },
        shouldUpdateBackend: true
      };
    case IActionTypes.UPDATE_CRITERION_DETAILS_SUCCESS: {
      const { index, name, value } = action.payload;
      const criteria = state.currentTender.criteriaDetails.slice();
      criteria[index] = { ...criteria[index], [name]: value };
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          criteriaDetails: criteria
        },
        shouldUpdateBackend: action.shouldUpdateOnBackend
      };
    }
    case IActionTypes.REMOVE_CRITERION_DETAILS_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          criteriaDetails: state.currentTender.criteriaDetails.filter((_, i) => i !== action.payload.index)
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.START_UPLOADING_ATTACHMENTS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          attachments: {
            ...state.currentTender.attachments,
            isUploading: true
          }
        }
      };
    }
    case IActionTypes.END_UPLOADING_ATTACHMENTS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          attachments: {
            ...state.currentTender.attachments,
            isUploading: false
          }
        }
      };
    }
    case IActionTypes.ADD_NEW_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          attachments: {
            ...state.currentTender.attachments,
            list: [...state.currentTender.attachments.list, ...action.payload.attachments]
          }
        }
      };
    case IActionTypes.REMOVE_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          attachments: {
            ...state.currentTender.attachments,
            list: state.currentTender.attachments.list.filter((_, i) => i !== action.payload.index)
          }
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.REMOVE_MAIN_PICTURE_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          mainPictureUrl: ''
        }
      };
    }
    case IActionTypes.ADD_ANNOUNCEMENT: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          announcements: [action.payload.announcement, ...state.currentTender.announcements]
        }
      };
    }
    case IActionTypes.ADD_SUPPLIER_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: [action.payload.supplier, ...state.currentTender.suppliers]
        },
        shouldUpdateBackend: action.shouldUpdateOnBackend
      };
    case IActionTypes.REMOVE_SUPPLIER_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: state.currentTender.suppliers.filter(s => s.user.userName !== action.payload.username)
        }
      };
    case IActionTypes.ADD_NEW_SUPPLIERS_ATTACHMENTS_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();

      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.username);

      if (suppIndex > -1) {
        suppliers[suppIndex] = {
          ...suppliers[suppIndex],
          attachments: {
            ...suppliers[suppIndex].attachments,
            list: [...suppliers[suppIndex].attachments.list, ...action.payload.attachments]
          }
        };
      }
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    }
    case IActionTypes.START_UPLOADING_SUPPLIERS_ATTACHMENTS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: [
            {
              ...state.currentTender.suppliers[0],
              attachments: { ...state.currentTender.suppliers[0].attachments, isUploading: true }
            }
          ]
        }
      };
    }
    case IActionTypes.END_UPLOADING_SUPPLIERS_ATTACHMENTS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: [
            {
              ...state.currentTender.suppliers[0],
              attachments: { ...state.currentTender.suppliers[0].attachments, isUploading: false }
            }
          ]
        }
      };
    }
    case IActionTypes.UPDATE_SUPPLIERS_MHC_RESPONSES: {
      const suppliers = state.currentTender.suppliers.slice();

      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.username);

      if (suppIndex > -1) {
        suppliers[suppIndex] = {
          ...suppliers[suppIndex],
          mustHaveConditionResponses: action.payload.responses
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    }
    case IActionTypes.REMOVE_SUPPLIERS_ATTACHMENT_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();
      const supp = state.currentTender.suppliers.findIndex(
        s => s.attachments.list.findIndex(a => a.id === action.payload.id) > -1
      );

      if (supp > -1) {
        suppliers[supp] = {
          ...suppliers[supp],
          attachments: {
            ...state.currentTender.suppliers[0].attachments,
            list: state.currentTender.suppliers[0].attachments.list.filter(a => a.id !== action.payload.id)
          }
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    }
    case IActionTypes.UNBLOCK_SUPPLIER: {
      const suppliers = state.currentTender.suppliers.slice();
      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.userName);

      if (suppIndex > 0) {
        suppliers[suppIndex] = {
          ...suppliers[suppIndex],
          status: action.payload.status
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    }
    case IActionTypes.SEND_INVITATIONS_TO_SUPPLIERS: {
      const suppliers = state.currentTender.suppliers.slice();
      action.payload.emails.forEach(email => {
        const index = state.currentTender.suppliers.findIndex(supp => supp.user.userName === email);
        if (typeof index !== 'undefined') {
          suppliers[index] = {
            ...suppliers[index],
            status: {
              id: action.payload.invitationStatus.id,
              name: action.payload.invitationStatus.name
            },
            canReinvite: suppliers[index].status.id === SuppliersTenderStatus.INVITATION_NOT_SENT
          };
        }
      });

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.RESPOND_TO_INVITATION_BY_SUPPLIER_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();
      const responseStatus = action.payload.response
        ? SuppliersTenderStatus.INVITATION_ACCEPTED
        : SuppliersTenderStatus.INVITATION_REJECTED_BY_BIDDER;

      suppliers[0] = {
        ...suppliers[0],
        status: {
          id: responseStatus,
          name: action.payload.invitationStatusCodeList.find(s => s.id === responseStatus)!.name
        }
      };

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    case IActionTypes.ADD_NEW_MUST_HAVE_CONDITION_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          mustHaveConditions: [...state.currentTender.mustHaveConditions, action.payload.condition]
        }
      };
    case IActionTypes.UPDATE_MUST_HAVE_CONDITION_SUCCESS: {
      const { index, condition } = action.payload;

      const conditions = state.currentTender.mustHaveConditions.slice();
      conditions[index] = condition;

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          mustHaveConditions: conditions
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.REMOVE_MUST_HAVE_CONDITION_SUCCESS: {
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          mustHaveConditions: state.currentTender.mustHaveConditions.filter((_, i) => i !== action.payload.index)
        },
        shouldUpdateBackend: true
      };
    }
    case IActionTypes.UPDATE_NEW_DELIVERY_ADDRESS_PROPS_SUCCESS:
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          newDeliveryAddress: {
            ...state.currentTender.newDeliveryAddress,
            [action.payload.name]: action.payload.value
          }
        },
        shouldUpdateBackend: action.shouldUpdateOnBackend
      };
    case IActionTypes.SUBMIT_CHANGE_SUCCESS:
      return {
        ...state,
        shouldUpdateBackend: false
      };
    case IActionTypes.UPDATE_SUPPLIERS_OFFER_DESCRIPTION_SUCCESS:
      const suppliers = state.currentTender.suppliers.slice();

      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.userName);

      if (suppIndex > -1) {
        suppliers[suppIndex] = {
          ...suppliers[suppIndex],
          offerDescription: action.payload.description
        };
      }
      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    /**
     * If we receive from signalR new bid, we add it to bid array or update current
     * value.
     */
    case IActionTypes.UPDATE_SUPPLIERS_ITEM_BID_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();
      const supplierIndex = state.currentTender.suppliers.findIndex(
        s => s.user.userName === action.payload.suppliersItemBid.userName
      );

      if (supplierIndex > -1) {
        const itemBids = suppliers[supplierIndex].itemsBids.slice();
        const itemIndex = itemBids.findIndex(i => i.id === action.payload.suppliersItemBid.itemId);
        if (itemIndex > -1) {
          itemBids[itemIndex] = {
            ...itemBids[itemIndex],
            value: action.payload.suppliersItemBid.bid
          };
        } else {
          itemBids.push({
            id: action.payload.suppliersItemBid.itemId,
            value: action.payload.suppliersItemBid.bid
          });
        }
        suppliers[supplierIndex] = {
          ...suppliers[supplierIndex],
          itemsBids: itemBids
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    /**
     * If we receive from signalR new bid, we add it to bid array or update current
     * value.
     */
    case IActionTypes.UPDATE_SUPPLIERS_CRITERION_BID_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();
      const supplierIndex = state.currentTender.suppliers.findIndex(
        s => s.user.userName === action.payload.suppliersCriterionBid.userName
      );

      if (supplierIndex > -1) {
        const criterionBids = suppliers[supplierIndex].criteriaBids.slice();
        const itemIndex = criterionBids.findIndex(i => i.id === action.payload.suppliersCriterionBid.criteriaId);
        if (itemIndex > -1) {
          criterionBids[itemIndex] = {
            ...criterionBids[itemIndex],
            value: action.payload.suppliersCriterionBid.bid
          };
        } else {
          criterionBids.push({
            id: action.payload.suppliersCriterionBid.criteriaId,
            value: action.payload.suppliersCriterionBid.bid
          });
        }

        suppliers[supplierIndex] = {
          ...suppliers[supplierIndex],
          criteriaBids: criterionBids
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    case IActionTypes.UPDATE_SUPPLIERS_POSITIONS_SUCCESS: {
      const suppliers = state.currentTender.suppliers.slice();

      for (let i = 0; i < state.currentTender.suppliers.length; i++) {
        const suppPosIndex = action.payload.suppliersPositions.findIndex(
          s => s.userName === suppliers[i].user.userName
        );

        if (suppPosIndex > -1) {
          suppliers[i] = {
            ...suppliers[i],
            overallPosition: action.payload.suppliersPositions[suppPosIndex].position
          };
        }
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    case IActionTypes.UPDATE_SUPPLIERS_PARTICIPATION_STATUS: {
      const suppliers = state.currentTender.suppliers.slice();

      const supplierIndex = suppliers.findIndex(s => s.user.userName === action.payload.userName);
      if (supplierIndex > -1) {
        suppliers[supplierIndex] = {
          ...suppliers[supplierIndex],
          status: action.payload.status,
          criteriaBids:
            action.payload.status.id === SuppliersTenderStatus.LEFT ? [] : suppliers[supplierIndex].criteriaBids,
          itemsBids:
            action.payload.status.id === SuppliersTenderStatus.LEFT ? [] : suppliers[supplierIndex].itemsBids
        };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    case IActionTypes.UPDATE_SUPPLIERS_BLOCK_REASON: {
      const suppliers = state.currentTender.suppliers.slice();

      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.userName);

      if (suppIndex > -1) {
        suppliers[suppIndex] = { ...suppliers[suppIndex], rejectedReason: action.payload.reason };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers: suppliers
        }
      };
    }
    case IActionTypes.UPDATE_SUPPLIERS_LAST_ACTIVITY: {
      const suppliers = state.currentTender.suppliers.slice();

      const suppIndex = suppliers.findIndex(s => s.user.userName === action.payload.username);

      if (suppIndex > -1) {
        suppliers[suppIndex] = { ...suppliers[suppIndex], lastActivityDate: action.payload.date };
      }

      return {
        ...state,
        currentTender: {
          ...state.currentTender,
          suppliers
        }
      };
    }
    case IActionTypes.UPDATE_TENDER_STATUS: {
      return {
        ...state,
        currentTender: { ...state.currentTender, tenderStatus: action.payload.statusId }
      };
    }
    case IActionTypes.ADD_BID_CHANGE: {
      const { bid } = action.payload;

      const bidsChanges =
        state.bidsChanges.findIndex(b => b.userName === bid.userName && b.id === bid.id && b.type === bid.type) ===
        -1
          ? [...state.bidsChanges, bid]
          : state.bidsChanges;

      return {
        ...state,
        bidsChanges
      };
    }
    case IActionTypes.REMOVE_BID_CHANGE: {
      const { bid } = action.payload;
      return {
        ...state,
        bidsChanges: state.bidsChanges.filter(
          b => b.userName !== bid.userName && b.id === bid.id && b.type === bid.type
        )
      };
    }
    case IActionTypes.TOGGLE_HIDE_IRRELEVANT_SUPPLIERS: {
      return {
        ...state,
        hideIrrelevantSuppliers: !state.hideIrrelevantSuppliers
      };
    }
    case IActionTypes.RESET_NEW_TENDER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
