import React from 'react';
import { InputProps } from './Input';

type OwnProps = { required?: boolean };

type Props = OwnProps & InputProps;

const TableCellInput: React.FC<Props> = ({
  type,
  name,
  required,
  inputClassName = '',
  onChange,
  value,
  readOnly,
  onMouseEnter,
  onKeyPress,
  placeholder = '',
  step = '',
  onBlur,
  inputStyle,
  autoFocus,
  min,
  max,
  pattern,
  onFocus,
  onKeyDown,
  reference,
  ...restProps
}) => {
  return (
    <input
      type={type}
      name={name}
      max={max}
      min={min}
      {...(placeholder && { placeholder: placeholder })}
      onKeyPress={onKeyPress}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      className={inputClassName}
      value={value}
      {...(pattern && { pattern: pattern })}
      {...(step && { step: step })}
      {...(readOnly && { readOnly: readOnly })}
      onChange={e => {
        if (onChange && (typeof min === 'undefined' || Number(e.target.value) >= min)) {
          onChange(e);
        }
      }}
      onBlur={onBlur}
      style={{ ...inputStyle, borderBottom: required ? '2px solid #f5c113' : '1px solid transparent' }}
      {...(autoFocus && { autoFocus: autoFocus })}
      ref={reference}
      {...restProps}
    />
  );
};

export default TableCellInput;
