import { RegisterAction, IActionTypes } from "_actions/userActions";

export interface IState {
    isRegistering: boolean
}

export const initialState: IState = {
    isRegistering: false
}

export function reducer(state: IState = initialState, action: RegisterAction) {
    switch (action.type) {
        case IActionTypes.REGISTER_REQUEST:
            return {
                isRegistering: true
            };
        case IActionTypes.REGISTER_SUCCESS:
            return {
                isRegistering: false
            };
        case IActionTypes.REGISTER_FAILURE:
            return {
                isRegistering: false
            };
        default:
            return state
    }
}