import { ThunkAction } from 'redux-thunk';
import { Dispatch } from 'redux';
import { IApplicationState } from 'core/reducer';
import { GuideNavigationTarget } from '_types/commonTypes';

export enum IActionTypes {
  SET_GUIDE = 'SET_GUIDE',
  OPEN_GUIDE = 'OPEN_GUIDE',
  CLOSE_GUIDE = 'CLOSE_GUIDE'
}

export interface IOpenGuideSuccess {
  type: IActionTypes.OPEN_GUIDE;
}

export interface ICloseGuideSuccess {
  type: IActionTypes.CLOSE_GUIDE;
}

export interface ISetGuideAction {
  type: IActionTypes.SET_GUIDE;
  payload: { heading: string; message: string; target: GuideNavigationTarget; config?: ConfigGuide };
}

export interface ConfigGuide {
  disableDargenBg?: boolean;
}

function openGuide(
  heading: string,
  message: string,
  target: GuideNavigationTarget,
  config?: ConfigGuide
): ThunkAction<void, any, null, any> {
  return (dispatch: Dispatch, getState: () => IApplicationState) => {
    {
      try {
        const guide = getState().guide;
        if (message !== guide.message || heading !== guide.heading || target !== guide.target) {
          dispatch(setGuide(heading, message, target, config));
        }
        $('.tap-target').tapTarget('open');
        dispatch(openGuideSuccess());
      } catch (err) {
        console.log(err);
      }
    }
  };

  function openGuideSuccess(): IOpenGuideSuccess {
    return { type: IActionTypes.OPEN_GUIDE };
  }
}

function closeGuide(): ICloseGuideSuccess {
  return { type: IActionTypes.CLOSE_GUIDE };
}

function setGuide(heading: string, message: string, target: GuideNavigationTarget, config?: ConfigGuide) {
  return { type: IActionTypes.SET_GUIDE, payload: { heading, message, target, config } };
}

export { openGuide, closeGuide, setGuide };

export type Action = ISetGuideAction | IOpenGuideSuccess | ICloseGuideSuccess;
