import { LocalizationText, IAction } from '_types/commonTypes';
import { ThunkAction } from 'redux-thunk';
import adminApi from '_api/adminApi';
import { mapLocalizationTextAdmin } from '../../_mappers/adminMapper';

export enum IActionTypes {
  LOAD_TEXTS = '[ADMIN] LOAD TEXTS',
  CHANGE_TEXT = '[ADMIN] CHANGE_TEXT',
  EDIT_TEXT = '[ADMIN] EDIT_TEXT',
  ADD_TEXT = '[ADMIN] ADD_TEXT',
  REMOVE_TEXT = '[ADMIN] REMOVE_TEXT'
}

interface ILoadTextsSuccessAction extends IAction<IActionTypes.LOAD_TEXTS, { texts: LocalizationText[] }> {}

interface IChangeTextAction extends IAction<IActionTypes.CHANGE_TEXT, { index: number; text: LocalizationText }> {}

interface IEditTextSuccessAction extends IAction<IActionTypes.EDIT_TEXT, { text: LocalizationText }> {}

interface IAddTextSuccessAction extends IAction<IActionTypes.ADD_TEXT, { text: LocalizationText }> {}

interface IRemoveTextSuccessAction extends IAction<IActionTypes.REMOVE_TEXT, { key: string }> {}

function loadTexts(): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return adminApi.fetchLocalizationTexts().then(res => {
      const mappedText = res.data.map(data => mapLocalizationTextAdmin(data));
      dispatch(loadTextsSuccess(mappedText));
    });
  };

  function loadTextsSuccess(texts: LocalizationText[]): ILoadTextsSuccessAction {
    return { type: IActionTypes.LOAD_TEXTS, payload: { texts } };
  }
}

function removeText(key: string): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return adminApi.removeLocalizationText(key).then(res => {
      dispatch(removeTextSuccessAction(key));
    });
  };

  function removeTextSuccessAction(key: string): IRemoveTextSuccessAction {
    return { type: IActionTypes.REMOVE_TEXT, payload: { key } };
  }
}

function changeText(index: number, text: LocalizationText): IChangeTextAction {
  return { type: IActionTypes.CHANGE_TEXT, payload: { index, text } };
}

function editText(text: LocalizationText): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return adminApi.editLocalizationText(text).then(() => {
      dispatch(editTextSuccessAction(text));
    });
  };

  function editTextSuccessAction(text: LocalizationText): IEditTextSuccessAction {
    return { type: IActionTypes.EDIT_TEXT, payload: { text } };
  }
}

function addText(text: LocalizationText): ThunkAction<Promise<any>, any, null, any> {
  return dispatch => {
    return adminApi.addLocalizationText(text).then(() => {
      dispatch(addTextSuccessAction(text));
    });
  };

  function addTextSuccessAction(text: LocalizationText): IAddTextSuccessAction {
    return { type: IActionTypes.ADD_TEXT, payload: { text } };
  }
}

export type LocalizationAction =
  | ILoadTextsSuccessAction
  | IChangeTextAction
  | IAddTextSuccessAction
  | IEditTextSuccessAction
  | IRemoveTextSuccessAction;

export default {
  loadTexts,
  changeText,
  addText,
  editText,
  removeText
};
