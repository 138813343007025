export const LOGIN_FORM = 'loginForm';
export const REGISTER_FORM = 'registerForm';
export const COLLABORATOR_FORM = 'collaboratorForm';
export const BASIC_DATA_FORM = 'basicDataForm';
export const INVITE_SUPPLIER_BY_EMAIL_FORM = 'inviteByEmailForm';
export const ADD_CONTACT_BY_EMAIL_FORM = 'addContactByEmailForm';
export const INVITE_NEW_USER_FORM = 'inviteNewUserForm';
export const EDIT_DELIVERY_ADDRESS_FORM = 'editDeliveryAddressForm';
export const CHANGE_PASSWORD_FORM = 'changePasswordForm';
export const FORGOT_PASSWORD_REQUEST_FORM = 'forgotPasswordRequestForm';
export const FORGOT_NEW_PASSWORD_FORM = 'forgotPasswordForm';
export const TWITTER_REGISTRATION_FORM = 'twitterRegistrationForm';
export const REPORT_ISSUE_FORM = 'reportIssueForm';
