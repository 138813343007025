import { IActionTypes, AuthenticationAction } from '_actions/userActions';
import { User, Role } from '_types/commonTypes';
import { initialUserState, initialCompanyState } from './partialInitialStates';
import moment from 'moment';

export interface IState {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isProfileFilled: boolean;
  isSurveyCompleted: boolean;
  remainingFreeTenders: number;
  expires: Date;
  user: User;
  roles: Role[];
  userDetailsLoaded: boolean;
  creationDate: Date | null;
}

export const initialState: IState = {
  isAuthenticating: false,
  isAuthenticated: false,
  isProfileFilled: false,
  isSurveyCompleted: false,
  userDetailsLoaded: false,
  remainingFreeTenders: 0,
  user: initialUserState,
  expires: new Date().addDays(50),
  roles: [],
  creationDate: null
};

export function reducer(state: IState = initialState, action: AuthenticationAction): IState {
  switch (action.type) {
    case IActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        user: {
          ...state.user,
          userName: action.payload.userName
        }
      };
    case IActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        user: {
          ...state.user,
          email: action.payload.userName
        }
      };
    case IActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false
      };
    case IActionTypes.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isProfileFilled: action.payload.isProfileFilled,
        roles: action.payload.roles,
        expires: action.payload.expires,
        isSurveyCompleted: action.payload.surveyCompleted,
        remainingFreeTenders: action.payload.remainingFreeTenders,
        creationDate: action.payload.creationDate,
        user: {
          ...state.user,
          userName: action.payload.userName
        }
      };
    case IActionTypes.PROFILE_FILLED: {
      return { ...state, isProfileFilled: true };
    }
    case IActionTypes.SURVEY_FILLED: {
      return { ...state, isSurveyCompleted: true };
    }
    case IActionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
    case IActionTypes.LOAD_USER_DETAILS: {
      return {
        ...state,
        user: action.payload.user
      };
    }
    case IActionTypes.UPDATE_USER_DETAILS: {
      return {
        ...state,
        isProfileFilled: true,
        user: action.payload.user
      };
    }
    case IActionTypes.UPDATE_COMPANY_DETAILS: {
      return {
        ...state,
        user: {
          ...state.user,
          company: action.payload.company
        }
      };
    }
    case IActionTypes.SAVE_ADDRESS_TO_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          address: action.payload.address
        }
      };
    }
    case IActionTypes.UPDATE_BILING_INFO: {
      return {
        ...state,
        expires: moment(action.payload.expires).toDate(),
        roles: action.payload.role
      };
    }
    case IActionTypes.REDUCE_REMAINING_FREE_TENDERS: {
      return {
        ...state,
        remainingFreeTenders: state.remainingFreeTenders - 1
      };
    }
    case IActionTypes.SAVE_ADDRESS_TO_COMPANY: {
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...(state.user.company || initialCompanyState),
            address: action.payload.address
          }
        }
      };
    }
    case IActionTypes.USER_DETAILS_LOADED: {
      return {
        ...state,
        userDetailsLoaded: true
      };
    }
    default:
      return state;
  }
}
