import { Address, Country, Coords } from '_types/commonTypes';
import { AddressDTO } from '_types/backendTypes';
import { initialCountry } from '_reducers/partialInitialStates';

export const mapToCoords = (GPS_NS, GPS_EW): Coords => {
  return {
    lat: GPS_NS || '',
    lng: GPS_EW || ''
  };
};

export const mapAddressToDto = (address: Address): AddressDTO => {
  return {
    AddressId: address.id,
    CountryId: address.country.id,
    City: address.city,
    PostalCode: address.zipCode,
    State: address.state,
    Street: address.streetAddress,
    StreetLine2: address.streetAddress2,
    GPS_NS: address.gpsCoords.lat,
    GPS_EW: address.gpsCoords.lng
  };
};

export const mapDtoToAddress = (dto: AddressDTO | null, countries: Country[]): Address => {
  return {
    id: dto ? dto.AddressId || 0 : 0,
    city: dto ? dto.City || '' : '',
    country: dto && dto.CountryId ? countries.find(c => c.id === dto.CountryId)! : initialCountry,
    state: dto ? dto.State || '' : '',
    streetAddress: dto ? dto.Street || '' : '',
    streetAddress2: dto ? dto.StreetLine2 || '' : '',
    zipCode: dto ? dto.PostalCode || '' : '',
    gpsCoords: {
      lat: dto ? dto.GPS_NS || '' : '',
      lng: dto ? dto.GPS_EW || '' : ''
    }
  };
};

export const mapGoogleAddressComponentsToAddress = (
  components: google.maps.GeocoderAddressComponent[],
  location: google.maps.LatLng,
  countries: Country[]
): Address => {
  const countryComponent = components.find(x => x.types[0] === 'country') || {
    short_name: 'COUNTRY_NOT_FOUND'
  };
  const cityComponent = components.find(x => x.types.findIndex(x => x === 'sublocality' || x === 'locality') > -1);
  const zipCodeComponent = components.find(x => x.types[0] === 'postal_code');
  const stateComponent = components.find(x => x.types[0] === 'administrative_area_level_1');
  const routeComponent = components.find(x => x.types[0] === 'route');
  const premiseComponent = components.find(x => x.types[0] === 'premise');
  const streetNumberComponent = components.find(x => x.types[0] === 'street_number');

  const country: Country = countries.find(x => x.code === countryComponent.short_name) || initialCountry;

  const streetLine2 =
    premiseComponent && streetNumberComponent
      ? `${premiseComponent.short_name}/${streetNumberComponent.short_name}`
      : streetNumberComponent
      ? streetNumberComponent.short_name
      : '';

  return {
    id: 0,
    streetAddress: routeComponent
      ? routeComponent.long_name.normalize('NFKD').replace(/[\u0300-\u036f]/g, '')
      : '',
    streetAddress2: streetLine2.normalize('NFKD').replace(/[\u0300-\u036f]/g, ''),
    zipCode: zipCodeComponent ? zipCodeComponent.short_name.replace(/ /g, '') : '',
    gpsCoords: { lat: location.lat().toString(), lng: location.lng().toString() },
    city: cityComponent ? cityComponent.long_name.normalize('NFKD').replace(/[\u0300-\u036f]/g, '') : '',
    state: stateComponent ? stateComponent.long_name.normalize('NFKD').replace(/[\u0300-\u036f]/g, '') : '',
    country: country
  };
};
